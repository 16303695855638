// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffect (appsState)

// @author: Mrunal Mane
// Modified on: 2024-05-23
// Description: Detail dot added for Task List Detail page

// @author: Mohit Tiwari
// Modified on: 2024-09-03
// Description: Hide goal section from sidenav if insights is not visible 
//              Modified one long if chain to concise one

import React, { useState, useEffect, ReactElement, useCallback } from 'react';
import withRouter from "hoc/withRouter";

import SVG from 'react-inlinesvg';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';
import { setDashboards } from 'store/Actions';
import { setUrlOption } from 'store/Actions';
import { VisibilityState, AppsState, DatesState, EventSourceState, DashboardsState, OrgState, LanguageState, NavigationStatusState } from '../../store/Reducers';
import { getCall, deleteCall } from 'utils/ApiCallActions';

import qs from 'querystring';
import { menuListData } from '../../assets/data/SideNav';
import { CommonUtils } from '../../utils/CommonUtils';

import ExportExcelModal from 'components/CommonModal/ExportExcel/ExportExcelModal';
import CommonModal from 'components/CommonModal/CommonModal';

import './SideNav.scss';
import '../../containers/SiteLayout/SiteLayout.scss';
import '../../style.scss';
import { RetryApi } from 'utils/GlobalConstants';


import { useLocation } from "react-router-dom";
import { useErrorBoundary } from 'react-error-boundary';


import { InsightLabels } from 'labels';
import Icon from 'components/Icon/Icon';
import { Modal } from 'react-bootstrap';


interface CurrentState {
    sectionVisibility: VisibilityState;
    apps: AppsState;
    dates: DatesState;
    eventSource: EventSourceState;
    dashboards: DashboardsState;
    org: OrgState,
    languageData: LanguageState;
    navigationStatus: NavigationStatusState;
}
// const Layout: FunctionComponent<{ hello: string }> = props => (

const SideNav = (props: any): ReactElement => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const [menuList, menuSetList] = useState([])
    const { showBoundary } = useErrorBoundary();


    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    let index_up: any;
    let innerIndex_up: any;

    const [exportModal, setExportModal] = useState({
        open: false,
    });

    const [modalState, setModalState] = useState({
        show: false
    });
    const [sideBarNonClickable, setSideBarNonClickable] = useState(false);

    const [sideNavVisibility, setSideNavVisibility] = useState(false);

    let fetched_details = useSelector((state: CurrentState) => state);
    let appsState = useSelector((state: CurrentState) => state.apps);
    let dashboard_states = useSelector((state: CurrentState) => state.dashboards);


    useEffect(() => {
        try {
        } catch (error) {
            showBoundary(error)
        }
        setSideNavVisibility(false);
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
            menuSetList(menuListData);
            setSideNavVisibility(true)
        }
    }, [fetched_details.languageData.languageData, fetched_details.sectionVisibility])

    useEffect(() => {
        setSideBarNonClickable(!fetched_details.navigationStatus.navigationStatusData);
        if (!fetched_details.navigationStatus.navigationStatusData) {
            setTimeout(() => {
                setSideBarNonClickable(false);
            }, 15000)
        }
    }, [fetched_details.navigationStatus.navigationStatusData]);

    const [label, setLabel] = useState({
        icon1: dashboard_states?.data[0]?.dashboard1?.dashboardIcon,
        icon2: dashboard_states?.data[1]?.dashboard2?.dashboardIcon,
        icon3: dashboard_states?.data[2]?.dashboard3?.dashboardIcon,
    })


    const [deleteModal, setDeleteModal] = useState({
        open: false,
        optn: 0,
        id: '',
        deleteMsg1: '',
        deleteMsg2: '',

    })

    let viewSection = fetched_details.sectionVisibility;

    let superAdmin_flag: boolean | null = viewSection.superAdmin;
    let guideInsights_flag: boolean | null = viewSection.guideInsights_flag;
    let appInsights_flag: boolean | null = viewSection.appInsights_flag;
    let myBot_flag: boolean | null = viewSection.myBot_flag;
    let qaAutomator_flag: boolean | null = viewSection.qaAutomator_flag;

    useEffect(() => {
        setModalState((prevState: any) => {
            return {
                ...prevState,
                show: deleteModal.open
            }
        })

    }, [deleteModal.open])


    if (
        localStorage.getItem("INDEX_UP") !== null ||
        localStorage.getItem("INNER_INDEX_UP") !== null
    ) {
        index_up = localStorage.getItem("INDEX_UP");
        innerIndex_up = localStorage.getItem("INNER_INDEX_UP");
    }
    var [arrowIndex, setindex_up] = useState({
        index_up: index_up,
        innerIndex_up: innerIndex_up,
    });

    var [detailDot, setDetailDot] = useState({
        detailOption: '',
        detailFlag: false
    });


    useEffect(() => {
        try {
            if (location.pathname.includes('dashboard')) {
                getDashboards();
                setindex_up((prevState: any) => {
                    return {
                        ...prevState,
                        index_up: 0,
                    }
                });

                setDetailDot({
                    ...detailDot,
                    detailOption: '',
                    detailFlag: false
                })
            }
            else if (location.pathname.includes('guide-insights')) {
                if (location.pathname.includes('guide-analytics-detail')) {
                    setDetailDot({
                        ...detailDot,
                        detailOption: 'Summary',
                        detailFlag: true,
                    })
                }
                else if (location.pathname.includes('guide-errors-detail')) {
                    setDetailDot({
                        ...detailDot,
                        detailOption: 'GuideErrors',
                        detailFlag: true,
                    })
                }
                else if (location.pathname.includes('tooltips-detail')) {
                    setDetailDot({
                        ...detailDot,
                        detailOption: 'Summary',
                        detailFlag: true,
                    })
                }
                else if (location.pathname.includes('user-engagement-detail')) {
                    setDetailDot({
                        ...detailDot,
                        detailOption: 'Summary',
                        detailFlag: true,
                    })
                }
                else if (location.pathname.includes('tutorial-guides-detail')) {
                    setDetailDot({
                        ...detailDot,
                        detailOption: 'TutorialGuideSummary',
                        detailFlag: true,
                    })
                } else if (location.pathname.includes('task-list-detail')) {
                    setDetailDot({
                        ...detailDot,
                        detailOption: 'TaskList',
                        detailFlag: true,
                    })
                }
                else {
                    setDetailDot({
                        ...detailDot,
                        detailOption: '',
                        detailFlag: false
                    })
                }

                setindex_up((prevState: any) => {
                    return {
                        ...prevState,
                        index_up: 1,
                    }
                });

                if (location.pathname.includes('guide-analytics')) {
                    setindex_up((prevState: any) => {
                        return {
                            ...prevState,
                            innerIndex_up: 0,
                        }
                    });
                }
                else if (location.pathname.includes('tooltips')) {
                    setindex_up((prevState: any) => {
                        return {
                            ...prevState,
                            innerIndex_up: 1,
                        }
                    });
                }
                else if (location.pathname.includes('user-engagement')) {
                    setindex_up((prevState: any) => {
                        return {
                            ...prevState,
                            innerIndex_up: 2,
                        }
                    });
                }
                else {
                    setindex_up((prevState: any) => {
                        return {
                            ...prevState,
                            innerIndex_up: "",
                        }
                    });
                }
            }
            else if (location.pathname.includes('app-insights')) {
                if (location.pathname.includes('page-detail')) {
                    setDetailDot({
                        ...detailDot,
                        detailOption: 'page',
                        detailFlag: true,
                    })
                }
                else if (location.pathname.includes('/workflows-detail')) {
                    setDetailDot({
                        ...detailDot,
                        detailOption: 'combined_workflow',
                        detailFlag: true,
                    })
                }
                else if (location.pathname.includes('/feature-detail')) {
                    setDetailDot({
                        ...detailDot,
                        detailOption: 'feature',
                        detailFlag: true,
                    })
                }

                else {
                    setDetailDot({
                        ...detailDot,
                        detailOption: '',
                        detailFlag: false
                    })
                }
                setindex_up((prevState: any) => {
                    return {
                        ...prevState,
                        index_up: 2,
                    }
                });
            }
            else if (location.pathname.includes('survey')) {
                if (location.pathname.includes('survey-details')) {
                    setDetailDot({
                        ...detailDot,
                        detailOption: 'survey-stats',
                        detailFlag: true,
                    })
                }
                else {
                    setDetailDot({
                        ...detailDot,
                        detailOption: '',
                        detailFlag: false
                    })
                }
                setindex_up((prevState: any) => {
                    return {
                        ...prevState,
                        index_up: 3,
                    }
                });
            }
            else if (location.pathname.includes('automation-insights')) {
                if (location.pathname.includes('automation-detail')) {
                    setDetailDot({
                        ...detailDot,
                        detailOption: 'automation',
                        detailFlag: true,
                    })
                }
                else if (location.pathname.includes('automator-detail')) {
                    setDetailDot({
                        ...detailDot,
                        detailOption: 'qa-automation',
                        detailFlag: true,
                    })
                }
                else {
                    setDetailDot({
                        ...detailDot,
                        detailOption: '',
                        detailFlag: false
                    })
                }
                setindex_up((prevState: any) => {
                    return {
                        ...prevState,
                        index_up: 4,
                    }
                });
            }
            else if (location.pathname.includes('goal')) {
                if (location.pathname.includes('goal-details')) {
                    setDetailDot({
                        ...detailDot,
                        detailOption: 'goals',
                        detailFlag: true,
                    })
                }
                else {
                    setDetailDot({
                        ...detailDot,
                        detailOption: '',
                        detailFlag: false
                    })
                    setindex_up((prevState: any) => {
                        return {
                            ...prevState,
                            index_up: 6,
                        }
                    });
                }
            }
            else if (location.pathname.includes('organization')) {
                if (location.pathname.includes('org-detail')) {
                    setDetailDot({
                        ...detailDot,
                        detailOption: 'org-view',
                        detailFlag: true,
                    })
                }
                else {
                    setDetailDot({
                        ...detailDot,
                        detailOption: '',
                        detailFlag: false
                    })
                    setindex_up((prevState: any) => {
                        return {
                            ...prevState,
                            index_up: 5,
                        }
                    });
                }
            }
            else if (location.pathname.includes('chatbot')) {
                if (location.pathname.includes('chatbot-detail')) {
                    setDetailDot({
                        ...detailDot,
                        detailOption: 'chatbot-stats',
                        detailFlag: true,
                    })
                }
                else {
                    setDetailDot({
                        ...detailDot,
                        detailOption: '',
                        detailFlag: false
                    })
                }
                setindex_up((prevState: any) => {
                    return {
                        ...prevState,
                        index_up: 1,
                    }
                });
            } else {
                setDetailDot({
                    ...detailDot,
                    detailOption: '',
                    detailFlag: false
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [location.pathname])


    function loadEngagement(index: any, innerIndex: any, subInnerIndex: any, option?: any,) {
        try {
            if (innerIndex === "") {
                if (index === 1) {
                    setindex_up((prevState: any) => {
                        return {
                            ...prevState,
                            index_up: index,
                            innerIndex_up: 0,
                        }
                    });
                    var urlOption: object = {
                        urlOption: 'GuideMe'
                    }
                    dispatch(setUrlOption(urlOption));
                    localStorage.setItem("INDEX_UP", index);
                    localStorage.setItem("INNER_INDEX_UP", "0");
                }
                else {
                    setindex_up((prevState: any) => {
                        return {
                            ...prevState,
                            index_up: index,
                            innerIndex_up: innerIndex,
                        }
                    });
                    localStorage.setItem("INDEX_UP", index);
                    localStorage.setItem("INNER_INDEX_UP", innerIndex);
                }
            }
            if (index === 1 && innerIndex === 0) {
                if (option) {
                    var urlOption: object = {
                        urlOption: option
                    }
                    dispatch(setUrlOption(urlOption));
                } else {
                    var urlOption: object = {
                        urlOption: 'GuideMe'
                    }
                    dispatch(setUrlOption(urlOption));
                }
                setindex_up({
                    index_up: index,
                    innerIndex_up: innerIndex,
                });
            }

            // Guide Errors
            if (index === 1 && innerIndex === 1) {
                if (option) {
                    var urlOption: object = {
                        urlOption: option
                    }
                    dispatch(setUrlOption(urlOption));
                } else {
                    var urlOption: object = {
                        urlOption: 'GuideError'
                    }
                    dispatch(setUrlOption(urlOption));
                }
                setindex_up({
                    index_up: index,
                    innerIndex_up: innerIndex,
                });
            }

            //Tutorial Guides
            if (index === 1 && innerIndex === 2) {
                if (option) {
                    var urlOption: object = {
                        urlOption: option
                    }
                    dispatch(setUrlOption(urlOption));
                } else {
                    var urlOption: object = {
                        urlOption: 'TutorialGuideGraph'
                    }
                    dispatch(setUrlOption(urlOption));
                }
                setindex_up({
                    index_up: index,
                    innerIndex_up: innerIndex,
                });
            }

            // Tooltip
            if (index === 1 && innerIndex === 3) {
                if (option) {
                    var urlOption: object = {
                        urlOption: option
                    }
                    dispatch(setUrlOption(urlOption));
                } else {
                    var urlOption: object = {
                        urlOption: 'PushNotifications'
                    }
                    dispatch(setUrlOption(urlOption));
                }
                setindex_up({
                    index_up: index,
                    innerIndex_up: innerIndex,
                });
            }

            // Tooltip
            if (index === 1 && innerIndex === 4) {
                if (option) {
                    var urlOption: object = {
                        urlOption: option
                    }
                    dispatch(setUrlOption(urlOption));
                } else {
                    var urlOption: object = {
                        urlOption: 'Tooltips_Validation'
                    }
                    dispatch(setUrlOption(urlOption));
                }
                setindex_up({
                    index_up: index,
                    innerIndex_up: innerIndex,
                });
            }

            // TaskList
            if (index === 1 && innerIndex === 5) {
                if (option) {
                    var urlOption: object = {
                        urlOption: option
                    }
                    dispatch(setUrlOption(urlOption));
                } else {
                    var urlOption: object = {
                        urlOption: 'TaskList'
                    }
                    dispatch(setUrlOption(urlOption));
                }
                setindex_up({
                    index_up: index,
                    innerIndex_up: innerIndex,
                });
            }

            // UserEngagement
            if (index === 1 && innerIndex === 6) {
                if (option) {
                    var urlOption: object = {
                        urlOption: option
                    }
                    dispatch(setUrlOption(urlOption));
                } else {
                    var urlOption: object = {
                        urlOption: 'UserActivity'
                    }
                    dispatch(setUrlOption(urlOption));
                }
                setindex_up({
                    index_up: index,
                    innerIndex_up: innerIndex,
                });
            }

            localStorage.setItem("INDEX_UP", index);
            localStorage.setItem("INNER_INDEX_UP", innerIndex);

            setTimeout(() => {
                CommonUtils.LeftPanelHighlight(index, innerIndex, subInnerIndex, false, true);
                // localStorage.setItem("INDEX_UP", index);
            }, 0);
        } catch (error) {
            showBoundary(error)
        }
    }



    const displaySection = useCallback((title: string) => {
        try {
            if ((title === 'guideInsights' || title === 'goal') && guideInsights_flag === false) {
                return 'none';
            } else if (title === 'appInsights' && appInsights_flag == false) {
                return 'none';
            } else if (title === 'automationInsights' && myBot_flag === false) {
                return 'none';
            } else if ((title === 'cioDashboardTitle') && guideInsights_flag === false && appsState.pageTrackingEnabled == 0) {
                return 'none';
            } else if (title === 'organizationView' && superAdmin_flag === false) {
                return 'none';
            } else if (title === 'businessIntelligenceToolsIntegrations' && !getBusinessToolsVisibility()) {
                return 'none';
            }

            if (myBot_flag === true && appsState.appGuideAutomationEnabled === 'false' && title === 'automationInsights') {
                return 'none';
            }

            if ((localStorage.getItem('USER_ROLE') === 'creator')) {
                if (['goal', 'controlCenter', 'export', 'automationInsights', 'appInsights', 'guideInsights', 'cioDashboardTitle',
                    'businessIntelligenceToolsIntegrations'].includes(title)) {
                    return 'none';
                }
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [appsState]);


    useEffect(() => {
        try {
            if (appsState.pageTrackingEnabled == 0 && location.pathname.includes('pages')) {
                navigate({
                    pathname: `/dashboard/`,
                    search: qs.stringify({}),
                });
            }

            if (location.pathname.includes('feature') && appsState.featureTrackingEnabled == 0) {
                navigate({
                    pathname: `/dashboard/`,
                    search: qs.stringify({}),
                });
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [appsState])

    const displaySubSection = useCallback((subSectionTitle: string, sectionTitle: string) => {
        try {
            if (appsState.appGuideAutomationEnabled) {
                localStorage.setItem('APP_GUIDE_AUTOMATION_ENABLED', appsState.appGuideAutomationEnabled);
            }

            if (subSectionTitle === 'guideAutomation' && myBot_flag === false) {
                return 'none';
            } else if (subSectionTitle === 'stats' && localStorage.getItem('USER_ROLE') === 'creator') {
                return 'none';
            }

            if (myBot_flag === true && appsState.appGuideAutomationEnabled === 'false' && subSectionTitle === 'Guide Automation') {
                return 'none';
            }

            if ((localStorage.getItem('USER_ROLE') === 'creator') && subSectionTitle === 'stats') {
                return 'none';
            }

            if (subSectionTitle === 'feature' && appsState.featureTrackingEnabled == 0) {
                return 'none';
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [appsState]);

    const displaySubSubSection = useCallback((subSectionTitle: string, subSubSectionOption: string) => {
        try {
            if (subSectionTitle === 'userEngagement' && localStorage.getItem('USER_ACTIVITY') !== 'true') {
                if (subSubSectionOption === 'GuideMe' || subSubSectionOption === 'ShowMe' || subSubSectionOption === 'TestMe' || subSubSectionOption === 'DoItForMe' || subSubSectionOption === 'Tutorial Guides' || subSubSectionOption === 'Summary') {
                    return 'none';
                }
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [appsState]);

    const openExportModal = (key: number, subkey: number) => {
        try {
            loadEngagement(key, subkey, "");
            setExportModal((prevState: any) => {
                return {
                    ...prevState,
                    open: true
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    };

    const closeExportModal = useCallback(() => {
        try {
            setExportModal((prevState: any) => {
                return {
                    ...prevState,
                    open: false
                }
            })
            window.location.reload();
        } catch (error) {
            showBoundary(error)
        }
    }, []);

    const closeDeleteModal = () => {
        setDeleteModal((prevState: any) => {
            return {
                ...prevState,
                open: false
            }
        })
    }

    const open_deleteDashboard = (optn: number, data: any) => {
        try {
            const profileID = data[optn]['dashboard' + `${optn + 1}`].profileID;

            setDeleteModal((prevState: any) => {
                return {
                    ...prevState,
                    optn: optn,
                    id: profileID,
                    open: true
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    const deleteDashboard = () => {
        try {
            let params = {
                profileID: deleteModal.id,
            };

            deleteCall(params, "CREATE_DASHBOARD", "").then((data: any) => {
                if (data.result === "success") {
                    closeDeleteModal();
                    let queryParams = {
                        // app_code: "",
                    };
                    navigate({
                        pathname: `/dashboard/`,
                        search: qs.stringify(queryParams),
                    });

                    // window.location.reload();

                    getDashboards();

                    toast.error(insightsLbls.successfullyDeleted, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
        } catch (error) {
            showBoundary(error)
        }
    }

    const getDashboards = (apiErrorFlag?: string) => {
        try {
            let param_val = {};
            getCall(param_val, "CREATE_DASHBOARD", apiErrorFlag ?? '').then((data: any) => {
                if (data.result === 'success') {
                    const dashboardData: any = data.data;
                    const dummyData: any = [];
                
                    for (let i = 0; i < dashboardData.length; i++) {
                        dummyData[i] = {
                            ['dashboard' + (i + 1)]: {
                                active: true,
                                name: dashboardData[i].profileName,
                                profileID: dashboardData[i].profileID,
                                isDefault: dashboardData[i].isDefault,
                                dashboardIcon: dashboardData[i].dashboardIcon,
                                profileDetails: dashboardData[i].profileDetails
                            }
                        }

                        setLabel((prevState: any) => {
                            return {
                                ...prevState,
                                ['icon' + (i + 1)]: setDashboardIcon(dummyData[i]['dashboard' + (i + 1)]['dashboardIcon'] ?? 'dashboardIcon-1'),
                            }
                        })
                    }

                    dispatch(setDashboards(dummyData))
                } else if (data.result === 'retry') {
                    setTimeout(() => {
                        getDashboards('retry')
                    }, RetryApi.TIMEOUT)
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    const setDashboardIcon = (image: string, type?: string) => {
        try {
            if (image) {
                const imgName = image.split('dashboardIcon-')
                // set Image
                return <div className={`dashboardIcon-${imgName[1]} icon-box`}>
                    <Icon img={"img/custom_dashboard/icon" + imgName[1] + ".svg"} className="dashboardIcon-div" />
                </div>;
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    const loadRoute = () => {
        try {
            let searchParams: any = {
                app_id: fetched_details?.apps.appId,
                app_code: fetched_details?.apps.appCode,
            }


            const loaded_component: string[] = location.pathname.split('/');
            switch (loaded_component[2]) {
                case 'guide-analytics':
                    searchParams = {
                        ...searchParams,
                        option: '',
                        event_source: fetched_details?.eventSource.eventSourceGuides,
                        fdate: fetched_details?.dates.fdate,
                        tdate: fetched_details?.dates.realTime_tdate
                    };
                    break;

                case 'tutorial-guides':
                    searchParams = {
                        ...searchParams,
                        option: '',
                        event_source: fetched_details?.eventSource.eventSourceTutorialGuides,
                        fdate: fetched_details?.dates.fdate,
                        tdate: fetched_details?.dates.realTime_tdate
                    };
                    break;

                case 'tooltips':
                    searchParams = {
                        ...searchParams,
                        option: '',
                        event_source: fetched_details?.eventSource.eventSourceTooltips,
                        fdate: fetched_details?.dates.fdate,
                        tdate: fetched_details?.dates.realTime_tdate
                    };
                    break;

                case 'user-engagement':
                    searchParams = {
                        ...searchParams,
                        option: '',
                        event_source: fetched_details?.eventSource.eventSourceUserEngagement,
                        fdate: fetched_details?.dates.fdate,
                        tdate: fetched_details?.dates.realTime_tdate
                    };
                    break;

                case 'search-activities':
                    searchParams = {
                        ...searchParams,
                        event_source: fetched_details?.eventSource.eventSourceSearches,
                        fdate: fetched_details?.dates.fdate,
                        tdate: fetched_details?.dates.realTime_tdate
                    };
                    break;

                case 'workflows':
                    searchParams = {
                        ...searchParams,
                        fdate: fetched_details?.dates.fdate,
                        tdate: fetched_details?.dates.realTime_tdate
                    };
                    break;

                case 'pages':
                    searchParams = {
                        ...searchParams,
                        fdate: fetched_details?.dates.fdate,
                        tdate: fetched_details?.dates.realTime_tdate,
                        tag_page: 0
                    };
                    break;

                case 'guide-automation':
                    searchParams = {
                        ...searchParams,
                        event_source: fetched_details?.eventSource.eventSourceGuideAutomation,
                        fdate: fetched_details?.dates.fdate,
                        tdate: fetched_details?.dates.realTime_tdate
                    };
                    break;

                case 'survey':
                    searchParams = {
                        ...searchParams,
                        fdate: fetched_details?.dates.fdate,
                        tdate: fetched_details?.dates.realTime_tdate
                    };
                    break;


                case 'manage-survey':
                    searchParams = {
                        ...searchParams,
                        survey_form: 0
                    };
                    break;

                case 'manage':
                    searchParams = {
                        ...searchParams,
                        chatbot_form: 0
                    };
                    break;

                case 'qa-automator':
                    searchParams = {
                        ...searchParams,
                        fdate: fetched_details?.dates.fdate,
                        tdate: fetched_details?.dates.realTime_tdate
                    };
                    break;

                case 'manage-goal':
                    searchParams = {
                        ...searchParams,
                        goal_form: 0
                    };
                    break;

                default:
                    break;

            }
            return qs.stringify(searchParams)
        } catch (error) {
            showBoundary(error)
        }
    }

    const sideNavTitles = (optn: string) => {
        try {
            switch (optn) {
                case 'cioDashboardTitle':
                    return insightsLbls.cioDashboardTitle;

                case 'dashboard':
                    return insightsLbls.dashboard;

                case 'myGuideDashboard':
                    return insightsLbls.myGuideDashboard;

                case 'guideInsights':
                    return insightsLbls.guideInsights;

                case 'guidesTab':
                    return insightsLbls.guidesTab;

                case 'guideMe':
                    return insightsLbls.guideMe;

                case 'showMe':
                    return insightsLbls.showMe;

                case 'testMe':
                    return insightsLbls.testMe;

                case 'doItForMe':
                    return insightsLbls.doItForMe;

                case 'survey':
                    return insightsLbls.survey;

                case 'pusNotificationAndBeacons':
                    return insightsLbls.pusNotificationAndBeacons;

                case 'summary':
                    return insightsLbls.summary;

                case 'guideErrors':
                    return insightsLbls.guideErrors;

                case 'tutorialGuides':
                    return insightsLbls.tutorialGuides;

                case 'pushNotificationsAndBeacons':
                    return insightsLbls.pushNotificationsAndBeacons;

                case 'guidesPlayedByPushAndBeacons':
                    return insightsLbls.guidesPlayedByPushAndBeacons;

                case 'pushNotifications':
                    return insightsLbls.pushNotifications;

                case 'beacons':
                    return insightsLbls.beacons;

                case 'tooltips':
                    return insightsLbls.tooltips;

                case 'tooltipAndValidation':
                    return insightsLbls.tooltipAndValidation;

                case 'powerForm':
                    return insightsLbls.powerForm;

                case 'ductTape':
                    return insightsLbls.ductTape;

                case 'taskList':
                    return insightsLbls.taskList;

                case 'userEngagement':
                    return insightsLbls.userEngagement;

                case 'userActivity':
                    return insightsLbls.userActivity;

                case 'searches':
                    return insightsLbls.searches;

                case 'appInsights':
                    return insightsLbls.appInsights;

                case 'pages':
                    return insightsLbls.pages;

                case 'feature':
                    return insightsLbls.feature;

                case 'stats':
                    return insightsLbls.stats;

                case 'manage':
                    return insightsLbls.manage;

                case 'chatbotConversation':
                    return insightsLbls.chatbotConversation;

                case 'automationInsights':
                    return insightsLbls.automationInsights;

                case 'guideAutomation':
                    return insightsLbls.guideAutomation;

                case 'organizationView':
                    return insightsLbls.organizationView;

                case 'goal':
                    return insightsLbls.goal;

                case 'controlCenter':
                    return insightsLbls.controlCenter;

                case 'export':
                    return insightsLbls.export;

                case 'businessIntelligenceToolsIntegrations':
                    return insightsLbls.businessIntelligenceToolsIntegrations;
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const getGuideInsightsVisibility = () => {
        let giv = localStorage.getItem("GUIDEINSIGHTS_VISIBILITY");
        if (giv == "true") {
            return true;
        }
        return false;
    }

    const getBusinessToolsVisibility = () => {
        let role = localStorage.getItem("USER_ROLE");
        if (['partner_admin', 'mg_admin', 'owner'].includes(role)) {
            let giv = localStorage.getItem("BUSINESS_TOOLS_VISIBILITY");
            if (giv == "true") {
                return true;
            }
        }
        return false;
    }

    const getRouteLink = (data) => {
        let routerLinkVal = {};


        if (data.title !== 'cioDashboardTitle') {
            if (data.title === 'automationInsights') {
                if (localStorage.getItem('MYBOT_VISIBILITY') === 'true' && appsState.appGuideAutomationEnabled === 'true') {
                    routerLinkVal = {
                        pathname: '/automation-insights/guide-automation/',
                        search: loadRoute(),
                    };
                }
                // if (localStorage.getItem('MYBOT_VISIBILITY') === 'false') {
                //     routerLinkVal = {
                //         pathname: '/automation-insights/qa-automator/',
                //         search: loadRoute(),
                //     };

                // } else {
                //     routerLinkVal = {
                //         pathname: appsState.appGuideAutomationEnabled === 'false' ? '/automation-insights/qa-automator/' : '/automation-insights/guide-automation/',
                //         search: loadRoute(),
                //     };
                // }
            } else if (data.title === 'survey' && localStorage.getItem('USER_ROLE') === 'creator') {
                routerLinkVal = {
                    pathname: '/survey/manage-survey/',
                    search: loadRoute(),
                };
            } else if (data.title === 'chatbotConversation' && localStorage.getItem('USER_ROLE') === 'creator') {
                routerLinkVal = {
                    pathname: '/chatbot/manage/',
                    search: loadRoute(),
                };
            } else {
                routerLinkVal = {
                    pathname: data.routerLink,
                    search: loadRoute(),
                };
            }
        } else {
            let flag = 0;
            let cnt = 0;

            for (let i = 0; i < dashboard_states?.data.length; i++) {
                if (dashboard_states?.data[i]['dashboard' + `${i + 1}`].isDefault) {
                    routerLinkVal = {
                        pathname: '/custom-dashboard/' + (i + 1),
                        search: '?app_id=',
                    };
                    flag = flag + 1;
                    cnt = i + 1;
                }
            }

            if(flag) {
                routerLinkVal = {
                    pathname: '/custom-dashboard/' + (cnt),
                    search: '?app_id=',
                };
            } else {
                routerLinkVal = {
                    pathname: '/dashboard/',
                    search: '?app_id=',
                };
            }
        }

        return routerLinkVal;

    }

    return (
        <aside id="left-panel" className={sideBarNonClickable ? "left-panel disable-interactions" : "left-panel"} style={{
            marginTop: !(
                location.pathname.includes('control-center') ||
                location.pathname.includes('export-list') || location.pathname.includes('business-intelligence-tools-integrations')) ? '147px' : '74px',

            height: !(
                location.pathname.includes('control-center') ||
                location.pathname.includes('export-list') || location.pathname.includes('business-intelligence-tools-integrations')) ? 'calc(100vh - 148px)' : 'calc(100vh - 48px)'
        }}>
            <nav className="navbar navbar-expand-sm nopadding navScroll">
                <div className="navbar-header">
                    {/* <Button
              className="navbar-toggler navbar-menuBtn"
              type="button"
              data-toggle="collapse"
              data-target="#main-menu"
              aria-controls="main-menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fa fa-bars"></i>
            </Button> */}
                </div>
                {/* Havent added class collapse navbar-collapse */}
                <div id="main-menu" className="main-menu width280">
                    {(sideNavVisibility) && (
                        <div className="sidenavHeight">
                            <ul className="nav navbar-nav width100">
                                {menuList.map((data: any, key: number) => {
                                    return (
                                        <li key={key} className="mainLink level1" id={'optn_' + `${key}`} style={{ display: displaySection(data.title) }}>
                                            {data.children === undefined && (
                                                <Link to={{ pathname: data.routerLink, search: loadRoute() }} onClick={() => loadEngagement(key, '', '')} className="alignCenter cursorPointer displayFlex 1" id="optn_c">
                                                    <Icon className="menu-icon-img" img={'img/' + data.svg} />
                                                    {/* <span className="vertAlignT">{data.title === "Goal"? (<>Goal <sup>(Beta)</sup></>): (data.title)}</span> */}
                                                    <span className="vertAlignT">{sideNavTitles(data.title)}</span>

                                                    {detailDot.detailFlag && data.option === detailDot.detailOption && (
                                                        <i className="fa fa-circle detail-dot positionAbsolute right15" aria-hidden="true"></i>
                                                    )}
                                                </Link>
                                            )
                                            }

                                            {data.children !== undefined && (
                                                (() => {
                                                    

                                                    return (
                                                        <Link
                                                            to={getRouteLink(data)}
                                                            // to={{pathname:`${routerLinkVal}`, search:loadRoute()}}

                                                            onClick={() => loadEngagement(key, '', '')} className="displayFlex alignCenter cursorPointer 2">
                                                            <Icon className="menu-icon-img" img={`img/${data.svg}`} />
                                                            <span className="vertAlignT">{sideNavTitles(data.title)}</span>
                                                            {/* {data.title === 'cioDashboardTitle' && (!getGuideInsightsVisibility() ||
                                                                !(dashboard_states?.data[0]?.dashboard1.active || dashboard_states?.data[1]?.dashboard2.active || dashboard_states?.data[2]?.dashboard3.active)) ===
                                                                true ? null : (
                                                                <i className={'fa fa-angle' + (arrowIndex.index_up !== key ? '-down sideOption-down ' : '-up sideOption-up')} aria-hidden="true"></i>
                                                            )} */}
                                                        </Link>)
                                                })()

                                            )}

                                            {data.children !== undefined && (

                                                <ul id={'menu-' + `${key}`} className="collapse panel-collapse">
                                                    {data.children.map((submenu: any, subkey: number) => {
                                                        return data?.title !== 'cioDashboardTitle' ? (
                                                            <li key={subkey} className="mainLink subLink-1 level2" id={'subMenu_' + `${subkey}`} style={{ display: displaySubSection(submenu?.title, data?.title) }}>
                                                                {submenu.children === undefined && (
                                                                    <Link
                                                                        to={{ pathname: submenu.title !== 'export' && submenu.routerLink, search: loadRoute() }}
                                                                        onClick={submenu.title !== 'export' ? () => loadEngagement(key, subkey, '') : () => openExportModal(key, subkey)}
                                                                        className="displayFlex alignCenter cursorPointer 3"
                                                                    >
                                                                        {/* <Icon className="menu-icon-img" img={`img/${submenu.svg}`} /> */}
                                                                        <span>{sideNavTitles(submenu.title)}</span>
                                                                        {detailDot.detailFlag && submenu.option === detailDot.detailOption && (
                                                                            <i className="fa fa-circle detail-dot positionAbsolute right15" aria-hidden="true"></i>
                                                                        )}
                                                                        {/* {exportModal.open ? <ExportExcelModal closeModal={closeExportModal} ></ExportExcelModal> : null}  */}
                                                                    </Link>
                                                                )}

                                                                {submenu.children !== undefined && (
                                                                    <Link
                                                                        to={{ pathname: submenu.routerLink, search: loadRoute() }}
                                                                        onClick={() => loadEngagement(key, subkey, '')} className="displayFlex alignCenter cursorPointer 4">
                                                                        {/* <Icon className="menu-icon-img" img={`img/${submenu.svg}`} /> */}
                                                                        <span>{sideNavTitles(submenu.title)}</span>
                                                                        <i
                                                                            className={'fa fa-angle' + (arrowIndex.innerIndex_up !== subkey ? '-down sideOption-down ' : '-up sideOption-up')}
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </Link>
                                                                )}

                                                                {submenu.children !== undefined && (
                                                                    <ul id={'sub-submenu-' + `${subkey}`} className="panel-collapse">
                                                                        {submenu.children.map((subsubmenu: any, subsubkey: number) => {
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        (
                                                                                            (process.env.REACT_APP_WESTPAC === 'true') &&
                                                                                            (subsubmenu.title === 'showMe' ||
                                                                                                subsubmenu.title === 'testMe' ||
                                                                                                subsubmenu.title === 'doItForMe'
                                                                                                // || subsubmenu.title === 'Tutorial Guides'
                                                                                            )
                                                                                        )
                                                                                            ? null : (
                                                                                                <li key={subsubkey} className="mainLink subLink-2 level3" id={'subMenu2_' + `${subsubkey}`} style={{ display: displaySubSubSection(submenu.title, subsubmenu.option) }}>
                                                                                                    <Link
                                                                                                        to={{ pathname: subsubmenu.routerLink, search: loadRoute() }}
                                                                                                        className="displayFlex alignCenter cursorPointer 5"
                                                                                                        onClick={() => { loadEngagement(key, subkey, subsubkey, subsubmenu.option); }}
                                                                                                    >
                                                                                                        {/* <Icon className="menu-icon-img" img={`img/${subsubmenu.svg}`} /> */}
                                                                                                        <span className="subMenu-2-span">{sideNavTitles(subsubmenu.title)}</span>
                                                                                                        {detailDot.detailFlag === true && subsubmenu.option === detailDot.detailOption ? (
                                                                                                            <i className="fa fa-circle detail-dot positionAbsolute right15" aria-hidden="true"></i>
                                                                                                        ) : null}
                                                                                                    </Link>
                                                                                                </li>
                                                                                            )}
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                )}
                                                            </li>
                                                        ) : (
                                                            data.title === 'cioDashboardTitle' &&
                                                            getGuideInsightsVisibility() &&
                                                            (dashboard_states?.data[0]?.dashboard1.active ||
                                                                dashboard_states?.data[1]?.dashboard2.active ||
                                                                dashboard_states?.data[2]?.dashboard3.active) === true &&
                                                            (dashboard_states.data[0].dashboard1.active && submenu.title === 'Dashboard 1' ? (
                                                                <li key={subkey} className="mainLink subLink-1 level2" id={'subMenu_' + `${subkey}`} style={{ display: displaySubSection(submenu.title, data.title) }}>
                                                                    <Link
                                                                        to={{ pathname: submenu.routerLink, search: '?app_id=' }}
                                                                        onClick={() => loadEngagement(key, subkey, '')}
                                                                        className="displayFlex alignCenter cursorPointer 3"
                                                                    >
                                                                        {/* <Icon className="menu-icon-img" img={`img/${submenu.svg}`} /> */}
                                                                        {/* {label.icon1} */}
                                                                        <span className="checkOverlap">{dashboard_states.data[0].dashboard1.name}</span>
                                                                        {dashboard_states.data[0].dashboard1.isDefault ? (
                                                                            <></>
                                                                            // <Icon
                                                                            //     className={'dashboardDefault-icon dashboradIcon marginRight-15 width15 height15 positionRelative right10'}
                                                                            //     img={'img/dashboardIcon.svg'}
                                                                            // />
                                                                        ) : (
                                                                            <span className="positionRelative right10 width15 height15 displayInlineBlock"></span>
                                                                        )}
                                                                        <i className="fa fa-trash faCheckSideNav" onClick={() => open_deleteDashboard(0, dashboard_states.data)}></i>
                                                                    </Link>
                                                                </li>
                                                            ) : dashboard_states.data[1]?.dashboard2.active && submenu.title === 'Dashboard 2' ? (
                                                                <li key={subkey} className="mainLink subLink-1 level2" id={'subMenu_' + `${subkey}`} style={{ display: displaySubSection(submenu.title, data.title) }}>
                                                                    <Link
                                                                        to={{ pathname: submenu.routerLink, search: '?app_id=' }}
                                                                        onClick={() => loadEngagement(key, subkey, '')}
                                                                        className="displayFlex alignCenter cursorPointer 3"
                                                                    >
                                                                        {/* <Icon className="menu-icon-img" img={`img/${submenu.svg}`} /> */}
                                                                        {/* {label.icon2} */}
                                                                        <span className="checkOverlap">{dashboard_states.data[1]?.dashboard2.name}</span>
                                                                        {dashboard_states.data[1].dashboard2.isDefault ? (
                                                                            <></>
                                                                            // <Icon
                                                                            //     className={'dashboardDefault-icon dashboradIcon marginRight-15 width15 height15 positionRelative right10'}
                                                                            //     img={'img/dashboardIcon.svg'}
                                                                            // />
                                                                        ) : (
                                                                            <span className="positionRelative right10 width15 height15 displayInlineBlock"></span>
                                                                        )}
                                                                        <i className="fa fa-trash faCheckSideNav" onClick={() => open_deleteDashboard(1, dashboard_states.data)}></i>
                                                                    </Link>
                                                                </li>
                                                            ) : dashboard_states.data[2]?.dashboard3.active && submenu.title === 'Dashboard 3' ? (
                                                                <li key={subkey} className="mainLink subLink-1 level2" id={'subMenu_' + `${subkey}`} style={{ display: displaySubSection(submenu.title, data.title) }}>
                                                                    <Link
                                                                        to={{ pathname: submenu.routerLink, search: '?app_id=' }}
                                                                        onClick={() => loadEngagement(key, subkey, '')}
                                                                        className="displayFlex alignCenter cursorPointer 3"
                                                                    >
                                                                        {/* <Icon className="menu-icon-img" img={`img/${submenu.svg}`} /> */}
                                                                        {/* {label.icon3} */}
                                                                        <span className="checkOverlap">{dashboard_states.data[2]?.dashboard3.name}</span>
                                                                        {dashboard_states.data[2].dashboard3.isDefault ? (
                                                                            <></>
                                                                            // <Icon
                                                                            //     className={'dashboardDefault-icon dashboradIcon marginRight-15 width15 height15 positionRelative right10'}
                                                                            //     img={'img/dashboardIcon.svg'}
                                                                            // />
                                                                        ) : (
                                                                            <span className="positionRelative right10 width15 height15 displayInlineBlock"></span>
                                                                        )}
                                                                        <i className="fa fa-trash faCheckSideNav" onClick={() => open_deleteDashboard(2, dashboard_states.data)}></i>
                                                                    </Link>
                                                                </li>
                                                            ) : (
                                                                (dashboard_states.data[0]?.dashboard1.active ||
                                                                    dashboard_states.data[1]?.dashboard2.active ||
                                                                    dashboard_states.data[2]?.dashboard3.active) &&
                                                                submenu.title === 'myGuideDashboard' && (
                                                                    <li key={subkey} className="mainLink subLink-1 level2" id={'subMenu_' + `${subkey}`} style={{ display: displaySubSection(submenu.title, data.title) }}>
                                                                        <Link

                                                                            to={sideNavTitles(submenu.title) !== 'Export' ? submenu.routerLink : null}
                                                                            onClick={submenu.title !== 'Export' ? () => loadEngagement(key, subkey, '') : () => openExportModal(key, subkey)}
                                                                            className="displayFlex alignCenter cursorPointer 3"
                                                                        >
                                                                            {/* <Icon className="menu-icon-img" img={`img/${submenu.svg}`} /> */}
                                                                            <span>{insightsLbls.myGuideDashboard}</span>
                                                                            {detailDot.detailFlag && submenu.option === detailDot.detailOption ? (
                                                                                <i className="fa fa-circle detail-dot positionAbsolute right15" aria-hidden="true"></i>
                                                                            ) : null}
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            ))
                                                        );
                                                    })}
                                                </ul>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}

                </div>
            </nav>

            {/* <CommonModal
                modalState={deleteModal.open}
                dispatchModalState={closeDeleteModal}
                modalTitle={insightsLbls.deleteDashboard}
                size={'md'}
                footerDisabled={false}
                exportModal={false}
                padding={true}
                modalDialogClass={''}
                dispatchModalOk={() => deleteDashboard()}
                dispatchModalCancel={closeDeleteModal}
                okBtn={insightsLbls.delete}
            >

            </CommonModal> */}
            <></>
            <Modal
                show={modalState.show}
                onHide={() => closeDeleteModal()}
                size={"lg"}
                dialogClassName={'delete-dashboard-modal'}
                backdrop="static">
                <Modal.Header className='padding-0'>
                    <Modal.Title>{''}</Modal.Title>
                    <button type="button" className="close" onClick={() => closeDeleteModal()} >
                        <span aria-hidden="true"><Icon img="img/close-icon-large.svg"></Icon></span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body className="paddingBottom-0 deleteDashboard">
                    <div>
                        <div className="deleteChart">

                            <>
                                <Icon className='delete-img' img="img/warning-icon.svg"></Icon>
                                <p className='body-heading'>{insightsLbls.allDashboardDataDeleted}</p>
                                <p className='body-content'> {insightsLbls.wantToDeleteDashboard}</p>
                            </>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ display: 'block' }}>
                    <div className="col-lg-12 align-center" >


                        {<button className="btn btn-primary marginLeft-10" onClick={deleteDashboard} >{insightsLbls.ok}</button>}

                    </div>
                </Modal.Footer>
            </Modal>

            {exportModal.open ? <ExportExcelModal closeModal={closeExportModal}></ExportExcelModal> : null}
        </aside>
    );
};
export default React.memo(withRouter(SideNav));

import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";


import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Button, ButtonGroup, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AppsState, DashboardsState, DomainState, StatusFilterState, CommonComponentState, LanguageState, VisibilityState, OrgState } from "../../store/Reducers";
import { DashboardState } from "../../store/Reducers/Dashboard/dashboard";
import * as ActionTypes from "../../store/Actions/ActionTypes";
import * as dashboardActions from "../../store/Actions/index";
import { withReducer } from "../../store/withReducer";
import dashboardReducer from "../../store/Reducers/Dashboard/dashboard";
import CommonExpandableTable from "components/Table/CommonExpandableTable";


import qs from "querystring";
import { CommonUtils } from "../../utils/CommonUtils";
import { getCallExportExcel } from "utils/ApiCallActions";
import { Tab, Tabs } from "react-bootstrap";

// JSON Imports
import SectionTooltip from "../../components/SectionTooltip/SectionTooltip";
import { getCall, postCall } from "utils/ApiCallActions";
import { ApiRelativePaths, RetryApi } from "../../utils/GlobalConstants";
import { setDashboards } from "store/Actions";
import { toast } from "react-toastify";

import "./Dashboard.scss";


// Components
import SectionRefresh from "../../components/Layouts/SectionRefresh";
import ContainerTooltip from "../../components/Layouts/ContainerTooltip";
import CardContainer from "../../components/Layouts/CardContainer";
import Loader from "../../components/Loader/Loader";
// import Piechart from "../../components/Chart/Piechart";
import CommonTable from "../../components/Table/CommonTable";
import LineChart from "components/Chart/nvd3Charts/LineChart";
import Table from "../../components/Table/Table";
import SectionHeader from "../../components/Layouts/SectionHeader";
import SectionButtons from "../../components/Layouts/SectionButtons";
import Datepicker from "../../components/Calender/Datepicker";
import ExportToPdf from "../../components/ExportToPdf/ExportToPdf";
import UserEngagement from "../../components/DashboardCharts/UserEngagement";
import WorldMap from "../../components/Chart/WorldMap";
import Maintenance from "components/Maintenance/Maintenance";
import Piechart from "../../components/Chart/nvd3Charts/Piechart";
import AppListDropdown from "components/AppListDropdown/AppListDropdown";
import CommonModal from "components/CommonModal/CommonModal";
import EventSource from 'components/EventSource/EventSource';
import Monthpicker from "components/Calender/Monthpicker";
import StateFilter from "components/StateFilter/StateFilter";
import SectionFilters from "components/Layouts/SectionFilters";
import AppEnvListDropdown from "components/AppEnvListDropdown/AppEnvListDropdown";
import UserInfoSection from "components/Layouts/UserInfoSection";

import Bubblechart from "components/Chart/Bubblechart";
import { InsightLabels } from "labels";
import { useErrorBoundary } from 'react-error-boundary';
import DashboardUserEngagementLazyLoad from "components/UserEngagementData/DashboardUserEngagmentLazyLoad";
import { v4 as uuidv4 } from 'uuid';

let lineChartMargin = {
    top: "150",
    right: "50",
    bottom: "50",
    left: "65",
};

let linechart_height = 450;



let colHeaders = [];
let colKeys = [];
let superHeaders = [];
if (process.env.REACT_APP_WESTPAC === 'false') {
    colHeaders = [
        { colWidth: 0, width: "200px", title: "App Name" },
        { colWidth: 0, width: "100px", title: "GuideMe" },
        { colWidth: 0, width: "100px", title: "ShowMe" },
        { colWidth: 0, width: "100px", title: "TestMe" },
        { colWidth: 0, width: "150px", title: "DoItForMe" },
        { colWidth: 0, width: "150px", title: "Tutorial Guide" },
        { colWidth: 0, width: "150px", title: "Guide Plays" },
        { colWidth: 0, width: "150px", title: "Guide Count" },
        { colWidth: 0, width: "150px", title: "Push-Notification" },
        { colWidth: 0, width: "100px", title: "Beacon" },
        { colWidth: 0, width: "100px", title: "Survey" },
        { colWidth: 0, width: "100px", title: "Guidance" },
        { colWidth: 0, width: "100px", title: "Validation" },
        { colWidth: 0, width: "170px", title: "Guidance & Validation" },
        { colWidth: 0, width: "100px", title: "Duct Tape" },
        { colWidth: 0, width: "100px", title: "Power Form" },
        { colWidth: 0, width: "150px", title: "Tooltip Plays" },
        { colWidth: 0, width: "150px", title: "Tooltip Count" }

    ];

    colKeys = [
        { colWidth: 0, width: "200px", key: "appName" },
        { colWidth: 0, width: "100px", key: "guideMeCnt" },
        { colWidth: 0, width: "100px", key: "showMeCnt" },
        { colWidth: 0, width: "100px", key: "testMeCnt" },
        { colWidth: 0, width: "150px", key: "doItForMeCnt" },
        { colWidth: 0, width: "150px", key: "tutorialGuideCnt" },
        { colWidth: 0, width: "150px", key: "guidePlays" },
        { colWidth: 0, width: "150px", key: "guideCnt" },
        { colWidth: 0, width: "150px", key: "pushnotificationCnt" },
        { colWidth: 0, width: "100px", key: "beaconCnt" },
        { colWidth: 0, width: "100px", key: "surveyCnt" },
        { colWidth: 0, width: "100px", key: "guidanceCnt" },
        { colWidth: 0, width: "100px", key: "validationCnt" },
        { colWidth: 0, width: "170px", key: "guidancevalidationCnt" },
        { colWidth: 0, width: "100px", key: "ducttapeCnt" },
        { colWidth: 0, width: "100px", key: "powerhtmlCnt" },
        { colWidth: 0, width: "150px", key: "tooltipPlays" },
        { colWidth: 0, width: "150px", key: "tooltipCnt" },
    ]

    superHeaders = [
        { colspan: 0, width: "200px", title: "Apps" },
        { colspan: 5, width: "600px", title: "Guides" },
        { colspan: 0, width: "300px", title: "Total" },
        { colspan: 2, width: "250px", title: "Notifications" },
        { colspan: 0, width: "100px", title: "Survey" },
        { colspan: 5, width: "570px", title: "Tooltip" },
        { colspan: 0, width: "300px", title: "Total" }
    ]
} else {
    colHeaders = [
        { colWidth: 0, width: "200px", title: "App Name" },
        { colWidth: 0, width: "100px", title: "GuideMe" },
        { colWidth: 0, width: "150px", title: "Tutorial Guide" },
        { colWidth: 0, width: "150px", title: "Push-Notification" },
        { colWidth: 0, width: "100px", title: "Beacon" },
        { colWidth: 0, width: "100px", title: "Survey" },
        { colWidth: 0, width: "100px", title: "Validation" },
        { colWidth: 0, width: "100px", title: "Power Form" },
        { colWidth: 0, width: "100px", title: "Duct Tape" },
        { colWidth: 0, width: "170px", title: "Guidance & Validation" },
        { colWidth: 0, width: "100px", title: "Guidance" },
        { colWidth: 0, width: "150px", title: "Tooltip" },
        { colWidth: 0, width: "150px", title: "Total Count" },

    ];

    colKeys = [
        { colWidth: 0, width: "200px", key: "appName" },
        { colWidth: 0, width: "100px", key: "guideMeCnt" },
        { colWidth: 0, width: "150px", key: "tutorialGuideCnt" },
        { colWidth: 0, width: "150px", key: "pushnotificationCnt" },
        { colWidth: 0, width: "100px", key: "beaconCnt" },
        { colWidth: 0, width: "100px", key: "surveyCnt" },
        { colWidth: 0, width: "100px", key: "validationCnt" },
        { colWidth: 0, width: "100px", key: "powerhtmlCnt" },
        { colWidth: 0, width: "100px", key: "ducttapeCnt" },
        { colWidth: 0, width: "170px", key: "guidancevalidationCnt" },
        { colWidth: 0, width: "100px", key: "guidanceCnt" },
        { colWidth: 0, width: "150px", key: "tooltipCnt" },
        { colWidth: 0, width: "150px", key: "totalCnt" }
    ]

    superHeaders = [
        { colspan: 0, width: "200px", title: "Apps" },
        { colspan: 2, width: "250px", title: "Guides" },
        { colspan: 2, width: "250px", title: "Notifications" },
        { colspan: 0, width: "100px", title: "Survey" },
        { colspan: 5, width: "570px", title: "Tooltip" },
        { colspan: 0, width: "300px", title: "Total" }
    ]
}





const Dashboard: React.FC = (props: any) => {

    const { showBoundary } = useErrorBoundary();

    const location = useLocation();
    const navigate = useNavigate();
    const appListTotal = useRef({})

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    let fetched_dashboard_details = useSelector((state: CurrentState) => state.dashboards);


    const componentRef = useRef() as React.RefObject<HTMLDivElement>;

    const dispatch = useDispatch();
    interface CurrentState {
        apps: AppsState;
        commonComponentData: CommonComponentState;
        dashboard: DashboardState;
        domain: DomainState;
        dashboards: DashboardsState;
        statusFilter: StatusFilterState;
        languageData: LanguageState;
        sectionVisibility: VisibilityState;
        org: OrgState
    }

    let fetched_details = useSelector((state: CurrentState) => state);
    let orgDetails = useSelector((state: CurrentState) => state.org);

    const GuideMeMount = useRef(false);
    const ShowMeMount = useRef(false);
    const TestMeMount = useRef(false);
    const DoItForMeMount = useRef(false);
    const GuideTutorialsMount = useRef(false);
    const WorkflowChartMount = useRef(false);
    const WorkflowTableMount = useRef(false);
    const CountriesMapMount = useRef(false);
    const SurveysMount = useRef(false);
    const GuideSummarysMount = useRef(false);
    const SurveyChartMount = useRef(false);
    const AppListMount = useRef(false);
    const UserEngagementMount = useRef(false);

    const [parameter, setParameter] = useState({
        parameter: "",
        appCode: "",
        appId: "",
        source: "",
        isDeleted: "",
        option: '',
        type: '',
        appTitle: '',
        redirect: 0
    });


    // const [componentText, setComponentText] = useState({
    //     guideInsigts: {},
    //     guideMe: {},
    //     showMe: {},
    //     testMe: {},
    //     doItForMe: {},
    //     guideSummary: {},
    //     workflow: {},
    //     workflowSummary: {},
    //     surveySummary: {},
    //     locationInsights: {},
    // });


    // const [errorApiMsg, setApiErrorMsg] = useState([]);
    const [userEngagementLazyLoadChart, setUserEngagementLazyLoadChart] = useState({
        lazyLoad_flag: false,
        refreshFlag: false,
    })

    const [excelData, setExcelData] = useState({
        download: "",
        viewOnline: "",
        or: "",
        questionMsg: ""
    });


    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        showMaintenance_flag: false
    });

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        loader2: false,
        loader3: false,
        loader4: false,
        loader5: false,
        loader6: false,
        loader7: false,
        loader8: false,
        loader9: false,
        loader10: false,
        loader11: false,
        loader12: false,
        loader13: false,
        loader14: false,
        loader15: false,
        loader16: false,
        loader17: false,
        loader18: false,
        loader19: false,
        loader20: false,
        disabled: true,
    });

    const [tabKey, setTabKey] = useState({
        key: 1,
        chart: []
    });

    const [expanded, setExpanded] = useState(false);

    const [appsList, setAppsList] = useState({
        appsFetched: false,
        appList: [],
        appNames: [],
        appListString: [],
        lineChartEntities: ["app1", "app2", "app3", "app4", "app5", "app6", "app7", "app8", "app9", "appx"]
    });

    const [dashboardGuideMeChart, setDashboardGuideMeChart] = useState({
        loadGuideMeChartData: [],
        loadGuideMeChartOptions: [],
        dashboardGuideMeChartDataFetched: false,
        guideMeChart: {},
        guideMeChartFetched: false,
        bubbleChartData: [],
        selectedAppCount: 0,
        chartData_entites: [],
        optionData_entites: [],
        chartData_values: { counts: [] }
    });

    const [dashboardShowMeChart, setDashboardShowMeChart] = useState({
        loadShowMeChartData: [],
        loadShowMeChartOptions: [],
        dashboardShowMeChartDataFetched: false,
        showMeChart: {},
        showMeChartFetched: false,
        bubbleChartData: [],
        selectedAppCount: 0,
        chartData_entites: [],
        optionData_entites: [],
        chartData_values: { counts: [] }
    });

    const [dashboardTestMeChart, setDashboardTestMeChart] = useState({
        loadTestMeChartData: [],
        loadTestMeChartOptions: [],
        dashboardTestMeChartDataFetched: false,
        testMeChart: {},
        testMeChartFetched: false,
        bubbleChartData: [],
        selectedAppCount: 0,
        chartData_entites: [],
        optionData_entites: [],
        chartData_values: { counts: [] }

    });

    const [dashboardDoItForMeChart, setDashboardDoItForMeChart] = useState({
        loadDoItForMeChartData: [],
        loadDoItForMeChartOptions: [],
        dashboardDoItForMeChartDataFetched: false,
        doItForMeChart: {},
        doItForMeChartFetched: false,
        bubbleChartData: [],
        selectedAppCount: 0,
        chartData_entites: [],
        optionData_entites: [],
        chartData_values: { counts: [] }

    });

    const [dashboardGuideTutorialsChart, setDashboardGuideTutorialsChart] = useState({
        loadGuideTutorialsChartData: [],
        loadGuideTutorialsChartOptions: [],
        dashboardGuideTutorialsChartDataFetched: false,
        guideTutorialsChart: {},
        guideTutorialsChartFetched: false,
        bubbleChartData: [],
        selectedAppCount: 0,
        chartData_entites: [],
        optionData_entites: [],
        chartData_values: { counts: [] }
    });

    const [dashboardTooltipChart, setDashboardTooltipChart] = useState({
        loadTooltipChartData: [],
        loadTooltipChartOptions: [],
        dashboardTooltipChartDataFetched: false,
        tooltipChart: {},
        tooltipChartFetched: false,
        bubbleChartData: [],
        selectedAppCount: 0,
        chartData_entites: [],
        optionData_entites: [],
        chartData_values: { counts: [] }
    });

    const [dashboardPushnotificationChart, setDashboardPushnotificationChart] = useState({
        loadPushnotificationChartData: [],
        loadPushnotificationChartOptions: [],
        dashboardPushnotificationChartDataFetched: false,
        pushnotificationChart: {},
        pushnotificationChartFetched: false,
        bubbleChartData: [],
        selectedAppCount: 0,
        chartData_entites: [],
        optionData_entites: [],
        chartData_values: { counts: [] }
    });

    const [dashboardBeaconChart, setDashboardBeaconChart] = useState({
        loadBeaconChartData: [],
        loadBeaconChartOptions: [],
        dashboardBeaconChartDataFetched: false,
        beaconChart: {},
        beaconChartFetched: false,
        bubbleChartData: [],
        selectedAppCount: 0,
        chartData_entites: [],
        optionData_entites: [],
        chartData_values: { counts: [] }
    });

    const [dashboardSurveyGraph, setDashboardSurveyGraph] = useState({
        loadSurveyChartData: [],
        loadSurveyChartOptions: [],
        dashboardSurveyChartDataFetched: false,
        surveyChart: {},
        SurveyChartFetched: false,
        bubbleChartData: [],
        selectedAppCount: 0,
        chartData_entites: [],
        optionData_entites: [],
        chartData_values: { counts: [] }
    });

    const [dashboardValidationChart, setDashboardValidationChart] = useState({
        dashboardValidationChartDataFetched: false,
    });

    const [dashboardPowerhtmlChart, setDashboardPowerhtmlChart] = useState({
        dashboardPowerhtmlChartDataFetched: false,
    });

    const [dashboardDucttapeChart, setDashboardDucttapeChart] = useState({
        dashboardDucttapeChartDataFetched: false,
    });

    const [dashboardGuidancevalidationChart, setDashboardGuidancevalidationChart] = useState({
        dashboardGuidancevalidationChartDataFetched: false,
    });

    const [dashboardGuidanceChart, setDashboardGuidanceChart] = useState({
        dashboardGuidanceChartDataFetched: false,
    });

    const [dashboardCountriesChartData, setDashboardCountriesChartData] = useState({
        dashboardCountriesChartDataFetched: false,
        legendData: [],
        boundaryValues: [],
        colors: [],
        countryData: []
    });

    const [dashboardGuideSummary, setDashboardGuideSummary] = useState({
        dashboardGuideSummaryFetched: false,
        defaultSort: {
            sortColumn_index: 2,
            sortValue: "desc",
        },
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 10,
            currentPage: 0,
        },
        limit: 10,
        firstLoad: true
    });

    const [dashboardTooltipSummary, setDashboardTooltipSummary] = useState({
        dashboardTooltipSummaryFetched: false,
        defaultSort: {
            sortColumn_index: 2,
            sortValue: "desc",
        },
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 10,
            currentPage: 0,
        },
        limit: 10,
        firstLoad: true
    });

    const [dashboardWorkflowChart, setDashboardWorkflowChart] = useState({
        loadWorkflowChartData: [],
        loadWorkflowChartOptions: [],
        dashboardWorkflowChartDataFetched: false,
        workflowChart: {},
        workflowChartFetched: false,
    });

    const [dashboardWorkflowTableData, setDashboardWorkflowTableData] = useState({
        dashboardWorkflowTableDataFetched: false,
        defaultSort: {
            sortColumn_index: 2,
            sortValue: "desc",
        },
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 10,
            currentPage: 0,
        },
        limit: 10,
        firstLoad: true
    });


    const [dashboardSurveyData, setDashboardSurveyData] = useState({
        dashboardSurveyDataFetched: false,
        defaultSort: {
            sortColumn_index: 3,
            sortValue: "desc",
        },
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 10,
            currentPage: 0,
        },
        limit: 10,
        firstLoad: true
    });

    const [exportModal, setExportModal] = useState({
        open: false,
        exportDisabled: false,
        start_date: '',
        end_date: '',
        app_id: '',
        event_source: '',
        defaultValue: 'player'
    })

    const [totalSummaryCount, setTotalSummaryCount] = useState({
        totalSummaryCountFetched: false,
        tableData: {},
        totalSummaryCountTable: {}
    })

    const [dashboardTotalGuideCount, setDashboardTotalGuideCount] = useState({
        totalGuideCountFetched: false,
        totalGuideCountData: {},
        isLoading: false,
        firstLoad: true
    })

    let maxAppLength = 10;

    const [request_uuid, setUuid] = useState({
        value: uuidv4()
    })

    const [bubbleChartData, setBubbleChartData] = useState({
        count: 0
    })

    const [dashboardUserEngagementChart, setDashboardUserEngagementChart] = useState({
        loadUserEngagementChartData: [],
        loadUserEngagementChartOptions: [],
        dashboardUserEngagementChartDataFetched: false,
        userEngagementChart: {},
        userEngagementFetched: false,
    });

    const [dashboard_state, setDashboard_state] = useState({
        isDefault: true
    })
    // First Load (on date change) @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    }, [fetched_details.languageData.languageData])


    useEffect(() => {
        try {
            if (fetched_details?.commonComponentData?.datesData?.fdate &&
                fetched_details?.commonComponentData?.datesData?.tdate &&
                fetched_details?.commonComponentData?.eventSource?.eventSourceDashboard &&
                appsList.appList.length <= maxAppLength) {

                let activeItems = 0;
                for (let i = 0; i < fetched_details?.dashboards?.data.length; i++) {
                    if (fetched_details?.dashboards?.data[i]['dashboard' + (i + 1)]['isActive'] && fetched_details?.dashboards?.data[i]['dashboard' + (i + 1)]['profileDetails'].length) {
                        activeItems = i;
                        break;
                    } else {
                        activeItems = 3;
                    }
                }

                window.scrollTo(0, 0);
                setTimeout(() => {
                    CommonUtils.LeftPanelHighlight(0, activeItems, 0, false, false);
                }, 10);

                const queryParams = {
                    fdate: fetched_details?.commonComponentData?.datesData.fdate,
                    tdate: fetched_details?.commonComponentData?.datesData.tdate,
                    event_source: fetched_details?.commonComponentData?.eventSource.eventSourceDashboard,
                    state: fetched_details?.commonComponentData?.stateFilterData.stateFilter,
                };

                navigate({
                    pathname: `/dashboard/`,
                    search: decodeURIComponent(qs.stringify(queryParams)),
                });

                if ((fetched_details?.commonComponentData?.datesData.url.includes('dashboard')) && appsList.appsFetched) {
                    const reqUUID = uuidv4();
                    loadOnFilterChangeData(reqUUID);
                    getDashboardStatus();
                    setUuid((prevState: any) => {
                        return {
                            ...prevState,
                            value: reqUUID
                        }
                    })
                }
            }

        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details?.commonComponentData?.datesData,
    appsList.appsFetched,
    appsList.appList,
    appsList.appListString,
    fetched_details?.statusFilter?.stateFilter,
    fetched_details?.commonComponentData?.eventSource?.eventSourceDashboard]);
    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getDashboardGuideMeChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "event_source": '',
            "app_id": appsList.appListString,
            "filter": 'all',
            'request_uuid': reqUUID
        };

        dashboardActions.getDashboardData(params, "DASHBOARD_GUIDE_ME_CHART", ActionTypes.SET_DASHBOARD_GUIDEME_CHART_DATA, apiErrorFlag ?? '')
            .then((res: any) => {
                dispatch(res);
            })
    };

    const getDashboardShowMeChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "event_source": '',
            "app_id": appsList.appListString,
            "filter": 'all',
            'request_uuid': reqUUID
        };

        dashboardActions.getDashboardData(
            params,
            "DASHBOARD_SHOW_ME_CHART",
            ActionTypes.SET_DASHBOARD_SHOWME_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => {
            dispatch(res);
        })
    };

    const getDashboardTestMeChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "event_source": '',
            "app_id": appsList.appListString,
            "filter": 'all',
            'request_uuid': reqUUID
        };

        dashboardActions.getDashboardData(
            params,
            "DASHBOARD_TEST_ME_CHART",
            ActionTypes.SET_DASHBOARD_TESTME_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => {
            dispatch(res);
        });
    };

    const getDashboardDoItForMeChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "event_source": '',
            "app_id": appsList.appListString,
            "filter": 'all',
            'request_uuid': reqUUID
        };

        dashboardActions.getDashboardData(
            params,
            "DASHBOARD_DO_IT_FOR_ME_CHART",
            ActionTypes.SET_DASHBOARD_DO_IT_FOR_ME_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => {
            dispatch(res);
        })
    };


    const getDashboardGuideTutorialsChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "event_source": '',
            "app_id": appsList.appListString,
            "filter": 'all',
            'request_uuid': reqUUID
        };

        dashboardActions.getDashboardData(
            params,
            "DASHBOARD_GUIDE_TUTORIALS_CHART_DATA",
            ActionTypes.SET_DASHBOARD_GUIDE_TUTORIALS_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => {
            dispatch(res);
        })
    };

    const getDashboardTooltipChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "event_source": '',
            "app_id": appsList.appListString,
            "filter": 'all',
            'request_uuid': reqUUID
        };

        dashboardActions.getDashboardData(
            params,
            "DASHBOARD_TOOLTIP_CHART_DATA",
            ActionTypes.SET_DASHBOARD_TOOLTIP_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => {
            dispatch(res);
        })
    };

    const getDashboardPushnotificationChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "event_source": '',
            "app_id": appsList.appListString,
            "filter": 'all',
            'request_uuid': reqUUID
        };

        dashboardActions.getDashboardData(
            params,
            "DASHBOARD_PUSHNOTIFICATION_CHART_DATA",
            ActionTypes.SET_DASHBOARD_PUSHNOTIFICATION_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => {
            dispatch(res);
        })
    };

    const getDashboardBeaconChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "event_source": '',
            "app_id": appsList.appListString,
            "filter": 'all',
            'request_uuid': reqUUID
        };

        dashboardActions.getDashboardData(
            params,
            "DASHBOARD_BEACON_CHART_DATA",
            ActionTypes.SET_DASHBOARD_BEACON_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => {
            dispatch(res);
        })
    };

    const getDashboardSurveyGraphData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "event_source": '',
            "app_id": appsList.appListString,
            "filter": 'all',
            'request_uuid': reqUUID
        };

        dashboardActions.getDashboardData(
            params,
            "DASHBOARD_SURVEY_CHART_DATA",
            ActionTypes.SET_DASHBOARD_SURVEY_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => {
            dispatch(res);
        })
    };

    const getDashboardValidationChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "event_source": '',
            "app_id": appsList.appListString,
            "filter": 'all',
            'request_uuid': reqUUID
        };

        dashboardActions.getDashboardData(
            params,
            "DASHBOARD_VALIDATION_CHART_DATA",
            ActionTypes.SET_DASHBOARD_VALIDATION_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => {
            dispatch(res);
        })
    };

    const getDashboardPowerhtmlChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "event_source": '',
            "app_id": appsList.appListString,
            "filter": 'all',
            'request_uuid': reqUUID
        };

        dashboardActions.getDashboardData(
            params,
            "DASHBOARD_POWERHTML_CHART_DATA",
            ActionTypes.SET_DASHBOARD_POWERHTML_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => {
            dispatch(res);
        })
    };

    const getDashboardDucttapeChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "event_source": '',
            "app_id": appsList.appListString,
            "filter": 'all',
            'request_uuid': reqUUID
        };

        dashboardActions.getDashboardData(
            params,
            "DASHBOARD_DUCTTAPE_CHART_DATA",
            ActionTypes.SET_DASHBOARD_DUCTTAPE_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => {
            dispatch(res);
        })
    };

    const getDashboardGuidancevalidationChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "event_source": '',
            "app_id": appsList.appListString,
            "filter": 'all',
            'request_uuid': reqUUID
        };

        dashboardActions.getDashboardData(
            params,
            "DASHBOARD_GUIDANCEVALIDATION_CHART_DATA",
            ActionTypes.SET_DASHBOARD_GUIDANCEVALIDATION_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => {
            dispatch(res);
        })
    };

    const getDashboardGuidanceChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "event_source": '',
            "app_id": appsList.appListString,
            "filter": 'all',
            'request_uuid': reqUUID
        };

        dashboardActions.getDashboardData(
            params,
            "DASHBOARD_GUIDANCE_CHART_DATA",
            ActionTypes.SET_DASHBOARD_GUIDANCE_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => {
            dispatch(res);
        })
    };

    const getDashboardCountriesChartData = (reqUUID, apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "time_zone": timeZone,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            "app_id": appsList.appListString,

            "event_source": '',
            "filter": 'all',
            'request_uuid': reqUUID
        };

        if (fetched_details?.commonComponentData?.datesData.fdate && fetched_details?.commonComponentData?.datesData.tdate) {
            dashboardActions.getDashboardData(
                params,
                "GET_COUNTRY_DATA",
                ActionTypes.SET_DASHBOARD_CONTRIES_CHART_DATA,
                apiErrorFlag ?? ''
            ).then((res: any) => {
                dispatch(res);
            })
        }
    }

    const getDashboardWorkflowChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "event_source": '',
            "app_id": appsList.appListString,
            "filter": 'all',
            'request_uuid': reqUUID
        };

        dashboardActions.getDashboardData(
            params,
            "GET_WORKFLOW_MULTIAPP_GRAPH",
            ActionTypes.SET_DASHBOARD_WORKFLOWS_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => {
            dispatch(res);
        })
    };

    const getDashboardWorkflowTableData = (reqUUID, apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "time_zone": timeZone,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            "app_id": appsList.appListString,
            "event_source": '',
            "filter": 'all',
            'request_uuid': reqUUID
        };

        if (fetched_details?.commonComponentData?.datesData.fdate && fetched_details?.commonComponentData?.datesData.tdate) {
            dashboardActions.getDashboardData(
                params,
                "GET_WORKFLOW_MULTIAPP_SUMMARY",
                ActionTypes.SET_DASHBOARD_WORKFLOWS_TABLE_DATA,
                apiErrorFlag ?? '',
                dashboardWorkflowTableData.firstLoad
            ).then((res: any) => {
                dispatch(res);
            })
        }

    }

    const getDashboardSurveyChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "event_source": '',
            "app_id": appsList.appListString,
            "filter": 'all',
            'request_uuid': reqUUID
        };

        dashboardActions.getDashboardData(
            params,
            "GET_SURVEY_MULTIAPP_GRAPH",
            ActionTypes.SET_DASHBOARD_SURVEY_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => {
            dispatch(res);
        })
    };

    const getDashboardSurveysData = (reqUUID, apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "time_zone": timeZone,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            "app_id": appsList.appListString,
            "event_source": '',
            "filter": 'all',
            'request_uuid': reqUUID
        };

        if (fetched_details?.commonComponentData?.datesData.fdate && fetched_details?.commonComponentData?.datesData.tdate) {
            dashboardActions.getDashboardData(
                params,
                "GET_SENTIMENT_SUMMARY",
                ActionTypes.SET_DASHBOARD_SURVEYS_TABLE_DATA,
                apiErrorFlag ?? '',
                dashboardSurveyData.firstLoad
            ).then((res: any) => {
                dispatch(res);
            })
        }

    }

    const getDashboardGuideSummary = (reqUUID, apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "time_zone": timeZone,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            "app_id": appsList.appListString,
            "event_source": '',
            "filter": 'all',
            'request_uuid': reqUUID
        };

        if (fetched_details?.commonComponentData?.datesData.fdate && fetched_details?.commonComponentData?.datesData.tdate) {
            dashboardActions.getDashboardData(
                params,
                "GET_DASHBOARD_GUIDE_SUMMARY",
                ActionTypes.SET_DASHBOARD_GUIDE_SUMMARY_TABLE_DATA,
                apiErrorFlag ?? '',
                dashboardGuideSummary.firstLoad
            ).then((res: any) => {
                dispatch(res);
            })
        }
    }

    const getDashboardTooltipSummary = (reqUUID, apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "time_zone": timeZone,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            "app_id": appsList.appListString,
            "event_source": '',
            "filter": 'all',
            'request_uuid': reqUUID
        };

        if (fetched_details?.commonComponentData?.datesData.fdate && fetched_details?.commonComponentData?.datesData.tdate) {
            dashboardActions.getDashboardData(
                params,
                "GET_DASHBOARD_TOOLTIP_SUMMARY",
                ActionTypes.SET_DASHBOARD_TOOLTIP_SUMMARY_TABLE_DATA,
                apiErrorFlag ?? '',
                dashboardGuideSummary.firstLoad
            ).then((res: any) => {
                dispatch(res);
            })
        }
    }

    const exportDashboardData = (optn: boolean) => {
        let params = {
            "app_id": exportModal.app_id,
            "time_zone": timeZone,
            "start_date": exportModal.start_date,
            "end_date": exportModal.end_date,
            "event_source": exportModal.event_source,
        };

        getCallExportExcel(params, 'DASHBOARD_EXPORT_DATA', optn);
        closeExportModal();
    }

    const getDashboardGuidesCount = (reqUUID, apiErrorFlag?: string) => {
        const params = {
            "app_id": appsList.appListString,
            'request_uuid': reqUUID
        };

        if (fetched_details?.commonComponentData?.datesData.fdate && fetched_details?.commonComponentData?.datesData.tdate) {
            dashboardActions.getDashboardData(
                params,
                "GET_DASHBOARD_GUIDE_COUNT",
                ActionTypes.SET_DASHBOARD_GUIDE_COUNT,
                apiErrorFlag ?? '',
                dashboardTotalGuideCount.firstLoad
            ).then((res: any) => {
                dispatch(res);
            })
        }
    }

    const getDashboardUserEngagementChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "event_source": '',
            "app_id": appsList.appListString,
            "filter": 'all',
            'request_uuid': reqUUID
        };

        dashboardActions.getDashboardData(
            params,
            "DASHBOARD_USER_ENGAGEMENT",
            ActionTypes.SET_DASHBOARD_USER_ENGAGEMENT,
            apiErrorFlag ?? ''
        ).then((res: any) => {
            dispatch(res);
        })
    }
    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const loadOnFilterChangeData = (reqUUID) => {
        try {
            setDashboardGuideMeChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardGuideMeChartDataFetched: false,
                    guideMeChart: {},
                    guideMeChartFetched: false,
                    loadGuideMeChartData: [],
                    loadGuideMeChartOptions: [],
                };
            });
            enableExportPdf(0, false);
            getDashboardGuideMeChartData(reqUUID);

            setDashboardShowMeChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardShowMeChartDataFetched: false,
                    showMeChart: {},
                    showMeChartFetched: false,
                };
            });
            enableExportPdf(1, false);
            if (process.env.REACT_APP_WESTPAC === 'false') {
                getDashboardShowMeChartData(reqUUID);
            }

            setDashboardTestMeChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardTestMeChartDataFetched: false,
                    testMeChart: {},
                    testMeChartFetched: false,
                };
            });
            enableExportPdf(2, false);
            if (process.env.REACT_APP_WESTPAC === 'false') {
                getDashboardTestMeChartData(reqUUID);
            }

            setDashboardDoItForMeChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardDoItForMeChartDataFetched: false,
                    doItForMeChart: {},
                    doItForMeChartFetched: false,
                };
            });
            enableExportPdf(3, false);
            if (process.env.REACT_APP_WESTPAC === 'false') {
                getDashboardDoItForMeChartData(reqUUID);
            }

            setDashboardGuideTutorialsChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardGuideTutorialsChartDataFetched: false,
                    guideTutorialsChart: {},
                    guideTutorialsChartFetched: false,
                };
            });
            enableExportPdf(4, false);
            getDashboardGuideTutorialsChartData(reqUUID);


            setDashboardTooltipChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardTooltipChartDataFetched: false,
                    tooltipChart: {},
                    tooltipChartFetched: false,
                };
            });
            enableExportPdf(10, false);
            getDashboardTooltipChartData(reqUUID);


            setDashboardPushnotificationChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardPushnotificationChartDataFetched: false,
                    pushnotificationChart: {},
                    pushnotificationChartFetched: false,
                };
            });
            enableExportPdf(11, false);
            getDashboardPushnotificationChartData(reqUUID);


            setDashboardBeaconChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardBeaconChartDataFetched: false,
                    beaconChart: {},
                    beaconChartFetched: false,
                };
            });
            enableExportPdf(12, false);
            getDashboardBeaconChartData(reqUUID);

            setDashboardSurveyGraph((prevState: any) => {
                return {
                    ...prevState,
                    dashboardSurveyChartDataFetched: false,
                    surveyChart: {},
                    surveyChartFetched: false,
                };
            });
            enableExportPdf(13, false);
            getDashboardSurveyGraphData(reqUUID);

            setDashboardValidationChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardValidationChartDataFetched: false,
                }
            })

            setDashboardPowerhtmlChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardPowerhtmlChartDataFetched: false,
                }
            })

            setDashboardDucttapeChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardDucttapeChartDataFetched: false,
                }
            })

            setDashboardGuidancevalidationChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardGuidancevalidationChartDataFetched: false,
                }
            })

            setDashboardGuidancevalidationChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardGuidanceChartDataFetched: false,
                }
            })

            setDashboardGuidanceChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardGuidanceChartDataFetched: false,
                }
            })



            if (fetched_details.sectionVisibility.guideInsights_flag) {
                getDashboardValidationChartData(reqUUID);
                getDashboardPowerhtmlChartData(reqUUID);
                getDashboardDucttapeChartData(reqUUID);
                getDashboardGuidancevalidationChartData(reqUUID);
                getDashboardGuidanceChartData(reqUUID);
            }


            setDashboardGuideSummary((prevState: any) => {
                return {
                    ...prevState,
                    dashboardGuideSummaryFetched: false,
                    defaultSort: {
                        sortColumn_index: 3,
                        sortValue: "desc",
                    },
                    tablePagination: {
                        offset: 0,
                        data: [],
                        perPage: 10,
                        currentPage: 0,
                    },
                    limit: 10,
                    firstLoad: true
                };
            });
            enableExportPdf(5, false);


            setDashboardTooltipSummary((prevState: any) => {
                return {
                    ...prevState,
                    dashboardTooltipSummaryFetched: false,
                    defaultSort: {
                        sortColumn_index: 3,
                        sortValue: "desc",
                    },
                    tablePagination: {
                        offset: 0,
                        data: [],
                        perPage: 10,
                        currentPage: 0,
                    },
                    limit: 10,
                    firstLoad: true
                };
            });
            enableExportPdf(19, false);

            if (fetched_details.sectionVisibility.guideInsights_flag) {
                getDashboardGuideSummary(reqUUID);
                getDashboardTooltipSummary(reqUUID);
            }



            setDashboardWorkflowChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardWorkflowChartDataFetched: false,
                    workflowChart: {},
                    workflowChartFetched: false,
                };
            });
            enableExportPdf(6, true);
            // getDashboardWorkflowChartData();

            setDashboardWorkflowTableData((prevState: any) => {
                return {
                    ...prevState,
                    dashboardWorkflowTableDataFetched: false,
                    defaultSort: {
                        sortColumn_index: 3,
                        sortValue: "desc",
                    },
                    tablePagination: {
                        offset: 0,
                        data: [],
                        perPage: 10,
                        currentPage: 0,
                    },
                    limit: 10,
                    firstLoad: true
                };
            });
            enableExportPdf(7, true);
            // getDashboardWorkflowTableData();


            setDashboardSurveyData((prevState: any) => {
                return {
                    ...prevState,
                    dashboardSurveyDataFetched: false,
                    defaultSort: {
                        sortColumn_index: 3,
                        sortValue: "desc",
                    },
                    tablePagination: {
                        offset: 0,
                        data: [],
                        perPage: 10,
                        currentPage: 0,
                    },
                    limit: 10,
                    firstLoad: true
                };
            });
            enableExportPdf(8, false);
            getDashboardSurveysData(reqUUID);

            setDashboardCountriesChartData((prevState: any) => {
                return {
                    ...prevState,
                    dashboardCountriesChartDataFetched: false,
                    legendData: [],
                    boundaryValues: [],
                    colors: [],
                    countryData: [],
                };
            });
            enableExportPdf(9, false);
            getDashboardCountriesChartData(reqUUID);

            setTotalSummaryCount((prevState: any) => {
                return {
                    ...prevState,
                    totalSummaryCountFetched: false,
                    tableData: {},
                    totalSummaryCountTable: {}
                }
            });

            setDashboardTotalGuideCount((prevState) => {
                return {
                    ...prevState,
                    isLoading: false,
                    totalGuideCountFetched: false,
                    totalGuideCountData: []
                }
            });
            getDashboardGuidesCount(reqUUID)

            // setDashboardSurveyChart((prevState: any) => {
            //     return {
            //         ...prevState,
            //         dashboardSurveyChartDataFetched: false,
            //         surveyChart: {},
            //         surveyChartFetched: false,
            //     };
            // });
            // enableExportPdf(1, false);
            // getDashboardSurveyChartData();

            setDashboardUserEngagementChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardUserEngagementChartDataFetched: false,
                    userEngagementChartChart: {},
                    userEngagementChartFetched: false,
                    loadUserEngagementChartData: [],
                    loadUserEngagementChartOptions: [],
                };
            });
            getDashboardUserEngagementChartData(reqUUID);

        } catch (error) {
            showBoundary(error)
        }
    }

    const handleTableEvents = useCallback(
        (option: string, data: any) => {
            try {
                switch (option) {
                    case "routeToDetail":

                        if (data.guideID) {
                            setParameter((prevState: any) => {
                                return {
                                    ...prevState,
                                    parameter: data.guideID,
                                    option: data.tooltipGuideFlag ? ("/guide-insights/tooltips-detail/") : (!data.tutorialGuideFlag ? "/guide-insights/guide-analytics-detail/" : "/guide-insights/tutorial-guides-detail/"),
                                    source: data.guideTitle,
                                    appCode: data.appCode,
                                    appId: data.appId,
                                    appTitle: data.appTitle,
                                };
                            });

                        } else if (data.workflowCode) {
                            setParameter((prevState: any) => {
                                return {
                                    ...prevState,
                                    parameter: data.workflowCode + '/' + data.workflowType,
                                    option: "/app-insights/workflows-detail/",
                                    source: data.workflowTitle,
                                    type: data.workflowType,
                                    appCode: data.appCode,
                                    appId: data.appId,
                                };
                            });
                        } else if (data.sentimentCode) {
                            if (data.appCode) {
                                setParameter((prevState: any) => {
                                    return {
                                        ...prevState,
                                        parameter: data.sentimentCode,
                                        appCode: data.appCode,
                                        appId: data.appId,
                                        appTitle: data.appTitle,
                                        isDeleted: data.isDeleted,
                                        option: "/survey/survey-details/",
                                        source: data.sentimentTitle,
                                    };
                                });
                            }
                        }
                        break;
                }
            } catch (error) {
                showBoundary(error)
            }

        },
        [dashboardSurveyData]
    );

    const handleSwitch = (e: any) => {
        try {
            setDashboardGuideMeChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardGuideMeChartDataFetched: false
                };
            });

            setDashboardShowMeChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardShowMeChartDataFetched: false
                };
            });

            setDashboardTestMeChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardTestMeChartDataFetched: false
                };
            });

            setDashboardDoItForMeChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardDoItForMeChartDataFetched: false
                };
            });

            setDashboardGuideTutorialsChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardGuideTutorialsChartDataFetched: false
                };
            });

            setDashboardTooltipChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardTooltipChartDataFetched: false
                };
            });

            setDashboardPushnotificationChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardPushnotificationChartDataFetched: false
                };
            });

            setDashboardBeaconChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardBeaconChartDataFetched: false
                };
            });

            setDashboardSurveyGraph((prevState: any) => {
                return {
                    ...prevState,
                    dashboardSurveyChartDataFetched: false
                };
            });

            setTabKey((prevState: any) => {
                return {
                    ...prevState,
                    key: e
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    useEffect(() => {
        try {
            switch (+tabKey.key) {
                case 1:

                    if (fetched_details?.dashboard?.dashboardGuideMeChartData.result == 'success') {
                        setDashboardGuideMeChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideMeChartFetched: true,
                                dashboardGuideMeChartDataFetched: true
                            };
                        });
                    }
                    break;

                case 2:
                    setDashboardShowMeChart((prevState: any) => {
                        return {
                            ...prevState,
                            showMeChartFetched: true,
                            dashboardShowMeChartDataFetched: true
                        };
                    });
                    break;

                case 3:
                    setDashboardTestMeChart((prevState: any) => {
                        return {
                            ...prevState,
                            testMeChartFetched: true,
                            dashboardTestMeChartDataFetched: true
                        };
                    });


                    break;

                case 4:
                    setDashboardDoItForMeChart((prevState: any) => {
                        return {
                            ...prevState,
                            doItForMeChartFetched: true,
                            dashboardDoItForMeChartDataFetched: true
                        };
                    });


                    break;

                case 5:
                    setDashboardGuideTutorialsChart((prevState: any) => {
                        return {
                            ...prevState,
                            guideTutorialsChartFetched: true,
                            dashboardGuideTutorialsChartDataFetched: true
                        };
                    });


                    break;

                case 6:
                    setDashboardTooltipChart((prevState: any) => {
                        return {
                            ...prevState,
                            tooltipChartFetched: true,
                            dashboardTooltipChartDataFetched: true
                        };
                    });


                    break;

                case 7:
                    setDashboardPushnotificationChart((prevState: any) => {
                        return {
                            ...prevState,
                            pushnotificationChartFetched: true,
                            dashboardPushnotificationChartDataFetched: true
                        };
                    });


                    break;

                case 8:
                    setDashboardBeaconChart((prevState: any) => {
                        return {
                            ...prevState,
                            beaconChartFetched: true,
                            dashboardBeaconChartDataFetched: true
                        };
                    });


                    break;

                case 9:
                    setDashboardSurveyGraph((prevState: any) => {
                        return {
                            ...prevState,
                            surveyChartFetched: true,
                            dashboardSurveyChartDataFetched: true
                        };
                    });
                    break;

                default:
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [tabKey, fetched_details.dashboard.dashboardGuideMeChartData])

    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false);
            switch (optn) {
                case 0:
                    setDashboardGuideMeChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardGuideMeChartDataFetched: false,
                            guideMeChartFetched: false,
                            guideMeChart: {}
                        };
                    });

                    setDashboardShowMeChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardShowMeChartDataFetched: false,
                            showMeChartFetched: false,
                            showMeChart: {}
                        };
                    });

                    setDashboardTestMeChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardTestMeChartDataFetched: false,
                            testMeChartFetched: false,
                            testMeChart: {}
                        };
                    });

                    setDashboardDoItForMeChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardDoItForMeChartDataFetched: false,
                            doItForMeChartFetched: false,
                            doItForMeChart: {}
                        };
                    });


                    setDashboardGuideTutorialsChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardGuideTutorialsChartDataFetched: false,
                            guideTutorialsChartFetched: false,
                            guideTutorialsChart: {}
                        };
                    });

                    setDashboardTooltipChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardTooltipChartDataFetched: false,
                            tooltipChartFetched: false,
                            tooltipChart: {}
                        };
                    });

                    setDashboardPushnotificationChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardPushnotificationChartDataFetched: false,
                            pushnotificationChartFetched: false,
                            pushnotificationChart: {}
                        };
                    });

                    setDashboardBeaconChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardBeaconChartDataFetched: false,
                            beaconChartFetched: false,
                            beaconChart: {}
                        };
                    });

                    setDashboardSurveyGraph((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardSurveyChartDataFetched: false,
                            surveyChartFetched: false,
                            surveyChart: {}
                        };
                    });


                    // setDashboardGuideSummary((prevState: any) => {
                    //     return {
                    //         ...prevState,
                    //         dashboardGuideSummaryFetched: false,
                    //     }
                    // })

                    setTotalSummaryCount((prevState: any) => {
                        return {
                            ...prevState,
                            totalSummaryCountFetched: false,
                            tableData: {},
                            totalSummaryCountTable: {}
                        }
                    })

                    setDashboardValidationChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardValidationChartDataFetched: false,
                        }
                    })

                    setDashboardPowerhtmlChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardPowerhtmlChartDataFetched: false,
                        }
                    })

                    setDashboardDucttapeChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardDucttapeChartDataFetched: false,
                        }
                    })

                    setDashboardGuidancevalidationChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardGuidancevalidationChartDataFetched: false,
                        }
                    })

                    setDashboardGuidancevalidationChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardGuidanceChartDataFetched: false,
                        }
                    })

                    setDashboardGuidanceChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardGuidanceChartDataFetched: false,
                        }
                    })

                    setDashboardTotalGuideCount((prevState) => {
                        return {
                            ...prevState,
                            isLoading: false,
                            totalGuideCountFetched: false,
                            totalGuideCountData: []
                        }
                    });

                    getDashboardGuidesCount(request_uuid.value)
                    getDashboardGuideMeChartData(request_uuid.value);
                    if (process.env.REACT_APP_WESTPAC === 'false') {
                        getDashboardShowMeChartData(request_uuid.value);
                        getDashboardTestMeChartData(request_uuid.value);
                        getDashboardDoItForMeChartData(request_uuid.value);
                    }
                    getDashboardGuideTutorialsChartData(request_uuid.value);
                    getDashboardTooltipChartData(request_uuid.value);
                    getDashboardPushnotificationChartData(request_uuid.value);
                    getDashboardBeaconChartData(request_uuid.value);
                    getDashboardSurveyGraphData(request_uuid.value);
                    getDashboardValidationChartData(request_uuid.value);
                    getDashboardPowerhtmlChartData(request_uuid.value);
                    getDashboardDucttapeChartData(request_uuid.value);
                    getDashboardGuidancevalidationChartData(request_uuid.value);
                    getDashboardGuidanceChartData(request_uuid.value);
                    getDashboardGuidesCount(request_uuid.value);


                    /*
                        getDashboardGuideMeChartData();
                        getDashboardShowMeChartData();
                        getDashboardTestMeChartData();
                        getDashboardDoItForMeChartData();
                        getDashboardGuideTutorialsChartData();
                        getDashboardTooltipChartData();
                        getDashboardPushnotificationChartData();
                        getDashboardBeaconChartData();
                        getDashboardSurveyGraphData();
                    */


                    // getDashboardGuideSummary();
                    break;
                // case 2:
                //     setDashboardTestMeChart((prevState: any) => {
                //         return {
                //             ...prevState,
                //             dashboardTestMeChartDataFetched: false,
                //             testMeChartFetched: false,
                //             testMeChart: {}
                //         };
                //     });
                //     getDashboardTestMeChartData();
                //     break;

                // case 3:
                //     setDashboardDoItForMeChart((prevState: any) => {
                //         return {
                //             ...prevState,
                //             dashboardDoItForMeChartDataFetched: false,
                //             doItForMeChartFetched: false,
                //             doItForMeChart: {}
                //         };
                //     });
                //     getDashboardDoItForMeChartData();
                //     break;


                // case 4:
                //     setDashboardGuideTutorialsChart((prevState: any) => {
                //         return {
                //             ...prevState,
                //             dashboardGuideTutorialsChartDataFetched: false,
                //             guideTutorialsChartFetched: false,
                //             guideTutorialsChart: {}
                //         };
                //     });
                //     getDashboardGuideTutorialsChartData();
                //     break;



                case 1:
                    setDashboardWorkflowChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardWorkflowChartDataFetched: false,
                            workflowChartFetched: false,
                            workflowChart: {}
                        }
                    })
                    setDashboardWorkflowTableData((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardWorkflowTableDataFetched: false,
                        }
                    })
                    // getDashboardWorkflowChartData();
                    // getDashboardWorkflowTableData();
                    break;

                case 2:
                    // setDashboardSurveyChart((prevState: any) => {
                    //     return {
                    //         ...prevState,
                    //         dashboardSurveyChartDataFetched: false,
                    //     }
                    // })

                    setDashboardSurveyData((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardSurveyDataFetched: false,
                        }
                    })
                    // getDashboardSurveyChartData();
                    getDashboardSurveysData(request_uuid.value);
                    break;
                case 3:
                    setDashboardCountriesChartData((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardCountriesChartDataFetched: false
                        }
                    })

                    getDashboardCountriesChartData(request_uuid.value);
                    break;
                case 4:
                    setDashboardGuideSummary((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardGuideSummaryFetched: false,
                        }
                    })
                    getDashboardGuideSummary(request_uuid.value);
                    break;

                case 5:
                    // if (userEngagementLazyLoadChart.lazyLoad_flag) {
                    //     setUserEngagementLazyLoadChart((prevState: any) => {
                    //         return {
                    //             ...prevState,
                    //             refreshFlag: !(userEngagementLazyLoadChart.refreshFlag)
                    //         }
                    //     })
                    // }

                    setDashboardUserEngagementChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardUserEngagementChartDataFetched: false,
                            userEngagementChartFetched: false,
                            userEngagementChart: {}
                        };
                    });
                    getDashboardUserEngagementChartData(request_uuid.value);

                    break;

                case 6:
                    setDashboardTooltipSummary((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardTooltipSummaryFetched: false,
                        }
                    })
                    getDashboardTooltipSummary(request_uuid.value);
                    break;
                default:
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const closeExportModal = () => {
        try {
            setExportModal((prevState: any) => {
                return {
                    ...prevState,
                    open: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const openExportModal = (event: any) => {
        try {
            event.stopPropagation();
            setExportModal((prevState: any) => {
                return {
                    ...prevState,
                    open: true,
                    defaultValue: 'player'
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const getErrorFlag = (flag: boolean) => {
        try {
            setExportModal((prevState: any) => {
                return {
                    ...prevState,
                    exportDisabled: flag,
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    const getDates = (data: any) => {
        try {
            setExportModal((prevState: any) => {
                return {
                    ...prevState,
                    start_date: data.start_date,
                    end_date: data.end_date,
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const handleExpansion = (event: any) => {
        try {
            setDashboardGuideMeChart((prevState: any) => {
                return {
                    ...prevState,
                    guideMeChartFetched: true,
                    dashboardGuideMeChartDataFetched: true
                };
            });

            setDashboardShowMeChart((prevState: any) => {
                return {
                    ...prevState,
                    showMeChartFetched: true,
                    dashboardShowMeChartDataFetched: true
                };
            });

            setDashboardTestMeChart((prevState: any) => {
                return {
                    ...prevState,
                    testMeChartFetched: true,
                    dashboardTestMeChartDataFetched: true
                };
            });

            setDashboardDoItForMeChart((prevState: any) => {
                return {
                    ...prevState,
                    doItForMeChartFetched: true,
                    dashboardDoItForMeChartDataFetched: true
                };
            });

            setDashboardGuideTutorialsChart((prevState: any) => {
                return {
                    ...prevState,
                    guideTutorialsChartFetched: true,
                    dashboardGuideTutorialsChartDataFetched: true
                };
            });

            setDashboardTooltipChart((prevState: any) => {
                return {
                    ...prevState,
                    tooltipChartFetched: true,
                    dashboardTooltipChartDataFetched: true
                };
            });

            setDashboardPushnotificationChart((prevState: any) => {
                return {
                    ...prevState,
                    pushnotificationChartFetched: true,
                    dashboardPushnotificationChartDataFetched: true
                };
            });

            setDashboardBeaconChart((prevState: any) => {
                return {
                    ...prevState,
                    beaconChartFetched: true,
                    dashboardBeaconChartDataFetched: true
                };
            });

            setDashboardSurveyGraph((prevState: any) => {
                return {
                    ...prevState,
                    surveyChartFetched: true,
                    dashboardSurveyChartDataFetched: true
                };
            });

            setExpanded((prevState) => {
                return !prevState;
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    const tooltipText = (selected: boolean) => {
        return (<Tooltip id="tooltipText">
            <br></br>
            <div><span className="fontGreyCard">{!selected ? insightsLbls.expandViews : insightsLbls.collapseViews}</span></div>
            <br></br>
        </Tooltip>)
    }

    const getEventSourceValues = (type: string, eventSources: any) => {
        try {
            setExportModal((prevState: any) => {
                return {
                    ...prevState,
                    event_source: eventSources,
                    defaultValue: eventSources
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    const getSelectedAppsForExport = (data: any) => {
        try {
            let paramsString = '';

            let itr = 0;
            for (let item of data) {
                if (itr !== 0) {
                    paramsString += "&app_id=" + item['app_id'];
                } else {
                    paramsString += item['app_id']
                }
                itr++;
            }

            setExportModal((prevState: any) => {
                return {
                    ...prevState,
                    app_id: paramsString,
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@



    //GuideMe
    useEffect(() => {
        try {
            if (
                fetched_details?.dashboard?.dashboardGuideMeChartData?.data !== undefined &&
                fetched_details?.dashboard?.dashboardGuideMeChartData?.result === "success" &&
                fetched_details?.dashboard?.dashboardGuideMeChartData?.requestUUID === request_uuid.value
            ) {
                GuideMeMount.current = true;

                let guideMeChartData: any = [];
                let optionEntities: any = [];
                let guideMeChartOptions: any = [];
                guideMeChartData = fetched_details?.dashboard?.dashboardGuideMeChartData?.data?.graphData;
                optionEntities = fetched_details?.dashboard?.dashboardGuideMeChartData?.data?.legends;

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    guideMeChartOptions.push({
                        key: optionEntities[itr0].legendTitle?.trim(),
                        color: optionEntities[itr0].color,
                        area: optionEntities[itr0].area,
                        associatedDataPoint: optionEntities[itr0].associatedDataPoint
                    })
                }



                for (let itx1 = 0; itx1 < optionEntities.length; itx1++) {
                    let cnt = 0;
                    for (let itx2 = 0; itx2 < guideMeChartData.length; itx2++) {
                        cnt = cnt + guideMeChartData[itx2]['counts'][optionEntities[itx1].associatedDataPoint]
                    }
                    optionEntities[itx1].count = cnt
                }




                let chartData_entites_dummy = [];
                let optionData_entites_dummy = [];
                let chartData_values_dummy = { counts: {} };

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    chartData_entites_dummy.push(optionEntities[itr0]['legendTitle']);
                    optionData_entites_dummy.push({
                        key: optionEntities[itr0]['legendTitle'],
                        color: optionEntities[itr0]['color']
                    });
                    chartData_values_dummy.counts[optionEntities[itr0]['legendTitle']] = optionEntities[itr0]['count'];
                }


                setDashboardGuideMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadGuideMeChartData: [...guideMeChartData],
                        loadGuideMeChartOptions: guideMeChartOptions,
                        guideMeChartFetched: true,
                        dashboardGuideMeChartDataFetched: true,
                        bubbleChartData: optionEntities,
                        selectedAppCount: fetched_details?.dashboard?.dashboardGuideMeChartData?.data?.metadata?.length,

                        chartData_entites: chartData_entites_dummy,
                        optionData_entites: optionData_entites_dummy,
                        chartData_values: chartData_values_dummy,
                    };
                });




                // setDashboardGuideMeChart((prevState: any) => {
                //     return {
                //         ...prevState,
                //         loadGuideMeChartData: [...guideMeChartData],
                //         loadGuideMeChartOptions: [...guideMeChartOptions],
                //         dashboardGuideMeChartDataFetched: true,
                //     };
                // });

                apiCatchError(0, 0);
                enableExportPdf(0, true);
            } else if (fetched_details.dashboard.dashboardGuideMeChartData.result === 'retry') {
                getDashboardGuideMeChartData(request_uuid.value, 'retry');
            } else if (fetched_details.dashboard.dashboardGuideMeChartData.result === "error") {
                setDashboardGuideMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardGuideMeChartDataFetched: true,
                    };
                });

                if (fetched_details.dashboard.dashboardGuideMeChartData.errorCode === 503) {
                    apiCatchError(0, 503);
                } else {
                    apiCatchError(0, 1);
                }


                enableExportPdf(0, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.dashboard?.dashboardGuideMeChartData]);

    //ShowMe
    useEffect(() => {
        try {
            if (
                fetched_details.dashboard.dashboardShowMeChartData.data !== undefined &&
                fetched_details.dashboard.dashboardShowMeChartData.result === "success" &&
                fetched_details?.dashboard?.dashboardShowMeChartData?.requestUUID === request_uuid.value
            ) {
                ShowMeMount.current = true;
                let showMeChartData: any = [];
                let optionEntities: any = [];
                let showMeChartOptions: any = [];
                showMeChartData = fetched_details.dashboard.dashboardShowMeChartData.data.graphData;
                optionEntities = fetched_details.dashboard.dashboardShowMeChartData.data.legends;

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    showMeChartOptions[itr0] = {
                        key: optionEntities[itr0].legendTitle?.trim(),
                        color: optionEntities[itr0].color,
                        area: optionEntities[itr0].area,
                        associatedDataPoint: optionEntities[itr0].associatedDataPoint
                    }
                }

                for (let itx1 = 0; itx1 < optionEntities.length; itx1++) {
                    let cnt = 0;
                    for (let itx2 = 0; itx2 < showMeChartData.length; itx2++) {
                        cnt = cnt + showMeChartData[itx2]['counts'][optionEntities[itx1].associatedDataPoint]
                    }
                    optionEntities[itx1].count = cnt
                }


                let chartData_entites_dummy = [];
                let optionData_entites_dummy = [];
                let chartData_values_dummy = { counts: {} };

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    chartData_entites_dummy.push(optionEntities[itr0]['legendTitle']);
                    optionData_entites_dummy.push({
                        key: optionEntities[itr0]['legendTitle'],
                        color: optionEntities[itr0]['color']
                    });
                    chartData_values_dummy.counts[optionEntities[itr0]['legendTitle']] = optionEntities[itr0]['count'];
                }



                setDashboardShowMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadShowMeChartData: [...showMeChartData],
                        loadShowMeChartOptions: [...showMeChartOptions],
                        showMeChartFetched: true,
                        bubbleChartData: optionEntities,
                        dashboardShowMeChartDataFetched: true,
                        selectedAppCount: fetched_details?.dashboard?.dashboardShowMeChartData?.data?.metadata?.length,

                        chartData_entites: chartData_entites_dummy,
                        optionData_entites: optionData_entites_dummy,
                        chartData_values: chartData_values_dummy,
                    };
                });
                apiCatchError(1, 0);
                enableExportPdf(1, true);
            } else if (fetched_details.dashboard.dashboardShowMeChartData.result === 'retry') {
                getDashboardShowMeChartData(request_uuid.value, 'retry');
            } else if (fetched_details.dashboard.dashboardShowMeChartData.result === "error") {
                setDashboardShowMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardShowMeChartDataFetched: true,
                    };
                });
                if (fetched_details.dashboard.dashboardShowMeChartData.errorCode === 503) {
                    apiCatchError(1, 503);
                } else {
                    apiCatchError(1, 1);
                }
                enableExportPdf(1, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.dashboard.dashboardShowMeChartData]);

    //TestMe
    useEffect(() => {
        try {
            if (
                fetched_details.dashboard.dashboardTestMeChartData.data !== undefined &&
                fetched_details.dashboard.dashboardTestMeChartData.result === "success" &&
                fetched_details?.dashboard?.dashboardTestMeChartData?.requestUUID === request_uuid.value
            ) {
                TestMeMount.current = true;
                let testMeChartData: any = [];
                let optionEntities: any = [];
                let testMeChartOptions: any = [];
                testMeChartData = fetched_details.dashboard.dashboardTestMeChartData.data.graphData;
                optionEntities = fetched_details.dashboard.dashboardTestMeChartData.data.legends;

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    testMeChartOptions[itr0] = {
                        key: optionEntities[itr0].legendTitle?.trim(),
                        color: optionEntities[itr0].color,
                        area: optionEntities[itr0].area,
                        associatedDataPoint: optionEntities[itr0].associatedDataPoint
                    }
                }

                for (let itx1 = 0; itx1 < optionEntities.length; itx1++) {
                    let cnt = 0;
                    for (let itx2 = 0; itx2 < testMeChartData.length; itx2++) {
                        cnt = cnt + testMeChartData[itx2]['counts'][optionEntities[itx1].associatedDataPoint]
                    }
                    optionEntities[itx1].count = cnt
                }

                let chartData_entites_dummy = [];
                let optionData_entites_dummy = [];
                let chartData_values_dummy = { counts: {} };

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    chartData_entites_dummy.push(optionEntities[itr0]['legendTitle']);
                    optionData_entites_dummy.push({
                        key: optionEntities[itr0]['legendTitle'],
                        color: optionEntities[itr0]['color']
                    });
                    chartData_values_dummy.counts[optionEntities[itr0]['legendTitle']] = optionEntities[itr0]['count'];
                }

                setDashboardTestMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadTestMeChartData: [...testMeChartData],
                        loadTestMeChartOptions: [...testMeChartOptions],
                        dashboardTestMeChartDataFetched: true,
                        testMeChartFetched: true,
                        bubbleChartData: optionEntities,
                        selectedAppCount: fetched_details?.dashboard?.dashboardTestMeChartData?.data?.metadata?.length,
                        chartData_entites: chartData_entites_dummy,
                        optionData_entites: optionData_entites_dummy,
                        chartData_values: chartData_values_dummy,
                    };
                });
                apiCatchError(2, 0);
                enableExportPdf(2, true);

            } else if (fetched_details.dashboard.dashboardTestMeChartData.result === 'retry') {
                getDashboardTestMeChartData(request_uuid.value, 'retry');
            } else if (fetched_details.dashboard.dashboardTestMeChartData.result === "error") {
                setDashboardTestMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardTestMeChartDataFetched: true,
                    };
                });
                if (fetched_details.dashboard.dashboardTestMeChartData.errorCode === 503) {
                    apiCatchError(2, 503);
                } else {
                    apiCatchError(2, 1);
                }
                enableExportPdf(2, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.dashboard.dashboardTestMeChartData]);

    //DoItForMe
    useEffect(() => {
        try {
            if (
                fetched_details.dashboard.dashboardDoItForMeChartData.data !== undefined &&
                fetched_details.dashboard.dashboardDoItForMeChartData.result === "success" &&
                fetched_details?.dashboard?.dashboardDoItForMeChartData?.requestUUID === request_uuid.value
            ) {
                DoItForMeMount.current = true;
                let doItForMeChartData: any = [];
                let optionEntities: any = [];
                let doItForMeChartOptions: any = [];
                doItForMeChartData = fetched_details.dashboard.dashboardDoItForMeChartData.data.graphData;
                optionEntities = fetched_details.dashboard.dashboardDoItForMeChartData.data.legends;

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    doItForMeChartOptions[itr0] = {
                        key: optionEntities[itr0].legendTitle?.trim(),
                        color: optionEntities[itr0].color,
                        area: optionEntities[itr0].area,
                        associatedDataPoint: optionEntities[itr0].associatedDataPoint
                    }
                }

                for (let itx1 = 0; itx1 < optionEntities.length; itx1++) {
                    let cnt = 0;
                    for (let itx2 = 0; itx2 < doItForMeChartData.length; itx2++) {
                        cnt = cnt + doItForMeChartData[itx2]['counts'][optionEntities[itx1].associatedDataPoint]
                    }
                    optionEntities[itx1].count = cnt
                }

                let chartData_entites_dummy = [];
                let optionData_entites_dummy = [];
                let chartData_values_dummy = { counts: {} };

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    chartData_entites_dummy.push(optionEntities[itr0]['legendTitle']);
                    optionData_entites_dummy.push({
                        key: optionEntities[itr0]['legendTitle'],
                        color: optionEntities[itr0]['color']
                    });
                    chartData_values_dummy.counts[optionEntities[itr0]['legendTitle']] = optionEntities[itr0]['count'];
                }

                setDashboardDoItForMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadDoItForMeChartData: [...doItForMeChartData],
                        loadDoItForMeChartOptions: [...doItForMeChartOptions],
                        doItForMeChartFetched: true,
                        dashboardDoItForMeChartDataFetched: true,
                        bubbleChartData: optionEntities,
                        selectedAppCount: fetched_details?.dashboard?.dashboardDoItForMeChartData?.data?.metadata?.length,
                        chartData_entites: chartData_entites_dummy,
                        optionData_entites: optionData_entites_dummy,
                        chartData_values: chartData_values_dummy,
                    };
                });

                apiCatchError(3, 0);
                enableExportPdf(3, true);
            } else if (fetched_details.dashboard.dashboardDoItForMeChartData.result === 'retry') {
                getDashboardDoItForMeChartData(request_uuid.value, 'retry');
            } else if (fetched_details.dashboard.dashboardDoItForMeChartData.result === "error") {
                setDashboardDoItForMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardDoItForMeChartDataFetched: true,
                    };
                });
                if (fetched_details.dashboard.dashboardDoItForMeChartData.errorCode === 503) {
                    apiCatchError(3, 503);
                } else {
                    apiCatchError(3, 1);
                }
                enableExportPdf(3, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.dashboard.dashboardDoItForMeChartData]);

    //GuideTutorials
    useEffect(() => {
        try {
            if (
                fetched_details.dashboard.dashboardGuideTutorialsChartData.data !== undefined &&
                fetched_details.dashboard.dashboardGuideTutorialsChartData.result === "success" &&
                fetched_details?.dashboard?.dashboardGuideTutorialsChartData?.requestUUID === request_uuid.value
            ) {
                GuideTutorialsMount.current = true;
                let guideTutorialsChartData: any = [];
                let optionEntities: any = [];
                let guideTutorialsChartOptions: any = [];
                guideTutorialsChartData = fetched_details.dashboard.dashboardGuideTutorialsChartData.data.graphData;
                optionEntities = fetched_details.dashboard.dashboardGuideTutorialsChartData.data.legends;


                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    guideTutorialsChartOptions[itr0] = {
                        key: optionEntities[itr0].legendTitle?.trim(),
                        color: optionEntities[itr0].color,
                        area: optionEntities[itr0].area,
                        associatedDataPoint: optionEntities[itr0].associatedDataPoint
                    }
                }

                for (let itx1 = 0; itx1 < optionEntities.length; itx1++) {
                    let cnt = 0;
                    for (let itx2 = 0; itx2 < guideTutorialsChartData.length; itx2++) {
                        cnt = cnt + guideTutorialsChartData[itx2]['counts'][optionEntities[itx1].associatedDataPoint]
                    }
                    optionEntities[itx1].count = cnt
                }

                let chartData_entites_dummy = [];
                let optionData_entites_dummy = [];
                let chartData_values_dummy = { counts: {} };


                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    chartData_entites_dummy.push(optionEntities[itr0]['legendTitle']);
                    optionData_entites_dummy.push({
                        key: optionEntities[itr0]['legendTitle'],
                        color: optionEntities[itr0]['color']
                    });
                    chartData_values_dummy.counts[optionEntities[itr0]['legendTitle']] = optionEntities[itr0]['count'];
                }

                setDashboardGuideTutorialsChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadGuideTutorialsChartData: [...guideTutorialsChartData],
                        loadGuideTutorialsChartOptions: [...guideTutorialsChartOptions],
                        guideTutorialsChartFetched: true,
                        dashboardGuideTutorialsChartDataFetched: true,
                        bubbleChartData: optionEntities,
                        selectedAppCount: fetched_details?.dashboard?.dashboardGuideTutorialsChartData?.data?.metadata?.length,
                        chartData_entites: chartData_entites_dummy,
                        optionData_entites: optionData_entites_dummy,
                        chartData_values: chartData_values_dummy,

                    };
                });

                apiCatchError(4, 0);
                enableExportPdf(4, true);
            } else if (fetched_details.dashboard.dashboardGuideTutorialsChartData.result === 'retry') {
                getDashboardGuideTutorialsChartData(request_uuid.value, 'retry');
            } else if (fetched_details.dashboard.dashboardGuideTutorialsChartData.result === "error") {
                setDashboardGuideTutorialsChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardGuideTutorialsChartDataFetched: true,
                    };
                });
                if (fetched_details.dashboard.dashboardGuideTutorialsChartData.errorCode === 503) {
                    apiCatchError(4, 503);
                } else {
                    apiCatchError(4, 1);
                }
                enableExportPdf(4, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.dashboard.dashboardGuideTutorialsChartData]);

    // TooltipData
    useEffect(() => {
        try {
            if (
                fetched_details.dashboard.dashboardTooltipChartData.data !== undefined &&
                fetched_details.dashboard.dashboardTooltipChartData.result === "success" &&
                fetched_details?.dashboard?.dashboardTooltipChartData?.requestUUID === request_uuid.value
            ) {

                // GuideTutorialsMount.current = true;
                let tooltipChartData: any = [];
                let optionEntities: any = [];
                let tooltipChartOptions: any = [];
                tooltipChartData = fetched_details.dashboard.dashboardTooltipChartData.data.graphData;
                optionEntities = fetched_details.dashboard.dashboardTooltipChartData.data.legends;

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    tooltipChartOptions[itr0] = {
                        key: optionEntities[itr0].legendTitle?.trim(),
                        color: optionEntities[itr0].color,
                        area: optionEntities[itr0].area,
                        associatedDataPoint: optionEntities[itr0].associatedDataPoint,
                    }
                }


                for (let itx1 = 0; itx1 < optionEntities.length; itx1++) {
                    let cnt = 0;
                    for (let itx2 = 0; itx2 < tooltipChartData.length; itx2++) {
                        cnt = cnt + tooltipChartData[itx2]['counts'][optionEntities[itx1].associatedDataPoint]
                    }
                    optionEntities[itx1].count = cnt
                }

                let chartData_entites_dummy = [];
                let optionData_entites_dummy = [];
                let chartData_values_dummy = { counts: {} };


                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    chartData_entites_dummy.push(optionEntities[itr0]['legendTitle']);
                    optionData_entites_dummy.push({
                        key: optionEntities[itr0]['legendTitle'],
                        color: optionEntities[itr0]['color']
                    });
                    chartData_values_dummy.counts[optionEntities[itr0]['legendTitle']] = optionEntities[itr0]['count'];
                }

                setDashboardTooltipChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadTooltipChartData: [...tooltipChartData],
                        loadTooltipChartOptions: [...tooltipChartOptions],
                        tooltipChartFetched: true,
                        dashboardTooltipChartDataFetched: true,
                        bubbleChartData: optionEntities,
                        selectedAppCount: fetched_details?.dashboard?.dashboardTooltipChartData?.data?.metadata?.length,
                        chartData_entites: chartData_entites_dummy,
                        optionData_entites: optionData_entites_dummy,
                        chartData_values: chartData_values_dummy,
                    };
                });



                apiCatchError(10, 0);
                enableExportPdf(10, true);
            } else if (fetched_details.dashboard.dashboardTooltipChartData.result === 'retry') {
                getDashboardTooltipChartData(request_uuid.value, 'retry');
            } else if (fetched_details.dashboard.dashboardTooltipChartData.result === "error") {
                setDashboardTooltipChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardTooltipChartDataFetched: true,
                    };
                });
                if (fetched_details.dashboard.dashboardTooltipChartData.errorCode === 503) {
                    apiCatchError(10, 503);
                } else {
                    apiCatchError(10, 1);
                }
                enableExportPdf(10, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.dashboard.dashboardTooltipChartData]);

    // PushnotificationData
    useEffect(() => {
        try {
            if (
                fetched_details.dashboard.dashboardPushnotificationChartData.data !== undefined &&
                fetched_details.dashboard.dashboardPushnotificationChartData.result === "success" &&
                fetched_details?.dashboard?.dashboardPushnotificationChartData?.requestUUID === request_uuid.value
            ) {

                // GuideTutorialsMount.current = true;
                let pushnotificationChartData: any = [];
                let optionEntities: any = [];
                let pushnotificationChartOptions: any = [];
                pushnotificationChartData = fetched_details.dashboard.dashboardPushnotificationChartData.data.graphData;
                optionEntities = fetched_details.dashboard.dashboardPushnotificationChartData.data.legends;

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    pushnotificationChartOptions[itr0] = {
                        key: optionEntities[itr0].legendTitle?.trim(),
                        color: optionEntities[itr0].color,
                        area: optionEntities[itr0].area,
                        associatedDataPoint: optionEntities[itr0].associatedDataPoint
                    }
                }

                for (let itx1 = 0; itx1 < optionEntities.length; itx1++) {
                    let cnt = 0;
                    for (let itx2 = 0; itx2 < pushnotificationChartData.length; itx2++) {
                        cnt = cnt + pushnotificationChartData[itx2]['counts'][optionEntities[itx1].associatedDataPoint]
                    }
                    optionEntities[itx1].count = cnt
                }

                let chartData_entites_dummy = [];
                let optionData_entites_dummy = [];
                let chartData_values_dummy = { counts: {} };

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    chartData_entites_dummy.push(optionEntities[itr0]['legendTitle']);
                    optionData_entites_dummy.push({
                        key: optionEntities[itr0]['legendTitle'],
                        color: optionEntities[itr0]['color']
                    });
                    chartData_values_dummy.counts[optionEntities[itr0]['legendTitle']] = optionEntities[itr0]['count'];
                }
                setDashboardPushnotificationChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadPushnotificationChartData: [...pushnotificationChartData],
                        loadPushnotificationChartOptions: [...pushnotificationChartOptions],
                        pushnotificationChartFetched: true,
                        dashboardPushnotificationChartDataFetched: true,
                        bubbleChartData: optionEntities,
                        selectedAppCount: fetched_details?.dashboard?.dashboardPushnotificationChartData?.data?.metadata?.length,
                        chartData_entites: chartData_entites_dummy,
                        optionData_entites: optionData_entites_dummy,
                        chartData_values: chartData_values_dummy,
                    };
                });



                apiCatchError(11, 0);
                enableExportPdf(11, true);
            } else if (fetched_details.dashboard.dashboardPushnotificationChartData.result === 'retry') {
                getDashboardPushnotificationChartData(request_uuid.value, 'retry');
            } else if (fetched_details.dashboard.dashboardPushnotificationChartData.result === "error") {
                setDashboardPushnotificationChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardPushnotificationChartDataFetched: true,
                    };
                });
                if (fetched_details.dashboard.dashboardPushnotificationChartData.errorCode === 503) {
                    apiCatchError(11, 503);
                } else {
                    apiCatchError(11, 1);
                }
                enableExportPdf(11, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.dashboard.dashboardPushnotificationChartData]);

    // Beacon
    useEffect(() => {
        try {
            if (
                fetched_details.dashboard.dashboardBeaconChartData.data !== undefined &&
                fetched_details.dashboard.dashboardBeaconChartData.result === "success" &&
                fetched_details?.dashboard?.dashboardBeaconChartData?.requestUUID === request_uuid.value
            ) {

                // GuideTutorialsMount.current = true;
                let beaconChartData: any = [];
                let optionEntities: any = [];
                let beaconChartOptions: any = [];
                beaconChartData = fetched_details.dashboard.dashboardBeaconChartData.data.graphData;
                optionEntities = fetched_details.dashboard.dashboardBeaconChartData.data.legends;

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    beaconChartOptions[itr0] = {
                        key: optionEntities[itr0].legendTitle?.trim(),
                        color: optionEntities[itr0].color,
                        area: optionEntities[itr0].area,
                        associatedDataPoint: optionEntities[itr0].associatedDataPoint
                    }
                }

                for (let itx1 = 0; itx1 < optionEntities.length; itx1++) {
                    let cnt = 0;
                    for (let itx2 = 0; itx2 < beaconChartData.length; itx2++) {
                        cnt = cnt + beaconChartData[itx2]['counts'][optionEntities[itx1].associatedDataPoint]
                    }
                    optionEntities[itx1].count = cnt
                }

                let chartData_entites_dummy = [];
                let optionData_entites_dummy = [];
                let chartData_values_dummy = { counts: {} };

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    chartData_entites_dummy.push(optionEntities[itr0]['legendTitle']);
                    optionData_entites_dummy.push({
                        key: optionEntities[itr0]['legendTitle'],
                        color: optionEntities[itr0]['color']
                    });
                    chartData_values_dummy.counts[optionEntities[itr0]['legendTitle']] = optionEntities[itr0]['count'];
                }

                setDashboardBeaconChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadBeaconChartData: [...beaconChartData],
                        loadBeaconChartOptions: [...beaconChartOptions],
                        beaconChartFetched: true,
                        dashboardBeaconChartDataFetched: true,
                        bubbleChartData: optionEntities,
                        selectedAppCount: fetched_details?.dashboard?.dashboardBeaconChartData?.data?.metadata?.length,
                        chartData_entites: chartData_entites_dummy,
                        optionData_entites: optionData_entites_dummy,
                        chartData_values: chartData_values_dummy,
                    };
                });



                apiCatchError(12, 0);
                enableExportPdf(12, true);
            } else if (fetched_details.dashboard.dashboardBeaconChartData.result === 'retry') {
                getDashboardBeaconChartData(request_uuid.value, 'retry');
            } else if (fetched_details.dashboard.dashboardBeaconChartData.result === "error") {
                setDashboardBeaconChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardBeaconChartDataFetched: true,
                    };
                });
                if (fetched_details.dashboard.dashboardBeaconChartData.errorCode === 503) {
                    apiCatchError(12, 503);
                } else {
                    apiCatchError(12, 1);
                }
                enableExportPdf(12, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.dashboard.dashboardBeaconChartData]);


    useEffect(() => {
        try {
            if (
                fetched_details.dashboard.dashboardSurveyChartData.data !== undefined &&
                fetched_details.dashboard.dashboardSurveyChartData.result === "success" &&
                fetched_details?.dashboard?.dashboardSurveyChartData?.requestUUID === request_uuid.value
            ) {

                // GuideTutorialsMount.current = true;
                let surveyChartData: any = [];
                let optionEntities: any = [];
                let surveyChartOptions: any = [];
                surveyChartData = fetched_details.dashboard.dashboardSurveyChartData.data.graphData;
                optionEntities = fetched_details.dashboard.dashboardSurveyChartData.data.legends;

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    surveyChartOptions[itr0] = {
                        key: optionEntities[itr0].legendTitle?.trim(),
                        color: optionEntities[itr0].color,
                        area: optionEntities[itr0].area,
                        associatedDataPoint: optionEntities[itr0].associatedDataPoint
                    }
                }

                for (let itx1 = 0; itx1 < optionEntities.length; itx1++) {
                    let cnt = 0;
                    for (let itx2 = 0; itx2 < surveyChartData.length; itx2++) {
                        cnt = cnt + surveyChartData[itx2]['counts'][optionEntities[itx1].associatedDataPoint]
                    }
                    optionEntities[itx1].count = cnt
                }

                let chartData_entites_dummy = [];
                let optionData_entites_dummy = [];
                let chartData_values_dummy = { counts: {} };

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    chartData_entites_dummy.push(optionEntities[itr0]['legendTitle']);
                    optionData_entites_dummy.push({
                        key: optionEntities[itr0]['legendTitle'],
                        color: optionEntities[itr0]['color']
                    });
                    chartData_values_dummy.counts[optionEntities[itr0]['legendTitle']] = optionEntities[itr0]['count'];
                }

                setDashboardSurveyGraph((prevState: any) => {
                    return {
                        ...prevState,
                        loadSurveyChartData: [...surveyChartData],
                        loadSurveyChartOptions: [...surveyChartOptions],
                        dashboardSurveyChartDataFetched: true,
                        SurveyChartFetched: true,
                        bubbleChartData: optionEntities,
                        selectedAppCount: fetched_details?.dashboard?.dashboardSurveyChartData?.data?.metadata?.length,
                        chartData_entites: chartData_entites_dummy,
                        optionData_entites: optionData_entites_dummy,
                        chartData_values: chartData_values_dummy,
                    };
                });

                apiCatchError(13, 0);
                enableExportPdf(13, true);
            } else if (fetched_details.dashboard.dashboardSurveyChartData.result === 'retry') {
                getDashboardSurveyGraphData(request_uuid.value, 'retry');
            } else if (fetched_details.dashboard.dashboardSurveyChartData.result === "error") {
                setDashboardSurveyGraph((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardSurveyChartDataFetched: true,
                    };
                });
                apiCatchError(13, 1);
                enableExportPdf(13, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.dashboard.dashboardSurveyChartData]);

    //Validation
    useEffect(() => {
        try {
            if (
                fetched_details?.dashboard?.dashboardValidationChartData?.data !== undefined &&
                fetched_details?.dashboard?.dashboardValidationChartData?.result === "success" &&
                fetched_details?.dashboard?.dashboardValidationChartData?.requestUUID === request_uuid.value
            ) {

                setDashboardValidationChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardValidationChartDataFetched: true,
                    };
                });

                apiCatchError(14, 0);
                enableExportPdf(14, true);

            } else if (fetched_details.dashboard.dashboardValidationChartData.result === 'retry') {
                getDashboardValidationChartData(request_uuid.value, 'retry');
            } else if (fetched_details.dashboard.dashboardValidationChartData.result === "error") {
                setDashboardValidationChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardValidationChartDataFetched: true,
                    };
                });

                apiCatchError(14, 1);
                enableExportPdf(14, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.dashboard?.dashboardValidationChartData]);

    //PowerHtml
    useEffect(() => {
        try {
            if (
                fetched_details?.dashboard?.dashboardPowerhtmlChartData?.data !== undefined &&
                fetched_details?.dashboard?.dashboardPowerhtmlChartData?.result === "success" &&
                fetched_details?.dashboard?.dashboardPowerhtmlChartData?.requestUUID === request_uuid.value
            ) {
                setDashboardPowerhtmlChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardPowerhtmlChartDataFetched: true,
                    };
                });

                apiCatchError(15, 0);
                enableExportPdf(15, true);

            } else if (fetched_details.dashboard.dashboardPowerhtmlChartData.result === 'retry') {
                getDashboardPowerhtmlChartData(request_uuid.value, 'retry');
            } else if (fetched_details.dashboard.dashboardPowerhtmlChartData.result === "error") {
                setDashboardPowerhtmlChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardPowerhtmlChartDataFetched: true,
                    };
                });

                apiCatchError(15, 1);
                enableExportPdf(15, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.dashboard?.dashboardPowerhtmlChartData]);

    //DuctTape
    useEffect(() => {
        try {
            if (
                fetched_details?.dashboard?.dashboardDucttapeChartData?.data !== undefined &&
                fetched_details?.dashboard?.dashboardDucttapeChartData?.result === "success" &&
                fetched_details?.dashboard?.dashboardDucttapeChartData?.requestUUID === request_uuid.value
            ) {
                setDashboardDucttapeChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardDucttapeChartDataFetched: true,
                    };
                });

                apiCatchError(16, 0);
                enableExportPdf(16, true);

            } else if (fetched_details.dashboard.dashboardDucttapeChartData.result === 'retry') {
                getDashboardDucttapeChartData(request_uuid.value, 'retry');
            } else if (fetched_details.dashboard.dashboardDucttapeChartData.result === "error") {
                setDashboardDucttapeChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardDucttapeChartDataFetched: true,
                    };
                });

                apiCatchError(16, 1);
                enableExportPdf(16, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.dashboard?.dashboardDucttapeChartData]);

    //GuidanceValidation
    useEffect(() => {
        try {
            if (
                fetched_details?.dashboard?.dashboardGuidancevalidationChartData?.data !== undefined &&
                fetched_details?.dashboard?.dashboardGuidancevalidationChartData?.result === "success" &&
                fetched_details?.dashboard?.dashboardGuidancevalidationChartData?.requestUUID === request_uuid.value
            ) {
                setDashboardGuidancevalidationChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardGuidancevalidationChartDataFetched: true,
                    };
                });

                apiCatchError(17, 0);
                enableExportPdf(17, true);

            } else if (fetched_details.dashboard.dashboardGuidancevalidationChartData.result === 'retry') {
                getDashboardGuidancevalidationChartData(request_uuid.value, 'retry');
            } else if (fetched_details.dashboard.dashboardGuidancevalidationChartData.result === "error") {
                setDashboardGuidancevalidationChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardGuidancevalidationChartDataFetched: true,
                    };
                });

                apiCatchError(17, 1);
                enableExportPdf(17, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.dashboard?.dashboardGuidancevalidationChartData]);

    //Guidance
    useEffect(() => {
        try {
            if (
                fetched_details?.dashboard?.dashboardGuidanceChartData?.data !== undefined &&
                fetched_details?.dashboard?.dashboardGuidanceChartData?.result === "success" &&
                fetched_details?.dashboard?.dashboardGuidanceChartData?.requestUUID === request_uuid.value
            ) {
                setDashboardGuidanceChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardGuidanceChartDataFetched: true,
                    };
                });

                apiCatchError(18, 0);
                enableExportPdf(18, true);

            } else if (fetched_details.dashboard.dashboardGuidanceChartData.result === 'retry') {
                getDashboardGuidanceChartData(request_uuid.value, 'retry');
            } else if (fetched_details.dashboard.dashboardGuidanceChartData.result === "error") {
                setDashboardGuidanceChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardGuidanceChartDataFetched: true,
                    };
                });

                apiCatchError(18, 1);
                enableExportPdf(18, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.dashboard?.dashboardGuidanceChartData]);

    //Guide Summary Table Data
    useEffect(() => {
        try {

            if (
                fetched_details.dashboard.dashboardGuideSummaryTableData.data !== undefined &&
                fetched_details.dashboard.dashboardGuideSummaryTableData.result === "success" &&
                fetched_details?.dashboard?.dashboardGuideSummaryTableData?.requestUUID === request_uuid.value
            ) {
                GuideSummarysMount.current = true;
                setDashboardGuideSummary((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardGuideSummaryFetched: true,
                    };
                });

                enableExportPdf(5, true);
                apiCatchError(5, 0);
            } else if (fetched_details.dashboard.dashboardGuideSummaryTableData.result === 'retry') {
                getDashboardGuideSummary(request_uuid.value, 'retry');
            } else if (
                fetched_details.dashboard.dashboardGuideSummaryTableData.result === "error"
            ) {
                setDashboardGuideSummary((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardGuideSummaryFetched: true,
                    };
                });
                enableExportPdf(5, false);
                apiCatchError(5, 1);
            }

        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.dashboard.dashboardGuideSummaryTableData]);


    //Tooltip Summary Table Data
    useEffect(() => {
        try {
            if (
                fetched_details.dashboard.dashboardTooltipSummaryTableData.data !== undefined &&
                fetched_details.dashboard.dashboardTooltipSummaryTableData.result === "success" &&
                fetched_details?.dashboard?.dashboardTooltipSummaryTableData?.requestUUID === request_uuid.value
            ) {

                setDashboardTooltipSummary((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardTooltipSummaryFetched: true,
                    };
                });

                enableExportPdf(19, true);
                apiCatchError(19, 0);
            } else if (fetched_details.dashboard.dashboardTooltipSummaryTableData.result === 'retry') {
                getDashboardTooltipSummary(request_uuid.value, 'retry');
            } else if (
                fetched_details.dashboard.dashboardTooltipSummaryTableData.result === "error"
            ) {
                setDashboardTooltipSummary((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardTooltipSummaryFetched: true,
                    };
                });
                enableExportPdf(19, false);
                apiCatchError(19, 1);
            }

        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details.dashboard.dashboardTooltipSummaryTableData]);

    //WorkFlow chart Data
    useEffect(() => {
        try {
            if (
                fetched_details.dashboard.dashboardWorkflowChartData.data !== undefined &&
                fetched_details.dashboard.dashboardWorkflowChartData.result === "success" &&
                fetched_details?.dashboard?.dashboardWorkflowChartData?.requestUUID === request_uuid.value
            ) {
                WorkflowChartMount.current = true;
                let workflowChartData: any = [];
                let optionEntities: any = [];
                let workflowChartOptions: any = [];
                workflowChartData = fetched_details.dashboard.dashboardWorkflowChartData.data.graphData;
                optionEntities = fetched_details.dashboard.dashboardWorkflowChartData.data.legends;

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    workflowChartOptions[itr0] = {
                        key: optionEntities[itr0].legendTitle?.trim(),
                        color: optionEntities[itr0].color,
                        area: optionEntities[itr0].area,
                        associatedDataPoint: optionEntities[itr0].associatedDataPoint
                    }
                }

                setDashboardWorkflowChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadWorkflowChartData: [...workflowChartData],
                        loadWorkflowChartOptions: [...workflowChartOptions],
                        dashboardWorkflowChartDataFetched: true,
                    };
                });
                apiCatchError(6, 0);
                enableExportPdf(6, true);
            } else if (fetched_details.dashboard.dashboardWorkflowChartData.result === 'retry') {
                getDashboardWorkflowChartData(request_uuid.value, 'retry');
            } else if (fetched_details.dashboard.dashboardWorkflowChartData.result === "error") {
                setDashboardWorkflowChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardWorkflowChartDataFetched: true,
                    };
                });
                apiCatchError(6, 1);
                enableExportPdf(6, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.dashboard.dashboardWorkflowChartData]);

    // Workflow table data
    useEffect(() => {
        try {
            if (
                fetched_details.dashboard.dashboardWorkflowsTableData.data !== undefined &&
                fetched_details.dashboard.dashboardWorkflowsTableData.result === "success" &&
                fetched_details?.dashboard?.dashboardWorkflowsTableData?.requestUUID === request_uuid.value
            ) {
                WorkflowTableMount.current = true;
                setDashboardWorkflowTableData((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardWorkflowTableDataFetched: true,
                    };
                });

                apiCatchError(7, 0);
                enableExportPdf(7, true);
            } else if (fetched_details.dashboard.dashboardWorkflowsTableData.result === 'retry') {
                getDashboardWorkflowTableData(request_uuid.value, 'retry');
            } else if (
                fetched_details.dashboard.dashboardWorkflowsTableData.result === "error"
            ) {
                setDashboardWorkflowTableData((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardWorkflowTableDataFetched: true,
                    };
                });
                apiCatchError(7, 1);
                enableExportPdf(7, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.dashboard.dashboardWorkflowsTableData]);

    // Survey table data
    useEffect(() => {
        try {
            if (
                fetched_details.dashboard.dashboardSurveysTableData.data !== undefined &&
                fetched_details.dashboard.dashboardSurveysTableData.result === "success" &&
                fetched_details?.dashboard?.dashboardSurveysTableData?.requestUUID === request_uuid.value
            ) {
                if (
                    fetched_details.dashboard.dashboardSurveysTableData.data !== undefined &&
                    fetched_details.dashboard.dashboardSurveysTableData.result === "success"
                ) {
                    SurveysMount.current = true;
                    setDashboardSurveyData((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardSurveyDataFetched: true,
                        };
                    });

                    enableExportPdf(8, true);
                    apiCatchError(8, 0);
                } else if (fetched_details.dashboard.dashboardSurveysTableData.result === 'retry') {
                    getDashboardSurveysData(request_uuid.value, 'retry');
                } else if (
                    fetched_details.dashboard.dashboardSurveysTableData.result === "error"
                ) {
                    setDashboardSurveyData((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardSurveyDataFetched: true,
                        };
                    });
                    enableExportPdf(8, false);
                    apiCatchError(8, 1);
                }
            }
        } catch (error) {
            showBoundary(error)
        }
        if (
            fetched_details.dashboard.dashboardSurveysTableData.data !== undefined &&
            fetched_details.dashboard.dashboardSurveysTableData.result === "success"
        ) {
            if (
                fetched_details.dashboard.dashboardSurveysTableData.data !== undefined &&
                fetched_details.dashboard.dashboardSurveysTableData.result === "success"
            ) {
                SurveysMount.current = true;
                setDashboardSurveyData((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardSurveyDataFetched: true,
                    };
                });

                enableExportPdf(8, true);
                apiCatchError(8, 0);
            } else if (fetched_details.dashboard.dashboardSurveysTableData.result === 'retry') {
                getDashboardSurveysData(request_uuid.value, 'retry');
            } else if (
                fetched_details.dashboard.dashboardSurveysTableData.result === "error"
            ) {
                setDashboardSurveyData((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardSurveyDataFetched: true,
                    };
                });
                enableExportPdf(8, false);
                apiCatchError(8, 1);
            }
        }
    }, [fetched_details.dashboard.dashboardSurveysTableData]);

    // Countries chart data
    useEffect(() => {
        try {
            if (
                fetched_details.dashboard.dashboardCountriesChartData.data !== undefined &&
                fetched_details.dashboard.dashboardCountriesChartData.result === "success" &&
                fetched_details?.dashboard?.dashboardCountriesChartData?.requestUUID === request_uuid.value
            ) {
                CountriesMapMount.current = true;
                let dashboardState = fetched_details.dashboard.dashboardCountriesChartData.data;
                let bucketData = dashboardState != undefined ? dashboardState.locationData : [];
                let worldChart_legends: any[] = [];
                let max = 0;

                for (let i = 0; i < bucketData.length; i++) {
                    if (bucketData[i].count > max) {
                        max = bucketData[i].count;
                    }
                }

                let number = max;

                do {
                    number++;
                }
                while ((number % 5) !== 0);

                let BoundarySet = number / 5;
                let boundaryValues: any[] = [];
                let itrx = 0;

                while (boundaryValues.length < 6) {
                    if (Math.round(BoundarySet * itrx) !== 1) {
                        boundaryValues.push(Math.round(BoundarySet * itrx));
                    }
                    itrx++;
                }

                const colors = ['#ddebff', '#bdd6ff', '#3895ff', '#005bf0', '#0046b8'];
                // const colors = [ChartColors.yellowsandybrown, ChartColors.orangesandybrown, ChartColors.salmon, ChartColors.lightseagreen, ChartColors.darkturquoise];

                // const colors = [ChartColors.yellowsandybrown, ChartColors.orangesandybrown, ChartColors.salmon, ChartColors.lightseagreen, ChartColors.darkturquoise];

                for (let i = 1; i < boundaryValues.length; i++) {
                    worldChart_legends[i - 1] = [];
                    worldChart_legends[i - 1].value = boundaryValues[i];
                    worldChart_legends[i - 1].color = colors[i - 1];
                }

                let countryData: any = []

                for (let itr0 = 0; itr0 < bucketData.length; itr0++) {
                    switch (true) {
                        case (bucketData[itr0].count < worldChart_legends[0].value):
                            countryData.push({ ...bucketData[itr0], color: worldChart_legends[0].color })
                            break;
                        case (bucketData[itr0].count < worldChart_legends[1].value && worldChart_legends[0].value <= bucketData[itr0].count):
                            countryData.push({ ...bucketData[itr0], color: worldChart_legends[1].color })
                            break;
                        case (bucketData[itr0].count < worldChart_legends[2].value && worldChart_legends[1].value <= bucketData[itr0].count):
                            countryData.push({ ...bucketData[itr0], color: worldChart_legends[2].color })
                            break;
                        case (bucketData[itr0].count < worldChart_legends[3].value && worldChart_legends[2].value <= bucketData[itr0].count):
                            countryData.push({ ...bucketData[itr0], color: worldChart_legends[3].color })
                            break;
                        case (bucketData[itr0].count <= worldChart_legends[4].value && worldChart_legends[3].value <= bucketData[itr0].count):
                            countryData.push({ ...bucketData[itr0], color: worldChart_legends[4].color })
                            break;
                        default:
                            break;
                    }
                }

                setDashboardCountriesChartData((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardCountriesChartDataFetched: true,
                        legendData: worldChart_legends,
                        boundaryValues: boundaryValues,
                        colors: colors,
                        countryData: countryData
                    };
                });

                enableExportPdf(9, true);
                apiCatchError(9, 0);
            } else if (fetched_details.dashboard.dashboardCountriesChartData.result === 'retry') {
                getDashboardCountriesChartData(request_uuid.value, 'retry');
            } else if (
                fetched_details.dashboard.dashboardCountriesChartData.result === "error"
            ) {
                setDashboardCountriesChartData((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardCountriesChartDataFetched: true,
                    };
                });
                enableExportPdf(9, false);
                apiCatchError(9, 1);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.dashboard.dashboardCountriesChartData]);

    // Export pdf btn
    useEffect(() => {
        try {
            const exportVisibility = fetched_details?.sectionVisibility?.guideInsights_flag ?
                (loaderBtn.loader0 && loaderBtn.loader1 && loaderBtn.loader2 && loaderBtn.loader3 &&
                    loaderBtn.loader4 && loaderBtn.loader5 &&
                    loaderBtn.loader8 && loaderBtn.loader9 && loaderBtn.loader10 && loaderBtn.loader11 &&
                    loaderBtn.loader12 && loaderBtn.loader13 && loaderBtn.loader14 && loaderBtn.loader15 &&
                    loaderBtn.loader16 && loaderBtn.loader17 && loaderBtn.loader18 && loaderBtn.loader19) : (
                    loaderBtn.loader8 && loaderBtn.loader9 && loaderBtn.loader13
                );

            if (exportVisibility) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            } else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader0, loaderBtn.loader1, loaderBtn.loader2, loaderBtn.loader3, loaderBtn.loader4,
    loaderBtn.loader5, loaderBtn.loader6, loaderBtn.loader7, loaderBtn.loader8, loaderBtn.loader9,
    loaderBtn.loader10, loaderBtn.loader11, loaderBtn.loader12, loaderBtn.loader13, loaderBtn.loader14,
    loaderBtn.loader15, loaderBtn.loader16, loaderBtn.loader17, loaderBtn.loader18, loaderBtn.loader19])

    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);

            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })
        } catch (error) {
            showBoundary(error)
        }
        const result = errorCount.errorCount.every((val) => val === 1);

        setErrorCount((prevState: any) => {
            return {
                ...prevState,
                showMaintenance_flag: result
            }
        })

    }, [errorCount.errorCount])

    //Total Summary Count
    useEffect(() => {
        try {
            if (process.env.REACT_APP_WESTPAC === 'false') {
                if (
                    dashboardGuideMeChart.guideMeChartFetched && dashboardShowMeChart.showMeChartFetched &&
                    dashboardTestMeChart.testMeChartFetched && dashboardDoItForMeChart.doItForMeChartFetched &&
                    dashboardGuideTutorialsChart.guideTutorialsChartFetched &&
                    dashboardTooltipChart.tooltipChartFetched &&
                    dashboardPushnotificationChart.pushnotificationChartFetched &&
                    dashboardBeaconChart.beaconChartFetched &&
                    dashboardSurveyGraph.SurveyChartFetched &&
                    dashboardValidationChart.dashboardValidationChartDataFetched &&
                    dashboardPowerhtmlChart.dashboardPowerhtmlChartDataFetched &&
                    dashboardDucttapeChart.dashboardDucttapeChartDataFetched &&
                    dashboardGuidancevalidationChart.dashboardGuidancevalidationChartDataFetched &&
                    dashboardGuidanceChart.dashboardGuidanceChartDataFetched &&
                    dashboardTotalGuideCount.totalGuideCountFetched
                ) {
                    let countData = calculateGuideCount(fetched_details?.dashboard?.dashboardTotalGuideCount?.data);

                    let tableData = createTableData(calculateCount(fetched_details?.dashboard?.dashboardGuideMeChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardShowMeChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardTestMeChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardDoItForMeChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardGuideTutorialsChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardTooltipChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardPushnotificationChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardBeaconChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardSurveyChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardValidationChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardPowerhtmlChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardDucttapeChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardGuidancevalidationChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardGuidanceChartData),
                        countData, fetched_details?.dashboard?.dashboardGuideMeChartData);

                    let totalSummaryCountTable =
                        <div className="table-fixed-column-outter">
                            <div className="table-fixed-column-inner">
                                <Table id="summary" tableData={tableData} superHeaders={superHeaders} colKeys={colKeys} colHeaders={colHeaders} addCustomClass={true}></Table>
                            </div>
                        </div>


                    setTotalSummaryCount((prevState: any) => {
                        return {
                            ...prevState,
                            totalSummaryCountFetched: true,
                            tableData: tableData,
                            totalSummaryCountTable: totalSummaryCountTable,
                        }
                    });

                }
            } else {
                if (
                    dashboardGuideMeChart.guideMeChartFetched &&
                    dashboardGuideTutorialsChart.guideTutorialsChartFetched &&
                    dashboardTooltipChart.tooltipChartFetched &&
                    dashboardPushnotificationChart.pushnotificationChartFetched &&
                    dashboardBeaconChart.beaconChartFetched &&
                    dashboardSurveyGraph.SurveyChartFetched &&
                    dashboardValidationChart.dashboardValidationChartDataFetched &&
                    dashboardPowerhtmlChart.dashboardPowerhtmlChartDataFetched &&
                    dashboardDucttapeChart.dashboardDucttapeChartDataFetched &&
                    dashboardGuidancevalidationChart.dashboardGuidancevalidationChartDataFetched &&
                    dashboardGuidanceChart.dashboardGuidanceChartDataFetched &&
                    dashboardTotalGuideCount.totalGuideCountFetched
                ) {
                    let countData = calculateGuideCount(fetched_details?.dashboard?.dashboardTotalGuideCount?.data);

                    let tableData = createTableDataWestpac(calculateCount(fetched_details?.dashboard?.dashboardGuideMeChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardGuideTutorialsChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardTooltipChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardPushnotificationChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardBeaconChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardSurveyChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardValidationChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardPowerhtmlChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardDucttapeChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardGuidancevalidationChartData),
                        calculateCount(fetched_details?.dashboard?.dashboardGuidanceChartData),
                        countData, fetched_details?.dashboard?.dashboardGuideMeChartData);

                    let totalSummaryCountTable =
                        <div className="table-fixed-column-outter">
                            <div className="table-fixed-column-inner">
                                <Table id="summary" tableData={tableData} superHeaders={superHeaders} colKeys={colKeys} colHeaders={colHeaders} addCustomClass={true}></Table>
                            </div>
                        </div>


                    setTotalSummaryCount((prevState: any) => {
                        return {
                            ...prevState,
                            totalSummaryCountFetched: true,
                            tableData: tableData,
                            totalSummaryCountTable: totalSummaryCountTable,
                        }
                    });

                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [dashboardGuideMeChart.guideMeChartFetched,
    dashboardShowMeChart.showMeChartFetched,
    dashboardTestMeChart.testMeChartFetched,
    dashboardDoItForMeChart.doItForMeChartFetched,
    dashboardGuideTutorialsChart.guideTutorialsChartFetched,
    dashboardTooltipChart.dashboardTooltipChartDataFetched,
    dashboardPushnotificationChart.dashboardPushnotificationChartDataFetched,
    dashboardBeaconChart.dashboardBeaconChartDataFetched,
    dashboardSurveyGraph.dashboardSurveyChartDataFetched,
    dashboardValidationChart.dashboardValidationChartDataFetched,
    dashboardPowerhtmlChart.dashboardPowerhtmlChartDataFetched,
    dashboardDucttapeChart.dashboardDucttapeChartDataFetched,
    dashboardGuidancevalidationChart.dashboardGuidancevalidationChartDataFetched,
    dashboardGuidanceChart.dashboardGuidanceChartDataFetched,
    dashboardTotalGuideCount.totalGuideCountFetched
    ]);

    useEffect(() => {
        try {
            setDashboardTotalGuideCount((prevState) => {
                return {
                    ...prevState,
                    isLoading: false,
                    totalGuideCountFetched: true
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.dashboard?.dashboardTotalGuideCount]);


    //UserEngagment
    useEffect(() => {
        try {
            if (
                fetched_details?.dashboard?.dashboardUserEngagementData?.data !== undefined &&
                fetched_details?.dashboard?.dashboardUserEngagementData?.result === "success" &&
                fetched_details?.dashboard?.dashboardUserEngagementData?.requestUUID === request_uuid.value
            ) {
                UserEngagementMount.current = true;

                let userEngagementChartData: any = [];
                let optionEntities: any = [];
                let userEngagementChartOptions: any = [];
                userEngagementChartData = fetched_details?.dashboard?.dashboardUserEngagementData?.data?.graphData;
                optionEntities = fetched_details?.dashboard?.dashboardUserEngagementData?.data?.legends;

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    userEngagementChartOptions.push({
                        key: optionEntities[itr0].legendTitle?.trim(),
                        color: optionEntities[itr0].color,
                        area: optionEntities[itr0].area,
                        associatedDataPoint: optionEntities[itr0].associatedDataPoint
                    })
                }

                for (let itx1 = 0; itx1 < optionEntities.length; itx1++) {
                    let cnt = 0;
                    for (let itx2 = 0; itx2 < userEngagementChartData.length; itx2++) {
                        cnt = cnt + userEngagementChartData[itx2]['counts'][optionEntities[itx1].associatedDataPoint]
                    }
                    optionEntities[itx1].count = cnt
                }

                setDashboardUserEngagementChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadUserEngagementChartData: [...userEngagementChartData],
                        loadUserEngagementChartOptions: userEngagementChartOptions,
                        userEngagementChartFetched: true,
                        dashboardUserEngagementChartDataFetched: true,
                    };
                });
                apiCatchError(20, 0);
                enableExportPdf(20, true);
            } else if (fetched_details.dashboard.dashboardUserEngagementData.result === 'retry') {
                getDashboardUserEngagementChartData(request_uuid.value, 'retry');
            } else if (fetched_details.dashboard.dashboardUserEngagementData.result === "error") {
                setDashboardUserEngagementChart((prevState: any) => {
                    return {
                        ...prevState,
                        dashboardUserEngagementChartDataFetched: true,
                    };
                });

                if (fetched_details.dashboard?.dashboardUserEngagementData.errorCode === 503) {
                    apiCatchError(20, 503);
                } else {
                    apiCatchError(20, 1);
                }


                enableExportPdf(20, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.dashboard?.dashboardUserEngagementData]);


    // ***************************************************************************************************************************************

    useEffect(() => {
        try {
            document.title = insightsLbls.cioDashboardTitle;
        } catch (error) {
            showBoundary(error)
        }

    }, [insightsLbls])

    const getFinalEnvAppsList = useCallback((appData: any) => {
        try {
            let appIdList: any[] = [];
            let appNameList: any[] = [];
            let appListString: any = "";
            let lineChartEntities: any[] = [];
            let envCodeString: any = "";
            appData.map((data: any, key: number) => {
                lineChartEntities.push(`app${key + 1}`)
                appIdList.push(data.app_id);
                appNameList.push(data.app_name);
                if (key === 0) {
                    appListString += data.app_id;
                }
                else if (key > 0) {
                    appListString += "&app_id=" + data.app_id;
                }

                if (data.env_code && data.app_code) {
                    envCodeString += "&env_code=" + data.app_code + ":" + data.env_code;
                }
            });
            appListString += envCodeString;

            if (appData.length > 5) {
                lineChartEntities.push(`appx`)
            }

            setAppsList((prevState: any) => {
                return {
                    ...prevState,
                    appList: appIdList,
                    appNames: appNameList,
                    appListString: appListString,
                    appsFetched: true,
                    lineChartEntities: lineChartEntities,
                };
            });
        } catch (error) {
            showBoundary(error)
        }
    }, []);


    const getTotalAppList = useCallback((appData: any) => {
        try {
            appListTotal.current = [...appData]
        } catch (error) {
            showBoundary(error)
        }

    }, [])

    const calculateCount = (data: any) => {
        try {
            const graphdata = data?.data?.graphData;
            let appCountMap = new Map();
            graphdata.forEach((dataEntry: { counts: { [s: string]: unknown; } | ArrayLike<unknown>; }) => {
                for (const [key, value] of Object.entries(dataEntry.counts)) {
                    if (appCountMap.has(key)) {
                        appCountMap.set(key, appCountMap.get(key) + value);
                    } else {
                        appCountMap.set(key, value);
                    }
                }
            });
            return appCountMap;
        } catch (error) {
            showBoundary(error)
        }

    }

    const calculateGuideCount = (data: any) => {
        try {
            let countMap: Map<any, Map<any, any>> = new Map();
            const getDefaultOrVal = ((num: any) => {
                if (num == undefined) {
                    return 0;
                }
                return num;
            })
            data.forEach((elem: any) => {
                let totalGuides = 0;
                let totalTooltips = 0;
                if (elem.guides) {
                    totalGuides = getDefaultOrVal(elem.guides['published_count']) + getDefaultOrVal(elem.guides['unpublished_count']) + getDefaultOrVal(elem.guides['deleted_count'])
                }
                if (elem.tooltips) {
                    totalTooltips = getDefaultOrVal(elem.tooltips['published_count']) + getDefaultOrVal(elem.tooltips['unpublished_count']) + getDefaultOrVal(elem.tooltips['deleted_count'])
                }
                let nestedMap: Map<any, any> = new Map();
                nestedMap.set("guides", totalGuides);
                nestedMap.set("tooltips", totalTooltips);
                countMap.set(elem.app_title, nestedMap);
            });

            return countMap;
        } catch (error) {
            showBoundary(error)
        }

    }

    const createTableData = (guideMe: Map<any, any>,
        showMe: Map<any, any>,
        testMe: Map<any, any>,
        doItForMe: Map<any, any>,
        guideTutorial: Map<any, any>,
        tooltip: Map<any, any>,
        pushnotification: Map<any, any>,
        beacon: Map<any, any>,
        survey: Map<any, any>,
        validation: Map<any, any>,
        powerhtml: Map<any, any>,
        ducttape: Map<any, any>,
        guidancevalidation: Map<any, any>,
        guidance: Map<any, any>,
        countData: Map<any, any>,
        metadataObj: any) => {
        try {
            const getNumber = (val: any) => {
                return (val != null && val !== undefined) ? val : 0;
            }
            const getCountValues = (appName: any, isGuide: boolean) => {
                if (countData.get(appName) != undefined) {
                    if (isGuide) {
                        return countData.get(appName).get("guides");
                    } else {
                        return countData.get(appName).get("tooltips");
                    }
                }
                return 0;
            }
            const metadata = metadataObj?.data?.metadata;
            let appNameMapper = new Map();
            metadata.forEach((appDetail: { [x: string]: any; }) => {
                appNameMapper.set(appDetail['associatedDataPoint'], appDetail['legendTitle']);
            });
            let tableData: any[] = [];
            guideMe.forEach((value, key) => {
                let tempObj: any = {};
                if (appNameMapper.has(key)) {
                    let appName = appNameMapper.get(key);
                    tempObj["appName"] = appName;
                    tempObj["guideMeCnt"] = value;
                    tempObj["showMeCnt"] = getNumber(showMe.get(key));
                    tempObj["testMeCnt"] = getNumber(testMe.get(key));
                    tempObj["doItForMeCnt"] = getNumber(doItForMe.get(key));
                    tempObj["tutorialGuideCnt"] = getNumber(guideTutorial.get(key));
                    tempObj["pushnotificationCnt"] = getNumber(pushnotification.get(key));
                    tempObj["beaconCnt"] = getNumber(beacon.get(key));
                    tempObj["surveyCnt"] = getNumber(survey.get(key));
                    tempObj["validationCnt"] = getNumber(validation.get(key));
                    tempObj["powerhtmlCnt"] = getNumber(powerhtml.get(key));
                    tempObj["ducttapeCnt"] = getNumber(ducttape.get(key));
                    tempObj["guidancevalidationCnt"] = getNumber(guidancevalidation.get(key));
                    tempObj["guidanceCnt"] = getNumber(guidance.get(key));
                    tempObj["guideCnt"] = getCountValues(appName, true);
                    tempObj["tooltipCnt"] = getCountValues(appName, false);
                    tempObj["guidePlays"] = tempObj["guideMeCnt"] + tempObj["showMeCnt"] + tempObj["testMeCnt"] + tempObj["doItForMeCnt"] + tempObj["tutorialGuideCnt"];
                    tempObj["tooltipPlays"] = tempObj["validationCnt"] + tempObj["powerhtmlCnt"] + tempObj["ducttapeCnt"] + tempObj["guidancevalidationCnt"] + tempObj["guidanceCnt"];

                    tableData.push(tempObj);
                }
            });

            return tableData;
        } catch (error) {
            showBoundary(error)
        }

    }

    const createTableDataWestpac = (guideMe: Map<any, any>,
        guideTutorial: Map<any, any>,
        tooltip: Map<any, any>,
        pushnotification: Map<any, any>,
        beacon: Map<any, any>,
        survey: Map<any, any>,
        validation: Map<any, any>,
        powerhtml: Map<any, any>,
        ducttape: Map<any, any>,
        guidancevalidation: Map<any, any>,
        guidance: Map<any, any>,
        countData: Map<any, any>,
        metadataObj: any) => {
        try {
            const getNumber = (val: any) => {
                return (val != null && val !== undefined) ? val : 0;
            }
            const getCountValues = (appName: any, isGuide: boolean) => {
                if (countData.get(appName) != undefined) {
                    if (isGuide) {
                        return countData.get(appName).get("guides");
                    } else {
                        return countData.get(appName).get("tooltips");
                    }
                }
                return 0;
            }
            const metadata = metadataObj?.data?.metadata;
            let appNameMapper = new Map();
            metadata.forEach((appDetail: { [x: string]: any; }) => {
                appNameMapper.set(appDetail['associatedDataPoint'], appDetail['legendTitle']);
            });
            let tableData: any[] = [];
            guideMe.forEach((value, key) => {
                let tempObj: any = {};
                if (appNameMapper.has(key)) {
                    let appName = appNameMapper.get(key);
                    tempObj["appName"] = appName;
                    tempObj["guideMeCnt"] = value;
                    tempObj["tutorialGuideCnt"] = getNumber(guideTutorial.get(key));
                    tempObj["pushnotificationCnt"] = getNumber(pushnotification.get(key));
                    tempObj["beaconCnt"] = getNumber(beacon.get(key));
                    tempObj["surveyCnt"] = getNumber(survey.get(key));
                    tempObj["validationCnt"] = getNumber(validation.get(key));
                    tempObj["powerhtmlCnt"] = getNumber(powerhtml.get(key));
                    tempObj["ducttapeCnt"] = getNumber(ducttape.get(key));
                    tempObj["guidancevalidationCnt"] = getNumber(guidancevalidation.get(key));
                    tempObj["guidanceCnt"] = getNumber(guidance.get(key));
                    tempObj["guideCnt"] = getCountValues(appName, true);
                    tempObj["tooltipCnt"] = getCountValues(appName, false);
                    tempObj["guidePlays"] = tempObj["guideMeCnt"] + 0 + tempObj["tutorialGuideCnt"];
                    tempObj["tooltipPlays"] = tempObj["validationCnt"] + tempObj["powerhtmlCnt"] + tempObj["ducttapeCnt"] + tempObj["guidancevalidationCnt"] + tempObj["guidanceCnt"];

                    tableData.push(tempObj);
                }
            });

            return tableData;
        } catch (error) {
            showBoundary(error)
        }

    }

    useEffect(() => {
        try {
            if (parameter.parameter !== '') {
                let queryParams = {
                    app_id: parameter.appId,
                    app_code: parameter.appCode,
                    app_title: parameter.appTitle,
                    fdate: fetched_details?.commonComponentData?.datesData.fdate,
                    tdate: fetched_details?.commonComponentData?.datesData.tdate,
                    redirect: 1,
                }

                navigate({
                    pathname: parameter.option + parameter.parameter + '/',
                    search: qs.stringify(queryParams),
                });
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [parameter])

    const setSectionRefresh = useCallback((flag: boolean) => {
        try {
            setUserEngagementLazyLoadChart((prevState: any) => {
                return {
                    ...prevState,
                    lazyLoad_flag: flag
                }
            })

            if (flag) {
                enableExportPdf(0, true);
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [setUserEngagementLazyLoadChart, enableExportPdf])


    const getTotalCount = (cnt) => {
        setBubbleChartData((prevState: any) => {
            return {
                ...prevState,
                count: cnt
            }
        })
    }




    const getAppsList = useMemo(() => {
        return appsList.appsFetched ? appsList.appNames.map((optn) => (
            <span key={optn} className='infoChip marginLeft-5'>{optn}</span>
        )) : null;
    }, [appsList.appsFetched, appsList.appNames]);


    const setDefaultDashboard = (from?: string, apiErrorFlag?: string) => {
        let d_dummy = [];
        for (let itr0 = 0; itr0 < fetched_dashboard_details.data.length; itr0++) {
            const dashboardKey = 'dashboard' + (itr0 + 1);
            const currentDashboard = fetched_dashboard_details.data[itr0][dashboardKey];

            d_dummy.push({
                profileID: currentDashboard['profileID'],
                profileName: currentDashboard['name'],
                isDefault: false,
                dashboardNumber: itr0 + 1,
                dashboardIcon: currentDashboard['dashboardIcon'],
                isDashboard: false,
                profileDetails: currentDashboard['profileDetails']
            });
        }

        let path = ApiRelativePaths.CREATE_DASHBOARD;
        let params = {};
        let paramObj = {};
        let a = CommonUtils.URLGenerator(params, path, paramObj);

        postCall(d_dummy, "CREATE_DASHBOARD").then((data: any) => {
            if (data.result === "success") {
                let dData = []
                for (let itr0 = 0; itr0 < data.data.length; itr0++) {
                    if (data.data.length < 3) {
                        dData.push({
                            ['dashboard' + (itr0 + 1)]: {
                                active: true,
                                name: data.data[itr0].profileName,
                                profileID: data.data[itr0].profileID,
                                isDefault: false,
                                dashboardIcon: data.data[itr0].dashboardIcon,
                                profileDetails: data.data[itr0].profileDetails
                            }
                        })
                    }
                }
                dispatch(setDashboards(dData))

                setDashboard_state((prevState: any) => {
                    return {
                        ...prevState,
                        isDefault: true
                    }
                })

                toast.success(insightsLbls.successfullyUpdated, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    const getDashboardStatus = () => {
        let cnt = 0
        for (let itr0 = 0; itr0 < fetched_dashboard_details.data.length; itr0++) {
            const dashboardKey = 'dashboard' + (itr0 + 1);
            const currentDashboard = fetched_dashboard_details.data[itr0][dashboardKey];

           if(currentDashboard['isDefault']){
            cnt = cnt + 1
           }
        }

        setDashboard_state((prevState: any) => {
            return {
                ...prevState,
                isDefault: cnt === 0
            }
        })

    }
    // ***************************************************************************************************************************************
    return (
        <section className=" demo width100 dashboardSection" ref={componentRef}>
            <div className="row">
                {/* <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li className="active">{insightsLbls.cioDashboardTitle}</li>
                    </SectionHeader>
                </div> */}

                <SectionFilters>
                    <AppEnvListDropdown finalApps={getFinalEnvAppsList}></AppEnvListDropdown>
                    {/* {fetched_details?.sectionVisibility?.guideInsights_flag && <EventSource component={"dashboard"}></EventSource>}
                    {fetched_details?.sectionVisibility?.guideInsights_flag && <StateFilter component={"dashboard"} />} */}
                    <Datepicker source={'component'} />
                </SectionFilters>
            </div>


            {/* <SectionButtons sectionTitle={(insightsLbls.cioDashboardTitle !== undefined) ? insightsLbls.cioDashboardTitle : ('')}
                svgImage={"dashboard"} className={'marginTop-50'}>
                <button
                    title={insightsLbls.cioDashboardModalTitle}
                    className={"btn-primary displayFlex tooltipFilter marginRight-20"}
                    data-toggle="modal"
                    onClick={(event) => openExportModal(event)}
                    disabled={loaderBtn.disabled}
                >
                    <img
                        src={
                            require("../../assets/images/export-excel.png")
                        }
                        width="22"
                        height="auto"
                        alt=""
                        className="excel-icon"
                    />
                    {insightsLbls.cioDashboardModalTitle}
                </button>
                <ExportToPdf
                    componentRef={componentRef}
                    source={"Dashboard"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons> */}

            <div className="marginTop-50 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={"Dashboard"}
                                widthDividedBy={12}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="2"
                            title={insightsLbls.cioDashboardModalTitle}
                            data-toggle="modal"
                            onClick={(event) => openExportModal(event)}
                            disabled={loaderBtn.disabled}>
                            {insightsLbls.cioDashboardModalTitle}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>



            <div className="page-title-new col-4 col-sm-4 col-4 section-subheading-div">
                <h5>
                    {(insightsLbls.cioDashboardSubTitle !== undefined) ? insightsLbls.cioDashboardSubTitle : ('')}
                </h5>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                messageText={"Your Insights Update"}
                showUserInfo={true}>
                {getAppsList}
            </UserInfoSection>

            <button className="btn btn-primary dashDefault-btn" disabled={dashboard_state.isDefault} onClick={() => setDefaultDashboard()}>Make Default</button>
            
            <div className="infoMessage">{insightsLbls.dashboard24HrMsg}</div>
            <div className="dashboard">

                {/* <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant"> */}

                {fetched_details.sectionVisibility.guideInsights_flag && (
                    <>
                        {/* <SectionRefresh sectionTitle={insightsLbls.activity} refreshClass={""}>
                            <i className="fa fa-refresh pull-right pointer row" aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(0)}></i>
                        </SectionRefresh> */}


                        {
                            !expanded ?
                                <>
                                    <CardContainer
                                        div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                        div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                        div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                        <div className="ActivityExpand">
                                            <ContainerTooltip div1Classes={"marginBottom-20"} div3Classes={""} hTagClasses={"section-heading headingText"}>
                                                <SectionTooltip tooltipText={{
                                                    "subTitle": insightsLbls.activity,
                                                    "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                    "childern": [{
                                                        "key": insightsLbls.guideCounts,
                                                        "value": insightsLbls.guidesSelectedinTimeFrame
                                                    }
                                                    ]
                                                }} placement="right"></SectionTooltip>

                                                <button className="pull-right pointer refreshBtn" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button>

                                            </ContainerTooltip>
                                            {/* <div className="downArrowDashboard">
                                                <OverlayTrigger placement={"bottom"} overlay={tooltipText(expanded)}>
                                                    <span className="expand" onClick={handleExpansion}>{insightsLbls.expand} <i id={`detail-arrow`} className={'fa fa-angle' + (!expanded ? '-down ' : '-up ') + 'pointer ' + 'arrowWeightDashBoard'} aria-hidden="true"></i>
                                                    </span>
                                                </OverlayTrigger>
                                            </div> */}
                                        </div>




                                        <div className="minHeight400">
                                            <Tabs
                                                activeKey={tabKey.key}
                                                onSelect={handleSwitch}
                                                id="controlled-tab-guides"
                                            >


                                                <Tab eventKey={1} title={insightsLbls.guideMe} tabClassName="tab">

                                                    {dashboardGuideMeChart.dashboardGuideMeChartDataFetched ? (
                                                        errorCount.errorCount[0] === 0 ? (
                                                            dashboardGuideMeChart?.loadGuideMeChartData?.length === 0 ? (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="col-sm-12 col-lg-12 col-md-12 chartInfo-div displayFlex">
                                                                        {/* <div>
                                                                            <p className="infoKey">Total Count</p>
                                                                            <p className="infoValue">{bubbleChartData.count}</p>
                                                                        </div> */}
                                                                        {/* <hr className="infoDivider"></hr> */}
                                                                        <div>
                                                                            <p className="infoKey">Selected Apps</p>
                                                                            <p className="infoValue">{dashboardGuideMeChart.selectedAppCount}</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="displayFlex justifyCenter marginLR-10">
                                                                        <div className="col-6 marginTop-20 outer-border marginRight-10 height530">
                                                                            <LineChart
                                                                                chartEntities={appsList.lineChartEntities}
                                                                                optionEntities={dashboardGuideMeChart.loadGuideMeChartOptions}
                                                                                chartData={fetched_details?.dashboard?.dashboardGuideMeChartData?.data?.graphData}
                                                                                value_type={"count"}
                                                                                height={linechart_height}
                                                                                margin={lineChartMargin}
                                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                refId="dashboard_guideme"
                                                                                component='dashboard'>
                                                                            </LineChart>
                                                                        </div>
                                                                        <div className="col-6 paddingLeft-20 marginTop-20 donutDiv outer-border marginLeft-10 height530">

                                                                            <Piechart
                                                                                chartData={[
                                                                                    dashboardGuideMeChart.chartData_values
                                                                                ]}
                                                                                chartEntities={dashboardGuideMeChart.chartData_entites}
                                                                                optionEntities={dashboardGuideMeChart.optionData_entites}
                                                                                valueFormat={'value'}
                                                                                height={450}
                                                                                width={450}
                                                                                showLegends={true}
                                                                                chartType={'donut'}
                                                                                labelType={'percent'}
                                                                            />
                                                                            {/* <Bubblechart dispatchTotalCount={getTotalCount} data={dashboardGuideMeChart.bubbleChartData} selectedAppCount={dashboardGuideMeChart.selectedAppCount}></Bubblechart> */}
                                                                        </div>
                                                                    </div>
                                                                </>

                                                            )
                                                        ) : (
                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                {insightsLbls.serviceNotAvailable}
                                                            </h5>
                                                        )
                                                    ) : (
                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <Loader></Loader>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Tab>

                                                {process.env.REACT_APP_WESTPAC === 'false' && (
                                                    <Tab eventKey={2} title={insightsLbls.showMe} tabClassName="tab">
                                                        {dashboardShowMeChart.dashboardShowMeChartDataFetched ? (
                                                            errorCount.errorCount[1] === 0 ? (
                                                                dashboardShowMeChart?.loadShowMeChartData?.length === 0 ? (
                                                                    <div className="noDataText-font textCenter-absolute">
                                                                        {insightsLbls.noRecordFound}
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div className="col-sm-12 col-lg-12 col-md-12 chartInfo-div displayFlex">
                                                                            {/* <div>
                                                                                <p className="infoKey">Total Count</p>
                                                                                <p className="infoValue">{bubbleChartData.count}</p>
                                                                            </div>
                                                                            <hr className="infoDivider"></hr> */}
                                                                            <div>
                                                                                <p className="infoKey">Selected Apps</p>
                                                                                <p className="infoValue">{dashboardGuideMeChart.selectedAppCount}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="displayFlex justifyCenter marginLR-10">
                                                                            <div className="col-6 marginTop-20 outer-border marginRight-10 height530">
                                                                                <LineChart
                                                                                    chartEntities={appsList.lineChartEntities}
                                                                                    optionEntities={dashboardShowMeChart.loadShowMeChartOptions}
                                                                                    chartData={dashboardShowMeChart.loadShowMeChartData}
                                                                                    value_type={"count"}
                                                                                    height={linechart_height}
                                                                                    margin={lineChartMargin}
                                                                                    yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                    refId="dashboard_showme"
                                                                                    component='dashboard'>
                                                                                </LineChart>
                                                                            </div>
                                                                            <div className="col-6 paddingLeft-20 marginTop-20 donutDiv outer-border marginLeft-10 height53">
                                                                                <Piechart
                                                                                    chartData={[
                                                                                        dashboardShowMeChart.chartData_values
                                                                                    ]}
                                                                                    chartEntities={dashboardShowMeChart.chartData_entites}
                                                                                    optionEntities={dashboardShowMeChart.optionData_entites}
                                                                                    valueFormat={'value'}
                                                                                    height={450}
                                                                                    width={450}
                                                                                    showLegends={true}
                                                                                    chartType={'donut'}
                                                                                    labelType={'percent'}
                                                                                />
                                                                                {/* <Bubblechart dispatchTotalCount={getTotalCount} data={dashboardShowMeChart.bubbleChartData} selectedAppCount={dashboardShowMeChart.selectedAppCount}></Bubblechart> */}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            ) : (
                                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                    {insightsLbls.serviceNotAvailable}
                                                                </h5>
                                                            )
                                                        ) : (
                                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                    <Loader></Loader>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </Tab>
                                                )}


                                                {process.env.REACT_APP_WESTPAC === 'false' && (
                                                    <Tab eventKey={3} title={insightsLbls.testMe} tabClassName="tab">
                                                        {dashboardTestMeChart.dashboardTestMeChartDataFetched ? (
                                                            errorCount.errorCount[2] === 0 ? (
                                                                dashboardTestMeChart?.loadTestMeChartData?.length === 0 ? (
                                                                    <div className="noDataText-font textCenter-absolute">
                                                                        {insightsLbls.noRecordFound}
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div className="col-sm-12 col-lg-12 col-md-12 chartInfo-div displayFlex">
                                                                            {/* <div>
                                                                                <p className="infoKey">Total Count</p>
                                                                                <p className="infoValue">{bubbleChartData.count}</p>
                                                                            </div>
                                                                            <hr className="infoDivider"></hr> */}
                                                                            <div>
                                                                                <p className="infoKey">Selected Apps</p>
                                                                                <p className="infoValue">{dashboardGuideMeChart.selectedAppCount}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="displayFlex justifyCenter marginLR-10">
                                                                            <div className="col-6 marginTop-20 outer-border marginRight-10 height530">
                                                                                <LineChart
                                                                                    chartEntities={appsList.lineChartEntities}
                                                                                    optionEntities={dashboardTestMeChart.loadTestMeChartOptions}
                                                                                    chartData={dashboardTestMeChart.loadTestMeChartData}
                                                                                    value_type={"count"}
                                                                                    height={linechart_height}
                                                                                    margin={lineChartMargin}
                                                                                    yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                    refId="dashboard_testme"
                                                                                    component='dashboard'>
                                                                                </LineChart>
                                                                            </div>
                                                                            <div className="col-6 paddingLeft-20 marginTop-20 donutDiv outer-border marginLeft-10 height530">
                                                                                <Piechart
                                                                                    chartData={[
                                                                                        dashboardTestMeChart.chartData_values
                                                                                    ]}
                                                                                    chartEntities={dashboardTestMeChart.chartData_entites}
                                                                                    optionEntities={dashboardTestMeChart.optionData_entites}
                                                                                    valueFormat={'value'}
                                                                                    height={450}
                                                                                    width={450}
                                                                                    showLegends={true}
                                                                                    chartType={'donut'}
                                                                                    labelType={'percent'}
                                                                                />
                                                                                {/* <Bubblechart dispatchTotalCount={getTotalCount} data={dashboardTestMeChart.bubbleChartData} selectedAppCount={dashboardTestMeChart.selectedAppCount}></Bubblechart> */}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            ) : (
                                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                    {insightsLbls.serviceNotAvailable}
                                                                </h5>
                                                            )
                                                        ) : (
                                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                    <Loader></Loader>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Tab>
                                                )}


                                                {process.env.REACT_APP_WESTPAC === 'false' && (
                                                    <Tab eventKey={4} title={insightsLbls.doItForMe} tabClassName="tab">
                                                        {dashboardDoItForMeChart.dashboardDoItForMeChartDataFetched ? (
                                                            errorCount.errorCount[3] === 0 ? (
                                                                dashboardDoItForMeChart?.loadDoItForMeChartData?.length === 0 ? (
                                                                    <div className="noDataText-font textCenter-absolute">
                                                                        {insightsLbls.noRecordFound}
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div className="col-sm-12 col-lg-12 col-md-12 chartInfo-div displayFlex">
                                                                            {/* <div>
                                                                                <p className="infoKey">Total Count</p>
                                                                                <p className="infoValue">{bubbleChartData.count}</p>
                                                                            </div>
                                                                            <hr className="infoDivider"></hr> */}
                                                                            <div>
                                                                                <p className="infoKey">Selected Apps</p>
                                                                                <p className="infoValue">{dashboardGuideMeChart.selectedAppCount}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="displayFlex justifyCenter marginLR-10">
                                                                            <div className="col-6 marginTop-20 outer-border marginRight-10 height530">
                                                                                <LineChart
                                                                                    chartEntities={appsList.lineChartEntities}
                                                                                    optionEntities={dashboardDoItForMeChart.loadDoItForMeChartOptions}
                                                                                    chartData={dashboardDoItForMeChart.loadDoItForMeChartData}
                                                                                    value_type={"count"}
                                                                                    height={linechart_height}
                                                                                    margin={lineChartMargin}
                                                                                    yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                    refId="dashboard_doitforme"
                                                                                    component='dashboard'>
                                                                                </LineChart>
                                                                            </div>
                                                                            <div className="col-6 paddingLeft-20 marginTop-20 donutDiv outer-border marginLeft-10 height530">
                                                                                <Piechart
                                                                                    chartData={[
                                                                                        dashboardDoItForMeChart.chartData_values
                                                                                    ]}
                                                                                    chartEntities={dashboardDoItForMeChart.chartData_entites}
                                                                                    optionEntities={dashboardDoItForMeChart.optionData_entites}
                                                                                    valueFormat={'value'}
                                                                                    height={450}
                                                                                    width={450}
                                                                                    showLegends={true}
                                                                                    chartType={'donut'}
                                                                                    labelType={'percent'}
                                                                                />
                                                                                {/* <Bubblechart dispatchTotalCount={getTotalCount} data={dashboardDoItForMeChart.bubbleChartData} selectedAppCount={dashboardDoItForMeChart.selectedAppCount}></Bubblechart> */}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            ) : (
                                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                    {insightsLbls.serviceNotAvailable}
                                                                </h5>
                                                            )
                                                        ) : (
                                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                    <Loader></Loader>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Tab>
                                                )}


                                                <Tab eventKey={5} title={insightsLbls.tutorialGuide} tabClassName="tab">
                                                    {dashboardGuideTutorialsChart.dashboardGuideTutorialsChartDataFetched ? (
                                                        errorCount.errorCount[4] === 0 ? (
                                                            dashboardGuideTutorialsChart?.loadGuideTutorialsChartData?.length === 0 ? (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="col-sm-12 col-lg-12 col-md-12 chartInfo-div displayFlex">
                                                                        {/* <div>
                                                                            <p className="infoKey">Total Count</p>
                                                                            <p className="infoValue">{bubbleChartData.count}</p>
                                                                        </div>
                                                                        <hr className="infoDivider"></hr> */}
                                                                        <div>
                                                                            <p className="infoKey">Selected Apps</p>
                                                                            <p className="infoValue">{dashboardGuideMeChart.selectedAppCount}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="displayFlex justifyCenter marginLR-10">
                                                                        <div className="col-6 marginTop-20 outer-border marginRight-10 height530">
                                                                            <LineChart
                                                                                chartEntities={appsList.lineChartEntities}
                                                                                optionEntities={dashboardGuideTutorialsChart.loadGuideTutorialsChartOptions}
                                                                                chartData={dashboardGuideTutorialsChart.loadGuideTutorialsChartData}
                                                                                value_type={"count"}
                                                                                height={linechart_height}
                                                                                margin={lineChartMargin}
                                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                refId="dashboard_guideTutorials"
                                                                                component='dashboard'>
                                                                            </LineChart>
                                                                        </div>
                                                                        <div className="col-6 paddingLeft-20 marginTop-20 donutDiv outer-border marginLeft-10 height530">
                                                                            <Piechart
                                                                                chartData={[
                                                                                    dashboardGuideTutorialsChart.chartData_values
                                                                                ]}
                                                                                chartEntities={dashboardGuideTutorialsChart.chartData_entites}
                                                                                optionEntities={dashboardGuideTutorialsChart.optionData_entites}
                                                                                valueFormat={'value'}
                                                                                height={450}
                                                                                width={450}
                                                                                showLegends={true}
                                                                                chartType={'donut'}
                                                                                labelType={'percent'}
                                                                            />
                                                                            {/* <Bubblechart dispatchTotalCount={getTotalCount} data={dashboardGuideTutorialsChart.bubbleChartData} selectedAppCount={dashboardGuideTutorialsChart.selectedAppCount}></Bubblechart> */}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        ) : (
                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                {insightsLbls.serviceNotAvailable}
                                                            </h5>
                                                        )
                                                    ) : (
                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <Loader></Loader>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Tab>

                                                <Tab eventKey={6} title={insightsLbls.tooltip} tabClassName="tab">
                                                    {dashboardTooltipChart.dashboardTooltipChartDataFetched ? (
                                                        errorCount.errorCount[10] === 0 ? (
                                                            dashboardTooltipChart?.loadTooltipChartData?.length === 0 ? (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            ) : (

                                                                <>
                                                                    <div className="col-sm-12 col-lg-12 col-md-12 chartInfo-div displayFlex">
                                                                        {/* <div>
                                                                            <p className="infoKey">Total Count</p>
                                                                            <p className="infoValue">{bubbleChartData.count}</p>
                                                                        </div>
                                                                        <hr className="infoDivider"></hr> */}
                                                                        <div>
                                                                            <p className="infoKey">Selected Apps</p>
                                                                            <p className="infoValue">{dashboardGuideMeChart.selectedAppCount}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="displayFlex justifyCenter marginLR-10">
                                                                        <div className="col-6 marginTop-20 outer-border marginRight-10 height530">
                                                                            <LineChart
                                                                                chartEntities={appsList.lineChartEntities}
                                                                                optionEntities={dashboardTooltipChart.loadTooltipChartOptions}
                                                                                chartData={dashboardTooltipChart.loadTooltipChartData}
                                                                                value_type={"count"}
                                                                                height={linechart_height}
                                                                                margin={lineChartMargin}
                                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                refId="dashboard_tooltip"
                                                                                component='dashboard'>
                                                                            </LineChart>
                                                                        </div>
                                                                        <div className="col-6 paddingLeft-20 marginTop-20 donutDiv outer-border marginLeft-10 height530">
                                                                            <Piechart
                                                                                chartData={[
                                                                                    dashboardTooltipChart.chartData_values
                                                                                ]}
                                                                                chartEntities={dashboardTooltipChart.chartData_entites}
                                                                                optionEntities={dashboardTooltipChart.optionData_entites}
                                                                                valueFormat={'value'}
                                                                                height={450}
                                                                                width={450}
                                                                                showLegends={true}
                                                                                chartType={'donut'}
                                                                                labelType={'percent'}
                                                                            />
                                                                            {/* <Bubblechart dispatchTotalCount={getTotalCount} data={dashboardTooltipChart.bubbleChartData} selectedAppCount={dashboardTooltipChart.selectedAppCount}></Bubblechart> */}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        ) : (
                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                {insightsLbls.serviceNotAvailable}
                                                            </h5>
                                                        )
                                                    ) : (
                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <Loader></Loader>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Tab>

                                                <Tab eventKey={7} title={insightsLbls.pushNotification} tabClassName="tab">
                                                    {dashboardPushnotificationChart.dashboardPushnotificationChartDataFetched ? (
                                                        errorCount.errorCount[11] === 0 ? (
                                                            dashboardPushnotificationChart?.loadPushnotificationChartData?.length === 0 ? (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            ) : (


                                                                <>
                                                                    <div className="col-sm-12 col-lg-12 col-md-12 chartInfo-div displayFlex">
                                                                        {/* <div>
                                                                            <p className="infoKey">Total Count</p>
                                                                            <p className="infoValue">{bubbleChartData.count}</p>
                                                                        </div>
                                                                        <hr className="infoDivider"></hr> */}
                                                                        <div>
                                                                            <p className="infoKey">Selected Apps</p>
                                                                            <p className="infoValue">{dashboardGuideMeChart.selectedAppCount}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="displayFlex justifyCenter marginLR-10">
                                                                        <div className="col-6 marginTop-20 outer-border marginRight-10 height530">
                                                                            <LineChart
                                                                                chartEntities={appsList.lineChartEntities}
                                                                                optionEntities={dashboardPushnotificationChart.loadPushnotificationChartOptions}
                                                                                chartData={dashboardPushnotificationChart.loadPushnotificationChartData}
                                                                                value_type={"count"}
                                                                                height={linechart_height}
                                                                                margin={lineChartMargin}
                                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                refId="dashboard_pushnotification"
                                                                                component='dashboard'>
                                                                            </LineChart>
                                                                        </div>
                                                                        <div className="col-6 paddingLeft-20 marginTop-20 donutDiv outer-border marginLeft-10 height530">
                                                                            <Piechart
                                                                                chartData={[
                                                                                    dashboardPushnotificationChart.chartData_values
                                                                                ]}
                                                                                chartEntities={dashboardPushnotificationChart.chartData_entites}
                                                                                optionEntities={dashboardPushnotificationChart.optionData_entites}
                                                                                valueFormat={'value'}
                                                                                height={450}
                                                                                width={450}
                                                                                showLegends={true}
                                                                                chartType={'donut'}
                                                                                labelType={'percent'}
                                                                            />
                                                                            {/* <Bubblechart dispatchTotalCount={getTotalCount} data={dashboardPushnotificationChart.bubbleChartData} selectedAppCount={dashboardPushnotificationChart.selectedAppCount}></Bubblechart> */}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        ) : (
                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                {insightsLbls.serviceNotAvailable}
                                                            </h5>
                                                        )
                                                    ) : (
                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <Loader></Loader>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Tab>

                                                <Tab eventKey={8} title={insightsLbls.beacon} tabClassName="tab">
                                                    {dashboardBeaconChart.dashboardBeaconChartDataFetched ? (
                                                        errorCount.errorCount[12] === 0 ? (
                                                            dashboardBeaconChart?.loadBeaconChartData?.length === 0 ? (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            ) : (


                                                                <>
                                                                    <div className="col-sm-12 col-lg-12 col-md-12 chartInfo-div displayFlex">
                                                                        {/* <div>
                                                                            <p className="infoKey">Total Count</p>
                                                                            <p className="infoValue">{bubbleChartData.count}</p>
                                                                        </div>
                                                                        <hr className="infoDivider"></hr> */}
                                                                        <div>
                                                                            <p className="infoKey">Selected Apps</p>
                                                                            <p className="infoValue">{dashboardGuideMeChart.selectedAppCount}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="displayFlex justifyCenter marginLR-10">
                                                                        <div className="col-6 marginTop-20 outer-border marginRight-10 height530">
                                                                            <LineChart
                                                                                chartEntities={appsList.lineChartEntities}
                                                                                optionEntities={dashboardBeaconChart.loadBeaconChartOptions}
                                                                                chartData={dashboardBeaconChart.loadBeaconChartData}
                                                                                value_type={"count"}
                                                                                height={linechart_height}
                                                                                margin={lineChartMargin}
                                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                refId="dashboard_beacon"
                                                                                component='dashboard'>
                                                                            </LineChart>
                                                                        </div>
                                                                        <div className="col-6 paddingLeft-20 marginTop-20 donutDiv outer-border marginLeft-10 height530">
                                                                            <Piechart
                                                                                chartData={[
                                                                                    dashboardBeaconChart.chartData_values
                                                                                ]}
                                                                                chartEntities={dashboardBeaconChart.chartData_entites}
                                                                                optionEntities={dashboardBeaconChart.optionData_entites}
                                                                                valueFormat={'value'}
                                                                                height={450}
                                                                                width={450}
                                                                                showLegends={true}
                                                                                chartType={'donut'}
                                                                                labelType={'percent'}
                                                                            />
                                                                            {/* <Bubblechart dispatchTotalCount={getTotalCount} data={dashboardBeaconChart.bubbleChartData} selectedAppCount={dashboardBeaconChart.selectedAppCount}></Bubblechart> */}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        ) : (
                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                {insightsLbls.serviceNotAvailable}
                                                            </h5>
                                                        )
                                                    ) : (
                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <Loader></Loader>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Tab>

                                                <Tab eventKey={9} title={insightsLbls.survey} tabClassName="tab">
                                                    {dashboardSurveyGraph.dashboardSurveyChartDataFetched ? (
                                                        errorCount.errorCount[13] === 0 ? (
                                                            dashboardSurveyGraph?.loadSurveyChartData?.length === 0 ? (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            ) : (


                                                                <>
                                                                    <div className="col-sm-12 col-lg-12 col-md-12 chartInfo-div displayFlex">
                                                                        {/* <div>
                                                                            <p className="infoKey">Total Count</p>
                                                                            <p className="infoValue">{bubbleChartData.count}</p>
                                                                        </div>
                                                                        <hr className="infoDivider"></hr> */}
                                                                        <div>
                                                                            <p className="infoKey">Selected Apps</p>
                                                                            <p className="infoValue">{dashboardGuideMeChart.selectedAppCount}</p>
                                                                        </div>
                                                                    </div>
                                                                    {/* <p className="marginBottom-0 marginTop-10"><span className="bg-yellow">
                                                                        {"*" + insightsLbls.chartFilteredBasedOnApps}</span></p> */}
                                                                    <div className="displayFlex justifyCenter marginLR-10">

                                                                        <div className="col-6 marginTop-20 outer-border marginRight-10 height530">
                                                                            <LineChart
                                                                                chartEntities={appsList.lineChartEntities}
                                                                                optionEntities={dashboardSurveyGraph.loadSurveyChartOptions}
                                                                                chartData={dashboardSurveyGraph.loadSurveyChartData}
                                                                                value_type={"count"}
                                                                                height={linechart_height}
                                                                                margin={lineChartMargin}
                                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                refId="dashboard_beacon"
                                                                                component='dashboard'>
                                                                            </LineChart>
                                                                        </div>
                                                                        <div className="col-6 paddingLeft-20 marginTop-20 donutDiv outer-border marginLeft-10 height530">
                                                                            <Piechart
                                                                                chartData={[
                                                                                    dashboardSurveyGraph.chartData_values
                                                                                ]}
                                                                                chartEntities={dashboardSurveyGraph.chartData_entites}
                                                                                optionEntities={dashboardSurveyGraph.optionData_entites}
                                                                                valueFormat={'value'}
                                                                                height={450}
                                                                                width={450}
                                                                                showLegends={true}
                                                                                chartType={'donut'}
                                                                                labelType={'percent'}
                                                                            />
                                                                            {/* <Bubblechart dispatchTotalCount={getTotalCount} data={dashboardSurveyGraph.bubbleChartData} selectedAppCount={dashboardSurveyGraph.selectedAppCount}></Bubblechart> */}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        ) : (
                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                {insightsLbls.serviceNotAvailable}
                                                            </h5>
                                                        )
                                                    ) : (
                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <Loader></Loader>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </CardContainer>
                                </>
                                :
                                <>
                                    <CardContainer
                                        div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                        div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                        div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                        <div className="ActivityExpand">
                                            <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                <SectionTooltip tooltipText={{
                                                    "subTitle": insightsLbls.guideMeActivity,
                                                    "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                    "childern": [{
                                                        "key": insightsLbls.guideCounts,
                                                        "value": insightsLbls.guidesInGuideModeinTimeFrame
                                                    }]
                                                }} placement="right">
                                                </SectionTooltip>
                                            </ContainerTooltip>
                                            <div className="downArrowDashboard">
                                                <OverlayTrigger placement={"bottom"} overlay={tooltipText(expanded)}>
                                                    <span className="expand" onClick={handleExpansion}>{insightsLbls.collapse}<i id={`detail-arrow`} className={'fa fa-angle' + (!expanded ? '-down ' : '-up ') + 'pointer ' + 'arrowWeightDashBoard'} aria-hidden="true"></i>
                                                    </span>
                                                </OverlayTrigger>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                                            {(dashboardGuideMeChart.dashboardGuideMeChartDataFetched) ? (
                                                errorCount.errorCount[0] === 0 ? (
                                                    dashboardGuideMeChart?.loadGuideMeChartData?.length === 0 ? (
                                                        <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (

                                                        <>
                                                            <div className="displayFlex">
                                                                <div className="col-8">
                                                                    <LineChart
                                                                        chartEntities={appsList.lineChartEntities}
                                                                        optionEntities={dashboardGuideMeChart.loadGuideMeChartOptions}
                                                                        chartData={fetched_details?.dashboard?.dashboardGuideMeChartData?.data?.graphData}
                                                                        value_type={"count"}
                                                                        height={linechart_height}
                                                                        margin={lineChartMargin}
                                                                        yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                        refId="dashboard_guideme"
                                                                        component='dashboard'>
                                                                    </LineChart>
                                                                </div>
                                                                <div className="col-4 paddingLeft-20 buubleDiv">
                                                                    <Bubblechart data={dashboardGuideMeChart.bubbleChartData} selectedAppCount={dashboardGuideMeChart.selectedAppCount}></Bubblechart>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                ) : (
                                                    <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                {insightsLbls.serviceNotAvailable}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                )
                                            ) : (
                                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                        <Loader></Loader>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                    </CardContainer>


                                    {process.env.REACT_APP_WESTPAC === 'false' && (
                                        <>
                                            <CardContainer
                                                div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                                div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                                div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                    <SectionTooltip tooltipText={{
                                                        "subTitle": insightsLbls.showMeActivity,
                                                        "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                        "childern": [
                                                            {
                                                                "key": insightsLbls.guideCounts,
                                                                "value": insightsLbls.guidesInShowMeModeinTimeFrame
                                                            }
                                                        ]
                                                    }} placement="right">
                                                    </SectionTooltip>
                                                </ContainerTooltip>

                                                <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                                                    {dashboardShowMeChart.dashboardShowMeChartDataFetched ? (
                                                        errorCount.errorCount[1] === 0 ? (
                                                            dashboardShowMeChart?.loadShowMeChartData?.length === 0 ? (
                                                                <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                                        <div className="noDataText-font textCenter-absolute">
                                                                            {insightsLbls.noRecordFound}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="displayFlex">
                                                                        <div className="col-8">
                                                                            <LineChart
                                                                                chartEntities={appsList.lineChartEntities}
                                                                                optionEntities={dashboardShowMeChart.loadShowMeChartOptions}
                                                                                chartData={dashboardShowMeChart.loadShowMeChartData}
                                                                                value_type={"count"}
                                                                                height={linechart_height}
                                                                                margin={lineChartMargin}
                                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                refId="dashboard_showme"
                                                                                component='dashboard'>
                                                                            </LineChart>
                                                                        </div>
                                                                        <div className="col-4 paddingLeft-20 buubleDiv">
                                                                            <Bubblechart data={dashboardShowMeChart.bubbleChartData} selectedAppCount={dashboardShowMeChart.selectedAppCount}></Bubblechart>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        ) : (
                                                            <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                        {insightsLbls.serviceNotAvailable}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <Loader></Loader>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                            </CardContainer>

                                            <CardContainer
                                                div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                                div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                                div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                    <SectionTooltip tooltipText={{
                                                        "subTitle": insightsLbls.testMeActivity,
                                                        "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                        "childern": [
                                                            {
                                                                "key": insightsLbls.guideCounts,
                                                                "value": insightsLbls.guidesInTestMeModeinTimeFrame
                                                            }
                                                        ]
                                                    }} placement="right">
                                                    </SectionTooltip>
                                                </ContainerTooltip>
                                                <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                                    {dashboardTestMeChart.dashboardTestMeChartDataFetched ? (
                                                        errorCount.errorCount[2] === 0 ? (
                                                            dashboardTestMeChart?.loadTestMeChartData?.length === 0 ? (
                                                                <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                                        <div className="noDataText-font textCenter-absolute">
                                                                            {insightsLbls.noRecordFound}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (

                                                                <>
                                                                    <div className="displayFlex">
                                                                        <div className="col-8">
                                                                            <LineChart
                                                                                chartEntities={appsList.lineChartEntities}
                                                                                optionEntities={dashboardTestMeChart.loadTestMeChartOptions}
                                                                                chartData={dashboardTestMeChart.loadTestMeChartData}
                                                                                value_type={"count"}
                                                                                height={linechart_height}
                                                                                margin={lineChartMargin}
                                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                refId="dashboard_testme"
                                                                                component='dashboard'>
                                                                            </LineChart>
                                                                        </div>
                                                                        <div className="col-4 paddingLeft-20 buubleDiv">
                                                                            <Bubblechart data={dashboardTestMeChart.bubbleChartData} selectedAppCount={dashboardTestMeChart.selectedAppCount}></Bubblechart>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        ) : (
                                                            <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                        {insightsLbls.serviceNotAvailable}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <Loader></Loader>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </CardContainer>


                                            <CardContainer
                                                div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                                div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                                div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                    <SectionTooltip tooltipText={
                                                        {
                                                            "subTitle": insightsLbls.doItForMe,
                                                            "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                            "childern": [
                                                                {
                                                                    "key": insightsLbls.guideCounts,
                                                                    "value": insightsLbls.guidesInSDoItForMeModeinTimeFrame
                                                                }
                                                            ]
                                                        }
                                                    } placement="right"></SectionTooltip>
                                                </ContainerTooltip>

                                                <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                                                    {dashboardDoItForMeChart.dashboardDoItForMeChartDataFetched ? (
                                                        errorCount.errorCount[3] === 0 ? (
                                                            dashboardDoItForMeChart?.loadDoItForMeChartData?.length === 0 ? (
                                                                <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                                        <div className="noDataText-font textCenter-absolute">
                                                                            {insightsLbls.noRecordFound}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (

                                                                <>
                                                                    <div className="displayFlex">
                                                                        <div className="col-8">
                                                                            <LineChart
                                                                                chartEntities={appsList.lineChartEntities}
                                                                                optionEntities={dashboardDoItForMeChart.loadDoItForMeChartOptions}
                                                                                chartData={dashboardDoItForMeChart.loadDoItForMeChartData}
                                                                                value_type={"count"}
                                                                                height={linechart_height}
                                                                                margin={lineChartMargin}
                                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                refId="dashboard_doitforme"
                                                                                component='dashboard'>
                                                                            </LineChart>
                                                                        </div>
                                                                        <div className="col-4 paddingLeft-20 buubleDiv">
                                                                            <Bubblechart data={dashboardDoItForMeChart.bubbleChartData} selectedAppCount={dashboardDoItForMeChart.selectedAppCount}></Bubblechart>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        ) : (
                                                            <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                        {insightsLbls.serviceNotAvailable}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <Loader></Loader>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                            </CardContainer>
                                        </>
                                    )}

                                    <CardContainer
                                        div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                        div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                        div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                            <SectionTooltip tooltipText={
                                                {
                                                    "subTitle": insightsLbls.guideTutorialActivity,
                                                    "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                    "childern": [
                                                        {
                                                            "key": insightsLbls.guideCounts,
                                                            "value": insightsLbls.tutorialGuidesinTimeFrame
                                                        }
                                                    ]
                                                }
                                            } placement="right"></SectionTooltip>
                                        </ContainerTooltip>

                                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                                            {dashboardGuideTutorialsChart.dashboardGuideTutorialsChartDataFetched ? (
                                                errorCount.errorCount[4] === 0 ? (
                                                    dashboardGuideTutorialsChart?.loadGuideTutorialsChartData?.length === 0 ? (
                                                        <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div className="displayFlex">
                                                                <div className="col-8">
                                                                    <LineChart
                                                                        chartEntities={appsList.lineChartEntities}
                                                                        optionEntities={dashboardGuideTutorialsChart.loadGuideTutorialsChartOptions}
                                                                        chartData={dashboardGuideTutorialsChart.loadGuideTutorialsChartData}
                                                                        value_type={"count"}
                                                                        height={linechart_height}
                                                                        margin={lineChartMargin}
                                                                        yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                        refId="dashboard_guideTutorials"
                                                                        component='dashboard'>
                                                                    </LineChart>
                                                                </div>
                                                                <div className="col-4 paddingLeft-20 buubleDiv">
                                                                    <Bubblechart data={dashboardGuideTutorialsChart.bubbleChartData} selectedAppCount={dashboardGuideTutorialsChart.selectedAppCount}></Bubblechart>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                ) : (
                                                    <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                {insightsLbls.serviceNotAvailable}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                )
                                            ) : (
                                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                        <Loader></Loader>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                    </CardContainer>


                                    <CardContainer
                                        div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                        div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                        div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                            <SectionTooltip tooltipText={
                                                {
                                                    "subTitle": insightsLbls.tooltipActivity,
                                                    "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                    "childern": [
                                                        {
                                                            "key": insightsLbls.guideCounts,
                                                            "value": insightsLbls.guidesInTooltipModeinTimeFrame
                                                        }
                                                    ]
                                                }
                                            } placement="right"></SectionTooltip>
                                        </ContainerTooltip>

                                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                            {dashboardTooltipChart.dashboardTooltipChartDataFetched ? (
                                                errorCount.errorCount[10] === 0 ? (
                                                    dashboardTooltipChart?.loadTooltipChartData?.length === 0 ? (
                                                        <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div className="displayFlex">
                                                                <div className="col-8">
                                                                    <LineChart
                                                                        chartEntities={appsList.lineChartEntities}
                                                                        optionEntities={dashboardTooltipChart.loadTooltipChartOptions}
                                                                        chartData={dashboardTooltipChart.loadTooltipChartData}
                                                                        value_type={"count"}
                                                                        height={linechart_height}
                                                                        margin={lineChartMargin}
                                                                        yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                        refId="dashboard_tooltip"
                                                                        component='dashboard'>
                                                                    </LineChart>
                                                                </div>
                                                                <div className="col-4 paddingLeft-20 buubleDiv">
                                                                    <Bubblechart data={dashboardTooltipChart.bubbleChartData} selectedAppCount={dashboardTooltipChart.selectedAppCount}></Bubblechart>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                ) : (
                                                    <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                {insightsLbls.serviceNotAvailable}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                )
                                            ) : (
                                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                        <Loader></Loader>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </CardContainer>


                                    <CardContainer
                                        div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                        div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                        div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                            <SectionTooltip tooltipText={
                                                {
                                                    "subTitle": insightsLbls.pushnotificationActivity,
                                                    "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                    "childern": [
                                                        {
                                                            "key": insightsLbls.guideCounts,
                                                            "value": insightsLbls.guidesInPNModeinTimeFrame
                                                        }
                                                    ]
                                                }
                                            } placement="right"></SectionTooltip>
                                        </ContainerTooltip>

                                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                            {dashboardPushnotificationChart.dashboardPushnotificationChartDataFetched ? (
                                                errorCount.errorCount[11] === 0 ? (
                                                    dashboardPushnotificationChart?.loadPushnotificationChartData?.length === 0 ? (
                                                        <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div className="displayFlex">
                                                                <div className="col-8">
                                                                    <LineChart
                                                                        chartEntities={appsList.lineChartEntities}
                                                                        optionEntities={dashboardPushnotificationChart.loadPushnotificationChartOptions}
                                                                        chartData={dashboardPushnotificationChart.loadPushnotificationChartData}
                                                                        value_type={"count"}
                                                                        height={linechart_height}
                                                                        margin={lineChartMargin}
                                                                        yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                        refId="dashboard_pushnotification"
                                                                        component='dashboard'>
                                                                    </LineChart>
                                                                </div>
                                                                <div className="col-4 paddingLeft-20 buubleDiv">
                                                                    <Bubblechart data={dashboardPushnotificationChart.bubbleChartData} selectedAppCount={dashboardPushnotificationChart.selectedAppCount}></Bubblechart>
                                                                </div>
                                                            </div>
                                                        </>

                                                    )
                                                ) : (
                                                    <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                {insightsLbls.serviceNotAvailable}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                )
                                            ) : (
                                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                        <Loader></Loader>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </CardContainer>

                                    <CardContainer
                                        div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                        div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                        div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                            <SectionTooltip tooltipText={
                                                {
                                                    "subTitle": insightsLbls.beacon,
                                                    "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                    "childern": [
                                                        {
                                                            "key": insightsLbls.guideCounts,
                                                            "value": insightsLbls.guidesInBeaconModeinTimeFrame
                                                        }
                                                    ]
                                                }
                                            } placement="right"></SectionTooltip>
                                        </ContainerTooltip>

                                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                            {dashboardBeaconChart.dashboardBeaconChartDataFetched ? (
                                                errorCount.errorCount[12] === 0 ? (
                                                    dashboardBeaconChart?.loadBeaconChartData?.length === 0 ? (
                                                        <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div className="displayFlex">
                                                                <div className="col-8">
                                                                    <LineChart
                                                                        chartEntities={appsList.lineChartEntities}
                                                                        optionEntities={dashboardBeaconChart.loadBeaconChartOptions}
                                                                        chartData={dashboardBeaconChart.loadBeaconChartData}
                                                                        value_type={"count"}
                                                                        height={linechart_height}
                                                                        margin={lineChartMargin}
                                                                        yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                        refId="dashboard_beacon"
                                                                        component='dashboard'>
                                                                    </LineChart>
                                                                </div>
                                                                <div className="col-4 paddingLeft-20 buubleDiv">
                                                                    <Bubblechart data={dashboardBeaconChart.bubbleChartData} selectedAppCount={dashboardBeaconChart.selectedAppCount}></Bubblechart>
                                                                </div>
                                                            </div>
                                                        </>

                                                    )
                                                ) : (
                                                    <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                {insightsLbls.serviceNotAvailable}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                )
                                            ) : (
                                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                        <Loader></Loader>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </CardContainer>

                                    <CardContainer
                                        div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                        div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                        div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                            <SectionTooltip tooltipText={
                                                {
                                                    "subTitle": insightsLbls.survey,
                                                    "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                    "childern": [
                                                        {
                                                            "key": insightsLbls.guideCounts,
                                                            "value": insightsLbls.guidesInSurveyModeinTimeFrame
                                                        }
                                                    ]
                                                }
                                            } placement="right"></SectionTooltip>
                                        </ContainerTooltip>

                                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                            {dashboardSurveyGraph.dashboardSurveyChartDataFetched ? (
                                                errorCount.errorCount[13] === 0 ? (
                                                    dashboardSurveyGraph?.loadSurveyChartData?.length === 0 ? (
                                                        <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (

                                                        <>
                                                            <p className="marginBottom-0 marginTop-10">{"*" + insightsLbls.chartFilteredBasedOnApps}</p>
                                                            <div className="displayFlex">
                                                                <div className="col-8 marginTop-30">
                                                                    <LineChart
                                                                        chartEntities={appsList.lineChartEntities}
                                                                        optionEntities={dashboardSurveyGraph.loadSurveyChartOptions}
                                                                        chartData={dashboardSurveyGraph.loadSurveyChartData}
                                                                        value_type={"count"}
                                                                        height={linechart_height}
                                                                        margin={lineChartMargin}
                                                                        yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                        refId="dashboard_beacon"
                                                                        component='dashboard'>
                                                                    </LineChart>
                                                                </div>
                                                                <div className="col-4 paddingLeft-20 buubleDiv">
                                                                    <Bubblechart data={dashboardSurveyGraph.bubbleChartData} selectedAppCount={dashboardSurveyGraph.selectedAppCount}></Bubblechart>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                ) : (
                                                    <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                {insightsLbls.serviceNotAvailable}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                )
                                            ) : (
                                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                        <Loader></Loader>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </CardContainer>

                                </>
                        }
                    </>
                )}


                {/* {fetched_details.sectionVisibility.guideInsights_flag && (
                    <CardContainer
                        div1Classes={"col-sm-12 col-lg-12 col-md-12 .marginBottom-30 padding0 "}
                        div2Classes={"card icon-text section-wrapper marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0"}
                        div3Classes={"card-body minHeight200  overflowY-hidden"}>
                        <div className="graph-title displayFlex borderTop col-sm-6 row">
                            <h5 className="section-heading headingText tooltipBG">
                                <div className="displayFlex">
                                    <strong>{insightsLbls.totalCountSummary}</strong>
                                </div>
                            </h5>
                        </div>
                        <div className="marginTop-30">
                            {
                                totalSummaryCount.totalSummaryCountFetched ?
                                    <>{totalSummaryCount.totalSummaryCountTable}</>
                                    : (
                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight200">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader></Loader>
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                    </CardContainer>
                )} */}

                {/* GUIDE TABLE SUMMARY */}

                {fetched_details.sectionVisibility.guideInsights_flag && (<>
                    {

                        // <SectionRefresh sectionTitle={insightsLbls.guideSummary} refreshClass={"marginTop-30"}>
                        //     <i className="fa fa-refresh pull-right pointer row" aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(4)}></i>
                        //     {/* WORKFLOW GRAPH */}
                        // </SectionRefresh>
                    }
                    <CardContainer
                        div1Classes={"col-sm-12 col-lg-12 col-md-12 marginTop-20 padding0 "}
                        div2Classes={"card icon-text section-wrapper marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0"}
                        div3Classes={"card-body minHeight490  overflowY-hidden"}>
                        <ContainerTooltip
                            div1Classes={"graph-title displayFlex"}
                            div2Classes={"col-sm-12 col-lg-12 col-md-12 row"}
                            hTagClasses={"section-heading headingText tooltipBG"}
                        >
                            {process.env.REACT_APP_WESTPAC === 'false' ? (
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.top10Guides,
                                        "tooltipText": insightsLbls.tableTop10GuidesAcrossApps,
                                        "childern": [
                                            {
                                                "key": insightsLbls.guideTitle + ":",
                                                "value": insightsLbls.titleOfGuide
                                            },
                                            {
                                                "key": insightsLbls.appTitle + ":",
                                                "value": insightsLbls.appToWhichGuideBelong
                                            },
                                            {
                                                "key": insightsLbls.totalPlayed + ":",
                                                "value": insightsLbls.totalGuidesPlayedTitle
                                            },
                                            {
                                                "key": insightsLbls.guidePlayed + ":",
                                                "value": insightsLbls.totalGuidesCompleteCount
                                            },
                                            {
                                                "key": insightsLbls.showMePlayed + ":",
                                                "value": insightsLbls.totalSlideshowVideoGIFCount
                                            },
                                            {
                                                "key": insightsLbls.testMePlayed + ":",
                                                "value": insightsLbls.totalTestPassCount
                                            },
                                            {
                                                "key": insightsLbls.doItForMePlayed + ":",
                                                "value": insightsLbls.totalNumberOfTimesGuidePlayedInDoItForMe
                                            },
                                            {
                                                "key": insightsLbls.tutorialGuidePlayed + ":",
                                                "value": insightsLbls.guidePlayedInTutorialMode
                                            }
                                        ]
                                    }

                                } placement="right"></SectionTooltip>
                            ) : (
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.top10Guides,
                                        "tooltipText": insightsLbls.tableTop10GuidesAcrossApps,
                                        "childern": [
                                            {
                                                "key": insightsLbls.guideTitle + ":",
                                                "value": insightsLbls.titleOfGuide
                                            },
                                            {
                                                "key": insightsLbls.appTitle + ":",
                                                "value": insightsLbls.appToWhichGuideBelong
                                            },
                                            {
                                                "key": insightsLbls.totalPlayed + ":",
                                                "value": insightsLbls.totalGuidesPlayedTitle
                                            },
                                            {
                                                "key": insightsLbls.guidePlayed + ":",
                                                "value": insightsLbls.totalGuidesCompleteCount
                                            },
                                            {
                                                "key": insightsLbls.tutorialGuidePlayed + ":",
                                                "value": insightsLbls.guidePlayedInTutorialMode
                                            }
                                        ]
                                    }
                                } placement="right"></SectionTooltip>
                            )}

                            <button className="pull-right pointer refreshBtn" onClick={() => refreshSections(4)}> {insightsLbls.refresh}</button>

                        </ContainerTooltip>
                        {/* <div className="card-body cardPadding-bt100 tabelEditing workflow-section search_section tableInherit commonSummaryTable marginTop-20"> */}
                        {dashboardGuideSummary.dashboardGuideSummaryFetched ? (
                            errorCount.errorCount[5] === 0 ? (
                                <div className="guideSummaryTable tabelEditing commonSummaryTable">
                                    <CommonExpandableTable tableId="3"
                                        data={fetched_details.dashboard.dashboardGuideSummaryTableData.data}
                                        currentPage={0}
                                        barColors=""
                                        linkPresent={true}
                                        showExport={false}
                                        showSearch={false}
                                        showEntries={false}
                                        defaultSort={dashboardGuideSummary.defaultSort}
                                        tablePagination={dashboardGuideSummary.tablePagination}
                                        tableEvent={handleTableEvents}
                                        firstTimeloadFlag={fetched_details.dashboard.dashboardGuideSummaryTableData.firstTimeload}
                                        hideDeleted={false}
                                        modalTable={false}
                                        source={"dashboard"}
                                        tableComponent={"guide"}
                                    />
                                </div>
                            ) : (
                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                    {insightsLbls.serviceNotAvailable}
                                </h5>
                            )
                        ) : (
                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight400">
                                <div className="displayFlex alignCenter margin-0-auto">
                                    <Loader></Loader>
                                </div>
                            </div>
                        )}
                        {/* </div> */}

                    </CardContainer></>)}



                {/* TOOLTIP TABLE SUMMARY */}
                {fetched_details.sectionVisibility.guideInsights_flag && (<>
                    {

                        // <SectionRefresh sectionTitle={insightsLbls.tooltipSummary} refreshClass={"marginTop-30"}>
                        //     <i className="fa fa-refresh pull-right pointer row" aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(6)}></i>
                        // </SectionRefresh>
                    }
                    <CardContainer
                        div1Classes={"col-sm-12 col-lg-12 col-md-12 padding0 marginTop-20"}
                        div2Classes={"card icon-text section-wrapper marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0"}
                        div3Classes={"card-body minHeight490  overflowY-hidden"}>
                        <ContainerTooltip
                            div1Classes={"graph-title displayFlex"}
                            div2Classes={"col-sm-12 col-lg-12 col-md-12 row"}
                            hTagClasses={"section-heading headingText tooltipBG"}
                        >
                            <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.top10Tooltips,
                                    "tooltipText": insightsLbls.tableTop10GuidesAcrossApps,
                                    "childern": [
                                        {
                                            "key": insightsLbls.guideTitle + ":",
                                            "value": insightsLbls.titleOfGuide
                                        },
                                        {
                                            "key": insightsLbls.appTitle + ":",
                                            "value": insightsLbls.appToWhichGuideBelong
                                        },
                                        {
                                            "key": insightsLbls.totalPlaysLabel + ":",
                                            "value": insightsLbls.totalPlays
                                        },
                                        {
                                            "key": insightsLbls.guidanceLabel + ":",
                                            "value": insightsLbls.guidanceTooltip
                                        },
                                        {
                                            "key": insightsLbls.validationLabel + ":",
                                            "value": insightsLbls.validationTooltip
                                        },
                                        {
                                            "key": insightsLbls.guidanceAndValidation + ":",
                                            "value": insightsLbls.guidanceAndValidationTooltip
                                        },
                                        {
                                            "key": insightsLbls.digitalDuctTapeLabel + ":",
                                            "value": insightsLbls.digitalDuctTapeTooltip
                                        },
                                        {
                                            "key": insightsLbls.powerFormLabel + ":",
                                            "value": insightsLbls.powerFormTooltip
                                        }
                                    ]
                                }
                            } placement="right"></SectionTooltip>

                            <button className="pull-right pointer refreshBtn" onClick={() => refreshSections(6)}> {insightsLbls.refresh}</button>

                        </ContainerTooltip>
                        {/* <div className="card-body cardPadding-bt100 tabelEditing workflow-section search_section tableInherit commonSummaryTable marginTop-20"> */}
                        {dashboardTooltipSummary.dashboardTooltipSummaryFetched ? (
                            errorCount.errorCount[5] === 0 ? (
                                <div className="guideSummaryTable tabelEditing commonSummaryTable">
                                    <CommonExpandableTable tableId="3"
                                        data={fetched_details.dashboard.dashboardTooltipSummaryTableData.data}
                                        currentPage={0}
                                        barColors=""
                                        linkPresent={true}
                                        showExport={false}
                                        showSearch={false}
                                        showEntries={false}
                                        defaultSort={dashboardTooltipSummary.defaultSort}
                                        tablePagination={dashboardTooltipSummary.tablePagination}
                                        tableEvent={handleTableEvents}
                                        firstTimeloadFlag={fetched_details.dashboard.dashboardTooltipSummaryTableData.firstTimeload}
                                        hideDeleted={false}
                                        modalTable={false}
                                        source={"dashboard"}
                                        tableComponent={"tooltip"}
                                    />
                                </div>
                            ) : (
                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                    {insightsLbls.serviceNotAvailable}
                                </h5>
                            )
                        ) : (
                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight400">
                                <div className="displayFlex alignCenter margin-0-auto">
                                    <Loader></Loader>
                                </div>
                            </div>
                        )}
                        {/* </div> */}

                    </CardContainer>
                </>
                )}

                <div id="userEngagementSection marginTop-30">
                    {
                        // <SectionRefresh sectionTitle={insightsLbls.userActivity} refreshClass={"marginTop-30"}>
                        //     <i className="fa fa-refresh pull-right cursorPointer row displayFlex"
                        //         aria-hidden="true"
                        //         title={insightsLbls['refresh']}
                        //         style={{ cursor: userEngagementLazyLoadChart.lazyLoad_flag ? 'pointer' : 'default' }}
                        //         onClick={() => refreshSections(5)}></i>
                        // </SectionRefresh>
                    }
                    <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height600 blockImportant marginTop-20">
                        <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                            div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent paddingBottom-20"}
                            div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
                            <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.userActivity,
                                        "tooltipText": insightsLbls.userActivityGraphDashboard,
                                        "childern": []
                                    }
                                } placement="right"></SectionTooltip>

                                {/* <i className="fa fa-refresh pull-right cursorPointer row displayFlex"
                                aria-hidden="true"
                                title={insightsLbls['refresh']}
                                style={{ cursor: userEngagementLazyLoadChart.lazyLoad_flag ? 'pointer' : 'default' }}></i> */}
                                <button className="pull-right pointer refreshBtn" onClick={() => refreshSections(5)} style={{ cursor: userEngagementLazyLoadChart.lazyLoad_flag ? 'pointer' : 'default' }}> {insightsLbls.refresh}</button>

                            </ContainerTooltip>
                            {/* <p className="marginBottom-0 marginTop-10"><span className="bg-yellow">
                                {"*" + insightsLbls.min7DaysSelection}</span></p> */}
                            {/* <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                {appsList.appListString.length && (
                                    // <DashboardUserEngagementLazyLoad
                                    //     eventSource={fetched_details?.commonComponentData?.eventSource.eventSourceDashboard}
                                    //     dispatchOverlay={setSectionRefresh}
                                    //     refreshFlag={userEngagementLazyLoadChart.refreshFlag}
                                    //     envFilter={fetched_details?.commonComponentData?.envFilter?.envCode}
                                    //     component="dashboard"
                                    //     appList={appsList.appListString}>
                                    // </DashboardUserEngagementLazyLoad>


                                    <LineChart
                                        chartEntities={appsList.lineChartEntities}
                                        optionEntities={dashboardUserEngagementChart.loadUserEngagementChartOptions}
                                        chartData={fetched_details?.dashboard?.dashboardUserEngagementData?.data?.graphData}
                                        value_type={"count"}
                                        height={linechart_height}
                                        margin={lineChartMargin}
                                        yAxisLabel={insightsLbls.yAxisLabelCount}
                                        refId="dashboard_userEngagement"
                                        component='dashboard'>
                                    </LineChart>
                                )}

                            </div> */}
                            <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                {dashboardUserEngagementChart.dashboardUserEngagementChartDataFetched ? (
                                    errorCount.errorCount[20] === 0 ? (
                                        dashboardUserEngagementChart?.loadUserEngagementChartData?.length === 0 ? (
                                            <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <div className="noDataText-font textCenter-absolute">
                                                        {insightsLbls.noRecordFound}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (


                                            <div className="displayFlex">
                                                <div className="col-12 marginTop-30">
                                                    <LineChart
                                                        chartEntities={appsList.lineChartEntities}
                                                        optionEntities={dashboardUserEngagementChart.loadUserEngagementChartOptions}
                                                        chartData={dashboardUserEngagementChart.loadUserEngagementChartData}
                                                        value_type={"count"}
                                                        height={linechart_height}
                                                        margin={lineChartMargin}
                                                        yAxisLabel={insightsLbls.yAxisLabelCount}
                                                        refId="dashboard_userEngagement"
                                                        component='dashboard'>
                                                    </LineChart>
                                                </div>

                                            </div>

                                        )
                                    ) : (
                                        <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                    {insightsLbls.serviceNotAvailable}
                                                </h5>
                                            </div>
                                        </div>
                                    )
                                ) : (
                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </CardContainer>
                    </div>
                </div>




                {/*wORKflow Line Graph */}
                {/* {

                    <SectionRefresh sectionTitle={insightsLbls.workFlow} refreshClass={"marginTop-30"}>
                        <i className="fa fa-refresh pull-right pointer row" aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(1)}></i>
                    </SectionRefresh>
                } */}
                {/* <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant"> */}
                {/* <CardContainer
                    div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                    div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                    div3Classes={"card-body minHeight490  overflowY-hidden"}>
                    <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                        <SectionTooltip tooltipText={
                            {
                                "subTitle": insightsLbls.activity,
                                "tooltipText": insightsLbls.lineGraphonSelectedApps,
                                "childern": []
                            }
                        } placement="right"></SectionTooltip>
                    </ContainerTooltip>

                    <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                        {dashboardWorkflowChart.dashboardWorkflowChartDataFetched ? (
                            errorCount.errorCount[6] === 0 ? (
                                dashboardWorkflowChart.loadWorkflowChartData.length === 0 ? (
                                    <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <div className="noDataText-font textCenter-absolute">
                                                {insightsLbls.noRecordFound}
                                            </div>
                                        </div>
                                    </div>
                                ) : (


                                    dashboardWorkflowChart.workflowChartFetched ? (dashboardWorkflowChart.workflowChart) :
                                        (
                                            <>
                                                <LineChartWorkflow></LineChartWorkflow>
                                            </>
                                        )


                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {insightsLbls.serviceNotAvailable}
                                        </h5>
                                    </div>
                                </div>
                            )
                        ) : (
                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                <div className="displayFlex alignCenter margin-0-auto">
                                    <Loader></Loader>
                                </div>
                            </div>
                        )}
                    </div>

                </CardContainer> */}

                {/*wORKflow Table */}
                {/* <CardContainer
                    div1Classes={"col-sm-12 col-lg-12 col-md-12 .marginBottom-30 padding0 "}
                    div2Classes={"card icon-text section-wrapper marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0"}
                    div3Classes={"card-body minHeight400  overflowY-hidden"}>
                    <ContainerTooltip
                        div1Classes={"graph-title displayFlex"}
                        div2Classes={"col-sm-6 row"}
                        hTagClasses={"section-heading headingText tooltipBG"}>
                        <SectionTooltip tooltipText={
                            {
                                "subTitle": insightsLbls.top10Workflows,
                                "tooltipText": insightsLbls.tableTop10WorkflowsAcrossApps,
                                "childern": [
                                    {
                                        "key": insightsLbls.workflowTitle + ":",
                                        "value": insightsLbls.titleWorkflow
                                    },
                                    {
                                        "key": insightsLbls.type + ":",
                                        "value": insightsLbls.typeWorkflow
                                    },
                                    {
                                        "key": insightsLbls.walkthroughCounts + ":",
                                        "value": insightsLbls.totalCountinWorkflow
                                    },
                                    {
                                        "key": insightsLbls.avgTime + ":",
                                        "value": insightsLbls.avgTimeonWorkflow
                                    },
                                    {
                                        "key": insightsLbls.bestTime + ":",
                                        "value": insightsLbls.bestimeAchievedWorkflow
                                    }
                                ]
                            }
                        } placement="right"></SectionTooltip>
                    </ContainerTooltip>
                    <div className="card-body cardPadding-bt100 tabelEditing workflow-section search_section tableInherit commonSummaryTable marginTop-20">
                        {dashboardWorkflowTableData.dashboardWorkflowTableDataFetched ? (
                            errorCount.errorCount[7] === 0 ? (
                                <CommonTable
                                    tableId="Workflows"
                                    data={fetched_details.dashboard.dashboardWorkflowsTableData.data}
                                    currentPage={0}
                                    barColors=""
                                    linkPresent={true}
                                    showExport={false}
                                    showSearch={false}
                                    showEntries={false}
                                    showPagination={false}
                                    defaultSort={dashboardWorkflowTableData.defaultSort}
                                    tablePagination={dashboardWorkflowTableData.tablePagination}
                                    tableEvent={handleTableEvents}
                                    firstTimeloadFlag={
                                        fetched_details.dashboard.dashboardWorkflowsTableData.firstTimeload
                                    }
                                    modalTable={false}

                                />
                            ) : (
                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                    {insightsLbls.serviceNotAvailable}
                                </h5>
                            )
                        ) : (
                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight400">
                                <div className="displayFlex alignCenter margin-0-auto">
                                    <Loader></Loader>
                                </div>
                            </div>
                        )}
                    </div>

                </CardContainer> */}


                {/* SURVEY TABLE */}
                <CardContainer
                    div1Classes={"col-sm-12 col-lg-12 col-md-12 marginTop-20 padding0 "}
                    div2Classes={"card icon-text section-wrapper marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0"}
                    div3Classes={"card-body minHeight400  overflowY-hidden"}>
                    <ContainerTooltip
                        div1Classes={"graph-title displayFlex"}
                        div2Classes={"col-sm-12 col-lg-12 col-md-12 row"}
                        hTagClasses={"section-heading headingText tooltipBG"}
                    >
                        <SectionTooltip tooltipText={
                            {
                                "subTitle": insightsLbls.top10Survey,
                                "tooltipText": insightsLbls.tableTop10SurveysApps,
                                "childern": [
                                    {
                                        "key": insightsLbls.surveyName + ":",
                                        "value": insightsLbls.nameSurvey
                                    },
                                    {
                                        "key": insightsLbls.appTitle + ":",
                                        "value": insightsLbls.appToWhichSurveyBelong
                                    },
                                    {
                                        "key": insightsLbls.labels + ":",
                                        "value": insightsLbls.labelsToSurvey
                                    },
                                    {
                                        "key": insightsLbls.responses + ":",
                                        "value": insightsLbls.noOfResponsesForSurvey
                                    },
                                    {
                                        "key": insightsLbls.surveyDistribution + ":",
                                        "value": insightsLbls.distributionAnalyzedSurevyNegNeuPos
                                    },
                                    {
                                        "key": insightsLbls.lastUpdatedOn + ":",
                                        "value": insightsLbls.dateTimeofSurveyModification
                                    }
                                ]
                            }
                        } placement="right"></SectionTooltip>
                        <button className="pull-right pointer refreshBtn" onClick={() => refreshSections(2)}> {insightsLbls.refresh}</button>
                    </ContainerTooltip>

                    <div className="card-body cardPadding-bt100 tabelEditing workflow-section search_section tableInherit commonSummaryTable marginTop-20">
                        <span>{"*" + insightsLbls.tableFilteredBasedOnApps}</span>
                        {dashboardSurveyData.dashboardSurveyDataFetched ? (
                            errorCount.errorCount[8] === 0 ? (
                                <CommonTable
                                    tableId="Surveys"
                                    data={fetched_details.dashboard.dashboardSurveysTableData.data}
                                    currentPage={0}
                                    barColors="positiveColors"
                                    linkPresent={true}
                                    showExport={false}
                                    showSearch={false}
                                    showEntries={false}
                                    showPagination={false}
                                    defaultSort={dashboardSurveyData.defaultSort}
                                    tablePagination={dashboardSurveyData.tablePagination}
                                    tableEvent={handleTableEvents}
                                    firstTimeloadFlag={
                                        fetched_details.dashboard.dashboardSurveysTableData.firstTimeload
                                    }
                                    modalTable={false}

                                />
                            ) : (
                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                    {insightsLbls.serviceNotAvailable}
                                </h5>
                            )
                        ) : (
                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight400">
                                <div className="displayFlex alignCenter margin-0-auto">
                                    <Loader></Loader>
                                </div>
                            </div>
                        )}
                    </div>
                </CardContainer>
            </div>


            {/* LOCATION COMPONENT */}
            {/* {

                <SectionRefresh sectionTitle={insightsLbls.locationInsights} refreshClass={"marginTop-30"}>
                    <i className="fa fa-refresh pull-right pointer row" aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(3)}></i>
                </SectionRefresh>
            } */}
            <CardContainer
                div1Classes={
                    "col-sm-12 col-lg-12 col-md-12 marginTop-20 padding0 "
                }
                div2Classes={
                    "card icon-text section-wrapper marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0"
                }
                div3Classes={"card-body minHeight490  overflowY-hidden"}
            >
                <ContainerTooltip
                    div1Classes={"graph-title displayFlex"}
                    div2Classes={"col-sm-12 col-lg-12 col-md-12 row"}
                    hTagClasses={"section-heading headingText tooltipBG"}
                >
                    <SectionTooltip tooltipText={
                        {
                            "subTitle": insightsLbls.whereUsersAccessFrom,
                            "tooltipText": insightsLbls.geoLocationChartCountryWise1,
                            "childern": []
                        }
                    } placement="right"></SectionTooltip>

                    <button className="pull-right pointer refreshBtn" onClick={() => refreshSections(3)}> {insightsLbls.refresh}</button>

                </ContainerTooltip>
                {dashboardCountriesChartData.dashboardCountriesChartDataFetched ? (
                    errorCount.errorCount[9] === 0 ? (
                        <div>
                            <WorldMap
                                // WorldMapData={fetched_details.dashboard.dashboardCountriesChartData.data}
                                WorldMapData={dashboardCountriesChartData.countryData}
                                boudaryValues={dashboardCountriesChartData.boundaryValues}
                                colors={dashboardCountriesChartData.colors}
                            ></WorldMap>
                            <div className="legendKey">
                                <div>
                                    {dashboardCountriesChartData.legendData.map((data: any, key: number) => {
                                        return (
                                            <span className="worldLegend"
                                                style={{ backgroundColor: data.color }} key={key}></span>
                                        );
                                    })}
                                    {/* "{backgroundColor: data.color}" */}
                                </div>
                                <div>
                                    {dashboardCountriesChartData.legendData.map((data: any, key: number) => {
                                        return (
                                            <span className="worldLegend font14"
                                            >≥{data.value}</span>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="countryListDiv">
                                <div className="countryNameDiv">
                                    <p className="font14 chipParagraph">
                                        {
                                            dashboardCountriesChartData.countryData.map((country: any) => {
                                                return (
                                                    <>
                                                        <span className="infoChip maxWidth250 displayFlex countryNameChip" style={{ backgroundColor: `${country.color}` }} data-tip data-for={country.countryName}>
                                                            <span className="maxWidth200 countryLabel"
                                                            >{country.countryName}</span>
                                                            <span>:&nbsp;{country.count}</span>
                                                            <ReactTooltip id={country.countryName} place="bottom">{country.countryName}: {country.count}</ReactTooltip>
                                                        </span>
                                                    </>
                                                );
                                            })
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <h5 className="errorCss card-heading font-weight-400 text-center">
                            {insightsLbls.serviceNotAvailable}
                        </h5>
                    )
                ) : (
                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight400">
                        <div className="displayFlex alignCenter margin-0-auto">
                            <Loader></Loader>
                        </div>
                    </div>
                )}
            </CardContainer>



            <CommonModal
                modalState={exportModal.open}
                dispatchModalState={closeExportModal}
                modalTitle={insightsLbls.exportDashboardData}
                size={'lg'}
                footerDisabled={true}
                exportModal={false}
                padding={true}
                modalDialogClass={''}

                dispatchModalCancel={closeExportModal}

            // okBtnState={exportModal.exportDisabled}
            >
                <div className="exportDiv minHeight200">

                    <div className='exportFilter'>
                        <div className="displayFlex">
                            <Monthpicker errorDispacthed={getErrorFlag} datesDispatch={getDates}></Monthpicker>
                        </div>

                        <div className="displayFlex marginTop-10 paddingTop-20 paddingBottom-50 paddingLeft-76 positionRelative">
                            <AppListDropdown finalApps={getSelectedAppsForExport} getTotalAppList={getTotalAppList} fromComponent="exportModal" component="exportModal"></AppListDropdown>
                            <EventSource
                                component={"dashboardExportModal"}
                                outerDivClass={'marginLeft-30 positionRelative'}
                                eventSourceValue={getEventSourceValues}
                                defaultValue={exportModal.defaultValue}></EventSource>
                        </div>
                    </div>

                    <div className="displayFlex height100-percent exportExcel-div positionRelative">
                        <div className="col-lg-12 paddingTB-20 displayFlex btns-div">
                            <div className="downloadCsv col-lg-6 padding0 displayFlex">
                                <button className="btn btn-primary font13 exportModal-btn" disabled={exportModal.exportDisabled} onClick={() => exportDashboardData(false)}>
                                    <i className="fa fa-download marginRight-5" aria-hidden="true"></i>
                                    {insightsLbls['download']}
                                </button>
                            </div>
                            <div className="marginTop-25">
                                <div className="vl"></div>
                                <div className="or-div">
                                    {insightsLbls['or']}
                                </div>
                            </div>
                            <span className="text font14 marginLeft-10 font500">
                                {insightsLbls['excelViewerQn']}
                            </span>
                            <div className="downloadCsv col-lg-6 padding0 displayFlex">
                                <button className="btn btn-primary font13 exportModal-btn" disabled={exportModal.exportDisabled} onClick={() => exportDashboardData(true)}>
                                    <i className="fa fa-cloud marginRight-5" aria-hidden="true"></i>
                                    {insightsLbls['viewOnline']}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonModal>

            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}
        </section>
    );
};

export default withRouter(withReducer("dashboard", dashboardReducer)(Dashboard));

/**
 * @author mtiwari
 * Updated on 22 May, 2024
 * Corrected section tooltip structure for guide & validation while adding chartto custom dashboard
 */

import React, { useState, useEffect, useRef, useCallback } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';


import qs from 'querystring';
import { CommonUtils } from "utils/CommonUtils";
import { ChartColors } from "utils/GlobalConstants";


// Redux Imports
import { withReducer } from "store/withReducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { TooltipState } from "store/Reducers/GuideInsights/tooltips";
import tooltipReducer from "store/Reducers/GuideInsights/tooltips";
import * as tooltipActions from "store/Actions/index";
import { EventSourceState, UrlOptionState, TooltipFilterState, CommonComponentState, LanguageState } from "store/Reducers";
import * as ActionTypes from "../../../store/Actions/ActionTypes";


// SCSS Imports
import "./Tooltips.scss";


import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import SectionHeader from "components/Layouts/SectionHeader";
import Datepicker from "components/Calender/Datepicker";
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import EventSource from 'components/EventSource/EventSource';
import Segments from 'components/SegmentationFilter/Segmentation';
import Loader from "components/Loader/Loader";
import TooltipModal from "components/CommonModal/Tooltip/TooltipModal";
import CustomDashboardModal from "components/CommonModal/CustomDashboardModal/CustomDashboardModal";
import CommonTable from "components/Table/CommonTable";
import Maintenance from "components/Maintenance/Maintenance";
import Linechart from "components/Chart/nvd3Charts/LineChart"
import { useErrorBoundary } from 'react-error-boundary';
import SectionFilters from "components/Layouts/SectionFilters";
import EnvironmentFilter from "components/EnvironmentFilter/EnvironmentFilter";
import { InsightLabels } from "labels";
import { v4 as uuidv4 } from 'uuid';
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";
import UserInfoSection from "components/Layouts/UserInfoSection";


// type PathParamsType = {
//     // param1: string,
// }

// // Your component own properties
// type props = RouteComponentProps<PathParamsType> & {
//     // someString: string,
// }

interface CurrentState {
    eventSource: EventSourceState,
    tooltips: TooltipState,
    urlOption: UrlOptionState,
    tooltipList: TooltipFilterState,
    commonComponentData: CommonComponentState,
    languageData: LanguageState
}


const Tooltips: React.FC = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();
    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    const dispatch = useDispatch();

    const location = useLocation();
    const navigate = useNavigate();

    const tooltipActivityChartMount = useRef(false);
    const tooltipPowerformChartMount = useRef(false);
    const powerformSummaryTableMount = useRef(false);
    const smartTipSummaryTableMount = useRef(false);

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    const eventSource = 'player';
    let fetched_details = useSelector((state: CurrentState) => state);

    let activity_linechart_height = 400;
    let lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "65",
    }

    const [filterValues, setFilterValues] = useState({
        eventSource: "",
        guideStatus: "",
        segmentsGroup: {
            segmentsFilter: 0,
            segmentTitle: ''
        }
    });


    // const [goingUp, setGoingUp] = useState(false);

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        disabled: true,
    });

    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0, 0, 0],
        showMaintenance_flag: false
    });

    const [guideIds, setGuideIds] = useState({
        list: ''
    })

    const [activityChart, setActivityChart] = useState({
        chartData: [],
        chartOptions: [],
        chartFetched: false,
        activityLineChart: {},
        activityLineChartFetched: false,
        api: '',
    })

    const [ducttapeChart, setDucttapeChart] = useState({
        chartData: [],
        chartOptions: [],
        chartFetched: false,
        ducttapeLineChart: {},
        ducttapeLineChartFetched: false,
        api: '',
    })

    const [powerformChart, setPowerformChart] = useState({
        chartData: [],
        chartOptions: [],
        chartFetched: false,
        powerformLineChart: {},
        powerformLineChartFetched: false,
        api: '',
    })

    const [powerformSummary, setPowerformSummary] = useState({
        summaryFetched: false,
        searchQuery: '',
        sortKey: 'submit_count',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 2,
            'sortValue': 'desc'
        },
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true,
        hideDeleted: 0
    });

    const [smartTipSummary, setSmartTipSummary] = useState({
        summaryFetched: false,
        searchQuery: '',
        sortKey: 'digital_duct_tape_count',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 4,
            'sortValue': 'desc'
        },
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true,
        hideDeleted: 0
    });

    const [parameter, setParameter] = useState({
        paramter: "",
        source: "",
    });

    const [request_uuid, setUuid] = useState({
        value: uuidv4()
    })

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
            window.scrollTo(0, 0);
            CommonUtils.LeftPanelHighlight(1, 4, 0, true, false);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        document.title = insightsLbls.docTitleTooltips;
    }, [insightsLbls])


    useEffect(() => {
        setFilterValues((prevState: any) => {
            return {
                ...prevState,
                eventSource: fetched_details?.commonComponentData?.eventSource.eventSourceTooltips,
                segmentsGroup: { segmentsFilter: fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter, segmentTitle: fetched_details?.commonComponentData?.segmentsFilter?.segmentTitle },
            };
        });

    }, []);


    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getTooltipChartData = (reqUUID, guideIds: any, apiErrorFlag?: string) => {
        let params = {
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "time_zone": timeZone,
            'event_source': fetched_details?.commonComponentData?.eventSource.eventSourceTooltips,
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            "env_code": fetched_details?.commonComponentData?.envFilter.envCode ?? '',
            'request_uuid': reqUUID
        };

        setActivityChart((prevState: any) => {
            return {
                ...prevState,
                api: ['TOOLTIP_VALIDATION'],
            }
        })

        setDucttapeChart((prevState: any) => {
            return {
                ...prevState,
                api: ['DUCT_TAPE'],
            }
        })

        tooltipActions.getTooltipData(
            params,
            "TOOLTIP_VALIDATION",
            ActionTypes.SET_TOOLTIP_ACTIVITY_CHART,
            apiErrorFlag ?? '',
            false,
            guideIds
        ).then((res: any) => dispatch(res));
    }

    const getPowerformSummaryData = (reqUUID, guideIds: any, apiErrorFlag?: string) => {
        let params = {
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "time_zone": timeZone,
            'hide_deleted': powerformSummary.hideDeleted,
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            'search_text': encodeURIComponent(powerformSummary.searchQuery),
            'sort_by': powerformSummary.sortKey,
            'order': powerformSummary.sortValue,
            'page_index': powerformSummary.page,
            'page_size': powerformSummary.limit,
            "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceTooltips,
            "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            "env_code": fetched_details?.commonComponentData?.envFilter.envCode ?? '',
            'request_uuid': reqUUID
        };

        tooltipActions.getTooltipData(
            params,
            "POWER_FORM_SUMMARY",
            ActionTypes.SET_POWER_FORM_SUMMARY,
            apiErrorFlag ?? '',
            powerformSummary.firstLoad,
            guideIds
        ).then((res: any) => dispatch(res));
    }

    const getSmartTipSummaryData = (reqUUID, guideIds: any, apiErrorFlag?: string) => {
        let params = {
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "time_zone": timeZone,
            'hide_deleted': smartTipSummary.hideDeleted,
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            'query': encodeURIComponent(smartTipSummary.searchQuery),
            'sort_by': smartTipSummary.sortKey,
            'order': smartTipSummary.sortValue,
            'page_index': smartTipSummary.page,
            'page_size': smartTipSummary.limit,
            "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceTooltips,
            "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            'all_guides': true,
            "env_code": fetched_details?.commonComponentData?.envFilter.envCode ?? '',
            'request_uuid': reqUUID
        };

        tooltipActions.getTooltipData(
            params,
            "SMART_TIP_SUMMARY",
            ActionTypes.SET_SMART_TIP_SUMMARY,
            apiErrorFlag ?? '',
            smartTipSummary.firstLoad,
            guideIds
        ).then((res: any) => dispatch(res));
    }

    const getPowerformChartData = (reqUUID, guideIds: any, apiErrorFlag?: string) => {
        let params = {
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "time_zone": timeZone,
            'event_source': fetched_details?.commonComponentData?.eventSource.eventSourceTooltips,
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            "env_code": fetched_details?.commonComponentData?.envFilter.envCode ?? '',
            'request_uuid': reqUUID
        };

        setPowerformChart((prevState: any) => {
            return {
                ...prevState,
                api: ['POWER_FORM_STACKED_CHART'],
            }
        })

        tooltipActions.getTooltipData(
            params,
            "POWER_FORM_STACKED_CHART",
            ActionTypes.SET_POWER_FORM_CHART,
            apiErrorFlag ?? '',
            false,
            guideIds
        ).then((res: any) => dispatch(res));
    }

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false);

            switch (optn) {
                case 0:
                    setActivityChart((prevState: any) => {
                        return {
                            ...prevState,
                            chartFetched: false,
                            activityLineChart: {},
                            activityLineChartFetched: false,
                        };
                    });
                    getTooltipChartData(request_uuid.value, guideIds.list);
                    break;

                case 1:
                    setPowerformChart((prevState: any) => {
                        return {
                            ...prevState,
                            chartFetched: false,
                            powerformLineChart: {},
                            powerformLineChartFetched: false,
                        };
                    });
                    getPowerformChartData(request_uuid.value, guideIds.list)
                    setPowerformSummary((prevState: any) => {
                        return {
                            ...prevState,
                            summaryFetched: false,
                            searchQuery: '',
                            sortKey: 'submit_count',
                            page: 1,
                            showExport: true,
                            defaultSort: {
                                'sortColumn_index': 2,
                                'sortValue': 'desc'
                            },
                            // renderTaggedTable: false,
                            sortValue: 'desc',
                            limit: 5,
                            tablePagination: {
                                offset: 0,
                                data: [],
                                perPage: 5,
                                currentPage: 0
                            },
                            firstLoad: true,
                            hideDeleted: 0,
                        };
                    })
                    break;

                case 2:
                    setDucttapeChart((prevState: any) => {
                        return {
                            ...prevState,
                            chartFetched: false,
                            ducttapeLineChart: {},
                            ducttapeLineChartFetched: false,
                        };
                    });

                    getTooltipChartData(request_uuid.value, guideIds.list);
                    break;

                case 3:
                    setSmartTipSummary((prevState: any) => {
                        return {
                            ...prevState,
                            summaryFetched: false,
                            searchQuery: '',
                            sortKey: 'digital_duct_tape_count',
                            page: 1,
                            showExport: true,
                            defaultSort: {
                                'sortColumn_index': 4,
                                'sortValue': 'desc'
                            },
                            // renderTaggedTable: false,
                            sortValue: 'desc',
                            limit: 5,
                            tablePagination: {
                                offset: 0,
                                data: [],
                                perPage: 5,
                                currentPage: 0
                            },
                            firstLoad: true,
                            hideDeleted: 0,
                        };
                    });
                    break;

                default:
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const apiCatchError = (index: number, option: number) => {
        try {

            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }
    };

    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const getGuideIds = (data: any) => {
        try {
            setGuideIds((prevState: any) => {
                return {
                    ...prevState,
                    list: data
                }
            })

            setActivityChart((prevState: any) => {
                return {
                    ...prevState,
                    chartFetched: false,
                    activityLineChart: {},
                    activityLineChartFetched: false,
                };
            });

            setDucttapeChart((prevState: any) => {
                return {
                    ...prevState,
                    chartFetched: false,
                    ducttapeLineChart: {},
                    ducttapeLineChartFetched: false,
                };
            });

            setPowerformChart((prevState: any) => {
                return {
                    ...prevState,
                    chartFetched: false,
                    powerformLineChart: {},
                    powerformLineChartFetched: false,
                };
            });

            setPowerformSummary((prevState: any) => {
                return {
                    ...prevState,
                    summaryFetched: false,
                    searchQuery: '',
                    sortKey: 'submit_count',
                    page: 1,
                    showExport: true,
                    defaultSort: {
                        'sortColumn_index': 2,
                        'sortValue': 'desc'
                    },
                    // renderTaggedTable: false,
                    sortValue: 'desc',
                    limit: 5,
                    tablePagination: {
                        offset: 0,
                        data: [],
                        perPage: 5,
                        currentPage: 0
                    },
                    firstLoad: false,
                    hideDeleted: 0
                };
            });

            setSmartTipSummary((prevState: any) => {
                return {
                    ...prevState,
                    summaryFetched: false,
                    searchQuery: '',
                    sortKey: 'digital_duct_tape_count',
                    page: 1,
                    showExport: true,
                    defaultSort: {
                        'sortColumn_index': 4,
                        'sortValue': 'desc'
                    },
                    // renderTaggedTable: false,
                    sortValue: 'desc',
                    limit: 5,
                    tablePagination: {
                        offset: 0,
                        data: [],
                        perPage: 5,
                        currentPage: 0
                    },
                    firstLoad: false,
                    hideDeleted: 0
                };
            });

            // getPowerformSummaryData(data);
            // getTooltipChartData(data);
            // getPowerformChartData(data);
            // getSmartTipSummaryData(data);
        } catch (error) {
            showBoundary(error)
        }
    }

    const handleTableEvents = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "sortColumn":
                    setPowerformSummary((prevState) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "changePage":
                    setPowerformSummary((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":
                    setPowerformSummary((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });
                    break;

                case "changeEntries":
                    setPowerformSummary((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false,
                        }
                    });
                    break;

                case "hideDeleted":
                    setPowerformSummary((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            firstLoad: false,
                            hideDeleted: data
                        }
                    });
                    break;

                case "exportExcel":
                    // exportExcel(data)
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [powerformSummary]);

    const handleTableEvents2 = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "routeToDetail":
                    setParameter((prevState: any) => {
                        return {
                            ...prevState,
                            paramter: data.guideID,
                            source: data.guideTitle,
                        };
                    });
                    break;

                case "sortColumn":
                    setSmartTipSummary((prevState) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "changePage":

                    setSmartTipSummary((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":
                    setSmartTipSummary((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });
                    break;

                case "changeEntries":
                    setSmartTipSummary((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false,
                        }
                    });
                    break;

                case "hideDeleted":
                    setSmartTipSummary((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            firstLoad: false,
                            hideDeleted: data
                        }
                    });
                    break;

                case "exportExcel":
                    // exportExcel(data)
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [smartTipSummary]);


    // Use Effects @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    // Highlight on scroll

    useEffect(() => {
        try {
            const handleScroll = () => {
                const currentScrollY = window.scrollY;
                setTimeout(() => {
                    if (currentScrollY >= 680 && currentScrollY < 1800) {
                        CommonUtils.LeftPanelHighlight(1, 4, 1, true, false);
                    }
                    else if (currentScrollY >= 1800 && currentScrollY < 2500) {
                        CommonUtils.LeftPanelHighlight(1, 4, 2, true, false);
                    } else if (currentScrollY >= 2500) {
                        CommonUtils.LeftPanelHighlight(1, 4, 3, true, false);
                    } else {
                        CommonUtils.LeftPanelHighlight(1, 4, 0, true, false);
                    }
                }, 0);

            };

            window.addEventListener("scroll", handleScroll, { passive: true });

            return () => window.removeEventListener("scroll", handleScroll);
        } catch (error) {
            showBoundary(error)
        }

    }, [window.scrollY]);

    // On sidenav click scroll to section
    useEffect(() => {
        let queryParams = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            fdate: fetched_details?.commonComponentData.datesData.fdate,
            tdate: fetched_details?.commonComponentData.datesData.tdate,
            event_source: fetched_details?.commonComponentData?.eventSource.eventSourceTooltips,
            segment: fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            option: fetched_details.urlOption.urlOption,
            env: fetched_details?.commonComponentData?.envFilter?.envCode,
        }

        navigate({
            pathname: `/guide-insights/tooltips/`,
            search: qs.stringify(queryParams)
        })

        if (fetched_details.urlOption.urlOption === 'Tooltips_Validation' || fetched_details.urlOption.urlOption === '' || fetched_details.urlOption.urlOption === 'tooltips') {
            window.scrollTo({ top: 0, behavior: 'smooth' });

        } else if (fetched_details.urlOption.urlOption === 'PowerForm') {
            window.scrollTo({ top: 700, behavior: 'smooth' });

        } else if (fetched_details.urlOption.urlOption === 'DuctTape') {
            window.scrollTo({ top: 1900, behavior: 'smooth' });

        } else {
            window.scrollTo({ top: 2500, behavior: 'smooth' });

        }
    }, [fetched_details.urlOption.urlOption])

    // Navigate to detail page
    useEffect(() => {
        try {
            if (parameter.paramter !== '') {
                let queryParams = {
                    app_id: fetched_details?.commonComponentData.appsData.appId,
                    app_code: fetched_details?.commonComponentData.appsData.appCode,
                    fdate: fetched_details?.commonComponentData.datesData.fdate,
                    tdate: fetched_details?.commonComponentData.datesData.tdate,
                }
                navigate({
                    pathname: "/guide-insights/tooltips-detail/" + parameter.paramter + '/',
                    search: qs.stringify(queryParams),
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [parameter])

    // Export pdf btn state
    useEffect(() => {
        try {
            if (loaderBtn.loader0 && loaderBtn.loader1) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            } else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader0, loaderBtn.loader1])

    // Api call on App, Date, event source filter change
    useEffect(() => {
        try {
            let queryParams = {
                app_id: fetched_details?.commonComponentData.appsData.appId,
                app_code: fetched_details?.commonComponentData.appsData.appCode,
                fdate: fetched_details?.commonComponentData.datesData.fdate,
                tdate: fetched_details?.commonComponentData.datesData.tdate,
                event_source: fetched_details?.commonComponentData?.eventSource.eventSourceTooltips,
                segment: fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
                option: 'Tooltips_Validation',
                env: fetched_details?.commonComponentData?.envFilter?.envCode,
            }

            navigate({
                pathname: `/guide-insights/tooltips/`,
                search: qs.stringify(queryParams)
            })




            if (fetched_details?.commonComponentData.datesData.source === 'component' &&
                fetched_details?.commonComponentData.appsData?.appCode === localStorage.getItem('switch_app_code') &&
                fetched_details?.commonComponentData?.envFilter.listFetched) {

                // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
                if (fetched_details?.commonComponentData.datesData?.url?.includes('tooltips/') &&
                    fetched_details.commonComponentData.segmentsFilter.listFetched) {

                    const reqUUID = uuidv4();
                    setUuid((prevState: any) => {
                        return {
                            ...prevState,
                            value: reqUUID
                        }
                    })

                    setFilterValues((prevState: any) => {
                        return {
                            ...prevState,
                            eventSource: fetched_details?.eventSource?.eventSourceTooltips,
                            segmentsGroup: { segmentsFilter: fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter, segmentTitle: fetched_details?.commonComponentData?.segmentsFilter?.segmentTitle },
                        };
                    });

                    setLoaderBtn((prevState: any) => {
                        return {
                            ...prevState,
                            loader0: false,
                            loader1: false,
                            disabled: true,
                        }
                    })

                    setActivityChart((prevState: any) => {
                        return {
                            ...prevState,
                            chartFetched: false,
                            activityLineChart: {},
                            activityLineChartFetched: false,
                        };
                    });

                    setDucttapeChart((prevState: any) => {
                        return {
                            ...prevState,
                            chartFetched: false,
                            ducttapeLineChart: {},
                            ducttapeLineChartFetched: false,
                        };
                    });

                    setPowerformChart((prevState: any) => {
                        return {
                            ...prevState,
                            chartFetched: false,
                            powerformLineChart: {},
                            powerformLineChartFetched: false,

                        };
                    });


                    getTooltipChartData(reqUUID, guideIds.list)
                    getPowerformChartData(reqUUID, guideIds.list);

                    setPowerformSummary((prevState: any) => {
                        return {
                            ...prevState,
                            summaryFetched: false,
                            firstLoad: true,
                            searchQuery: '',
                            sortKey: 'submit_count',
                            page: 1,
                            showExport: true,
                            defaultSort: {
                                'sortColumn_index': 2,
                                'sortValue': 'desc'
                            },
                            sortValue: 'desc',
                            limit: 5,
                            tablePagination: {
                                offset: 0,
                                data: [],
                                perPage: 5,
                                currentPage: 0
                            },
                            hideDeleted: 0
                        };
                    });

                    setSmartTipSummary((prevState: any) => {
                        return {
                            ...prevState,
                            summaryFetched: false,
                            firstLoad: true,
                            searchQuery: '',
                            sortKey: 'digital_duct_tape_count',
                            page: 1,
                            showExport: true,
                            defaultSort: {
                                'sortColumn_index': 4,
                                'sortValue': 'desc'
                            },
                            sortValue: 'desc',
                            limit: 5,
                            tablePagination: {
                                offset: 0,
                                data: [],
                                perPage: 5,
                                currentPage: 0
                            },
                            hideDeleted: 0
                        };
                    });
                }
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details?.commonComponentData.datesData,
    fetched_details?.commonComponentData?.eventSource.eventSourceTooltips,
    fetched_details?.commonComponentData.segmentsFilter.listFetched,
    fetched_details?.commonComponentData.segmentsFilter.segmentsFilter,
    fetched_details?.commonComponentData.datesData.url,
    fetched_details?.tooltipList.tooltipList,
    fetched_details?.commonComponentData?.envFilter,
    fetched_details.languageData.languageData])

    // Tooltip activity chart
    useEffect(() => {
        try {
            if (
                fetched_details.tooltips.tooltipActivityChart.data !== undefined &&
                fetched_details.tooltips.tooltipActivityChart.result === "success" &&
                fetched_details?.tooltips.tooltipActivityChart?.requestUUID === request_uuid.value
            ) {

                tooltipActivityChartMount.current = true;
                let activityChart: any = [];

                activityChart = fetched_details.tooltips.tooltipActivityChart.data.graphData;

                renderActivityLineChart(activityChart);
                renderDucttapeLineChart(activityChart);

                apiCatchError(0, 0);
                enableExportPdf(0, true);

            } else if (fetched_details.tooltips.tooltipActivityChart.result === 'retry') {
                getTooltipChartData(request_uuid.value, guideIds.list, 'retry');

            } else if (fetched_details.tooltips.tooltipActivityChart.result === "error") {
                if (fetched_details.tooltips.tooltipActivityChart.errorCode === 503) {
                    apiCatchError(0, 503);
                } else {
                    apiCatchError(0, 1);
                }
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.tooltips.tooltipActivityChart])

    // Tooltip powerform chart
    useEffect(() => {
        try {
            if (
                fetched_details.tooltips.powerformChart.data !== undefined &&
                fetched_details.tooltips.powerformChart.result === "success" &&
                fetched_details?.tooltips.powerformChart?.requestUUID === request_uuid.value
            ) {

                tooltipPowerformChartMount.current = true;
                let powerformChart: any = [];

                powerformChart = fetched_details.tooltips.powerformChart.data.graphData;

                renderPowerFormLineChart(powerformChart);

                apiCatchError(1, 0);
                enableExportPdf(1, true);
            } else if (fetched_details.tooltips.powerformChart.result === 'retry') {
                getPowerformChartData(request_uuid.value, guideIds.list, 'retry');
            } else if (fetched_details.tooltips.powerformChart.result === "error") {


                if (fetched_details.tooltips.powerformChart.errorCode === 503) {

                    apiCatchError(1, 503);
                } else {
                    apiCatchError(1, 1);
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.tooltips.powerformChart]);

    // SmartTip summary data
    useEffect(() => {
        try {
            if (
                fetched_details.tooltips.smartTipSummary.data !== undefined &&
                fetched_details.tooltips.smartTipSummary.result === "success" &&
                fetched_details?.tooltips.smartTipSummary?.requestUUID === request_uuid.value
            ) {
                smartTipSummaryTableMount.current = true;
                setSmartTipSummary((prevState: any) => {
                    return {
                        ...prevState,
                        summaryFetched: true,
                    }
                })

                const d = { ...fetched_details.tooltips.smartTipSummary.data, }
                apiCatchError(2, 0);
                enableExportPdf(2, true);
            } else if (fetched_details.tooltips.smartTipSummary.result === 'retry') {
                getSmartTipSummaryData(request_uuid.value, guideIds.list, 'retry');
            } else if (fetched_details.tooltips.smartTipSummary.result === "error") {
                if (fetched_details.tooltips.smartTipSummary.errorCode === 503) {
                    apiCatchError(2, 503);
                } else {
                    apiCatchError(2, 1);
                }

                setSmartTipSummary((prevState: any) => {
                    return {
                        ...prevState,
                        summaryFetched: true,
                    }
                })
                enableExportPdf(2, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.tooltips.smartTipSummary]);

    // Powerform summary data
    useEffect(() => {
        try {
            if (
                fetched_details.tooltips.powerformSummary.data !== undefined &&
                fetched_details.tooltips.powerformSummary.result === "success" &&
                fetched_details?.tooltips.powerformSummary?.requestUUID === request_uuid.value
            ) {
                powerformSummaryTableMount.current = true;
                setPowerformSummary((prevState: any) => {
                    return {
                        ...prevState,
                        summaryFetched: true,
                    }
                })
                apiCatchError(3, 0);
                enableExportPdf(1, true);
            } else if (fetched_details.tooltips.powerformSummary.result === 'retry') {
                getPowerformSummaryData(request_uuid.value, guideIds.list, 'retry');
            } else if (fetched_details.tooltips.powerformSummary.result === "error") {
                if (fetched_details.tooltips.powerformSummary.errorCode === 503) {
                    apiCatchError(3, 503);
                } else {
                    apiCatchError(3, 1);
                }

                setPowerformSummary((prevState: any) => {
                    return {
                        ...prevState,
                        summaryFetched: true,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.tooltips.powerformSummary]);


    // Api call on table actions (Powerform summary)
    useEffect(() => {
        try {
            // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
            if (fetched_details?.commonComponentData.datesData?.url?.includes('tooltips/') || fetched_details?.commonComponentData.datesData.dateSelection === 15) {
                enableExportPdf(2, false);
                getPowerformSummaryData(request_uuid.value, guideIds.list);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        powerformSummary.sortKey,
        powerformSummary.sortValue,
        powerformSummary.defaultSort,
        powerformSummary.page,
        powerformSummary.searchQuery,
        powerformSummary.limit,
        powerformSummary.hideDeleted,
        request_uuid.value
    ]);

    // Api call on table actions (SmartTip summary)
    useEffect(() => {
        try {
            // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
            if (fetched_details?.commonComponentData.datesData?.url?.includes('tooltips/') || fetched_details?.commonComponentData.datesData.dateSelection === 15) {
                enableExportPdf(3, false);
                getSmartTipSummaryData(request_uuid.value, guideIds.list);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        smartTipSummary.sortKey,
        smartTipSummary.sortValue,
        smartTipSummary.defaultSort,
        smartTipSummary.page,
        smartTipSummary.searchQuery,
        smartTipSummary.limit,
        smartTipSummary.hideDeleted,
        request_uuid.value
    ]);

    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);
            const error_503 = errorCount.errorCount.every((val) => val === 503);

            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })

            if (error_503) {
                CommonUtils.handle503Redirect(window.location.href)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [errorCount.errorCount])


    // Chart Renders and Callbacks @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const renderActivityLineChart = (data: any) => {
        try {
            setActivityChart((prevState: any) => {
                return {
                    ...prevState,
                    chartData: [...data],
                    // chartOptions: [...activityChartOptions],
                    chartFetched: true
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const renderDucttapeLineChart = (data: any) => {
        try {
            setDucttapeChart((prevState: any) => {
                return {
                    ...prevState,
                    chartData: [...data],
                    // chartOptions: [...ducttapeChartOptions],
                    chartFetched: true
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const renderPowerFormLineChart = (data: any) => {

        try {
            for (let itr1 = 0; itr1 < data.length; itr1++) {
                data[itr1].counts.cancel = parseInt(data[itr1].counts.cancel, 10);
                data[itr1].counts.submit = parseInt(data[itr1].counts.submit, 10);

                // cancel[i] = {};
                // cancel[i].y = parseInt(data[i].counts.cancel, 10);
                // cancel[i].x = data[i].startTimestamp;
                // cancel[i].startTimestamp = data[i].startTimestamp;
                // cancel[i].date = data[i].date;

                // submit[i] = {};
                // submit[i].y = parseInt(data[i].counts.submit, 10);
                // submit[i].x = data[i].startTimestamp;
                // submit[i].startTimestamp = data[i].startTimestamp;
                // submit[i].date = data[i].date;

            }

            setPowerformChart((prevState: any) => {
                return {
                    ...prevState,
                    chartData: [...data],
                    // chartOptions: [...powerformChartOptions],
                    chartFetched: true
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const getActivityLineChart = useCallback(
        (optn: string) => {
            try {
                if (optn === "data") {
                    return activityChart.chartData;
                } else {
                    return activityChart.chartOptions;
                }
            } catch (error) {
                showBoundary(error)
            }

        },
        [activityChart.chartData, activityChart.chartOptions]
    );

    const ActivityLineChart = useCallback(() => {
        try {
            setActivityChart((prevState: any) => {
                return {
                    ...prevState,
                    activityLineChart: <Linechart
                        chartData={getActivityLineChart("data")}
                        chartEntities={['guidance', 'validation', 'guidanceValidation']}
                        optionEntities={[
                            {
                                key: insightsLbls.tooltip,
                                color: ChartColors.color1,
                                associatedDataPoint: "guidance",
                                area: false
                            },
                            {
                                key: insightsLbls.validation,
                                color: ChartColors.color2,
                                associatedDataPoint: "validation",
                                area: false,
                            },
                            {
                                key: insightsLbls.tooltipAndValidation,
                                color: ChartColors.color3,
                                associatedDataPoint: "guidanceValidation",
                                area: false
                            }
                        ]}
                        value_type={"count"}
                        height={activity_linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls.yAxisLabelCount}
                    ></Linechart>,
                    activityLineChartFetched: true,

                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }

    }, [getActivityLineChart, lineChartMargin, activity_linechart_height]);

    const getDucttapeLineChart = useCallback(
        (optn: string) => {
            try {
                if (optn === "data") {
                    return ducttapeChart.chartData;
                } else {
                    return ducttapeChart.chartOptions;
                }
            } catch (error) {
                showBoundary(error)
            }

        },
        [ducttapeChart.chartData, ducttapeChart.chartOptions]
    );

    const DucttapeLineChart = useCallback(() => {
        try {
            setDucttapeChart((prevState: any) => {
                return {
                    ...prevState,
                    ducttapeLineChart: <Linechart
                        chartData={getDucttapeLineChart("data")}
                        chartEntities={['digitalDuctTape']}
                        optionEntities={[
                            {
                                key: insightsLbls.ductTape,
                                color: ChartColors.color4, 
                                associatedDataPoint: "digitalDuctTape",
                                area: false
                            },
                        ]}
                        value_type={"count"}
                        height={activity_linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls.yAxisLabelCount}
                    ></Linechart>,
                    ducttapeLineChartFetched: true,

                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }

    }, [getDucttapeLineChart, lineChartMargin, activity_linechart_height]);

    const getPowerformLineChart = useCallback(
        (optn: string) => {
            try {
                if (optn === "data") {
                    return powerformChart.chartData;
                } else {
                    return powerformChart.chartOptions;
                }
            } catch (error) {
                showBoundary(error)
            }

        },
        [powerformChart.chartData, powerformChart.chartOptions]
    );

    const PowerformLineChart = useCallback(() => {
        try {
            setPowerformChart((prevState: any) => {
                return {
                    ...prevState,
                    powerformLineChart: <Linechart
                        chartData={getPowerformLineChart("data")}
                        chartEntities={['submit', 'cancel']}
                        optionEntities={[
                            {
                                key: insightsLbls.submitted,
                                color: ChartColors.color3, 
                                associatedDataPoint: "submit",
                                area: false
                            },
                            {
                                key: insightsLbls.cancelled,
                                color: ChartColors.color4, 
                                associatedDataPoint: "cancel",
                                area: false
                            }
                        ]}
                        value_type={"count"}
                        height={activity_linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls.yAxisLabelCount}
                    ></Linechart>,
                    powerformLineChartFetched: true,

                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }

    }, [getPowerformLineChart, lineChartMargin, activity_linechart_height]);


    const getValuesFromUrl = () => {
        // return 'guide_id=11383'
    }

    return (
        <section className="tooltips demo  width100" ref={componentRef}>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li className="active">{insightsLbls.tooltips}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <EnvironmentFilter component={"guideAnalytics"} disabled={false} />
                    <Segments component={"tooltips"} defaultValue={''}></Segments>
                    <EventSource component={"tooltips"}></EventSource>
                    <TooltipModal appCode="" dispatchGuidesList={getGuideIds} fromComponent={'tooltip'} defaultSelected={getValuesFromUrl()}></TooltipModal>
                    <Datepicker source={'component'}></Datepicker>
                </SectionFilters>
            </div>

            {/* <SectionButtons sectionTitle={insightsLbls.tooltips} svgImage={"tooltips"} className={'marginTop-50'}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={"Tooltips"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons> */}


            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={"Guides"}
                                widthDividedBy={12}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                dateObject={fetched_details?.commonComponentData?.datesData}
                messageText={insightsLbls.tooltips}>
            </UserInfoSection>

            <div id="ActivityChartSection">
                {

                    // <SectionRefresh sectionTitle={insightsLbls.tooltipAndValidation} refreshClass={""}>
                    //     <i className="fa fa-refresh pull-right cursorPointer row displayFlex"
                    //         aria-hidden="true"
                    //         title={insightsLbls.refresh}
                    //         onClick={() => refreshSections(0)}></i>

                    //     <CustomDashboardModal
                    //         api={activityChart.api}
                    //         eventSource={filterValues.eventSource}
                    //         guideStatus={filterValues.guideStatus}
                    //         segmentsGroup={filterValues.segmentsGroup}
                    //         chartType={"LineChart"}
                    //         sectionHeader={insightsLbls.tooltipAndValidation}
                    //         sectionTooltip={
                    //             {
                    //                 "subTitle": insightsLbls.activity,
                    //                 "tooltipText": insightsLbls.tooltipGuidanceAndValidationGraph,
                    //                 "childern": [
                    //                     {
                    //                         "key": insightsLbls.tooltip + ":",
                    //                         "value": insightsLbls.countOfTooltipHoverClicked
                    //                     },
                    //                     {
                    //                         "key": insightsLbls.validation + ":",
                    //                         "value": insightsLbls.countOfValidationPerformed
                    //                     },
                    //                     {
                    //                         "key": insightsLbls.tooltipAndValidation + ":",
                    //                         "value": insightsLbls.countOfValidationAndInteractionPerformed
                    //                     }
                    //                 ]
                    //             }
                    //         }
                    //         sectionType={'type1'}
                    //         guide_filter={false}
                    //         event_filter={true}
                    //         segment_filter={true}
                    //         tooltip_filter={true}
                    //         component={'tooltip'}
                    //         tooltip_guideIds={guideIds.list}
                    //     ></CustomDashboardModal>
                    // </SectionRefresh>
                }
                <div className="dashboardTiles  col-lg-12 page_section padding0 height600 blockImportant marginTop-20">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5  height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            {
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.tooltipAndValidation,
                                        "tooltipText": insightsLbls.tooltipGuidanceAndValidationGraph,
                                        "childern": [
                                            {
                                                "key": insightsLbls.tooltip + ":",
                                                "value": insightsLbls.countOfTooltipHoverClicked
                                            },
                                            {
                                                "key": insightsLbls.validation + ":",
                                                "value": insightsLbls.countOfValidationPerformed
                                            },
                                            {
                                                "key": insightsLbls.tooltipAndValidation + ":",
                                                "value": insightsLbls.countOfValidationAndInteractionPerformed
                                            }
                                        ]
                                    }
                                } placement="right"></SectionTooltip>
                            }


                            <span>
                                <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button>
                                <CustomDashboardModal
                                    api={activityChart.api}
                                    eventSource={filterValues.eventSource}
                                    guideStatus={filterValues.guideStatus}
                                    segmentsGroup={filterValues.segmentsGroup}
                                    chartType={"LineChart"}
                                    sectionHeader={insightsLbls.tooltipAndValidation}
                                    sectionTooltip={
                                        {
                                            "subTitle": insightsLbls.activity,
                                            "tooltipText": insightsLbls.tooltipGuidanceAndValidationGraph,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.tooltip + ":",
                                                    "value": insightsLbls.countOfTooltipHoverClicked
                                                },
                                                {
                                                    "key": insightsLbls.validation + ":",
                                                    "value": insightsLbls.countOfValidationPerformed
                                                },
                                                {
                                                    "key": insightsLbls.tooltipAndValidation + ":",
                                                    "value": insightsLbls.countOfValidationAndInteractionPerformed
                                                }
                                            ]
                                        }
                                    }
                                    sectionType={'type1'}
                                    guide_filter={false}
                                    event_filter={true}
                                    segment_filter={true}
                                    tooltip_filter={true}
                                    component={'tooltip'}
                                    tooltip_guideIds={guideIds.list}
                                ></CustomDashboardModal>
                            </span>
                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {activityChart.chartFetched ? (
                                errorCount.errorCount[0] === 0 ? (
                                    activityChart.chartData.length === 0 ? (
                                        <div className="noDataText-font textCenter-absolute">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (activityChart.activityLineChartFetched
                                        ? <>{activityChart.activityLineChart}</>
                                        : (<ActivityLineChart></ActivityLineChart>)
                                    )
                                )
                                    : (
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {insightsLbls.serviceNotAvailable}
                                        </h5>
                                    )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}
                        </div>

                    </CardContainer>
                </div>
            </div>

            <div id="powerformChartSection">
                {
                    // <SectionRefresh sectionTitle={insightsLbls.powerForm} refreshClass={"marginTop-30"}>
                    //     <i className="fa fa-refresh pull-right cursorPointer row displayFlex"
                    //         aria-hidden="true"
                    //         title={insightsLbls.refresh}
                    //         onClick={() => refreshSections(1)}></i>

                    //     <CustomDashboardModal
                    //         api={powerformChart.api}
                    //         eventSource={filterValues.eventSource}
                    //         guideStatus={filterValues.guideStatus}
                    //         segmentsGroup={filterValues.segmentsGroup}
                    //         chartType={"LineChart"}
                    //         sectionHeader={insightsLbls.powerForm}
                    //         sectionTooltip={
                    //             {
                    //                 "subTitle": insightsLbls.activity,
                    //                 "tooltipText": insightsLbls.barChartPowerForm,
                    //                 "childern": [
                    //                     {
                    //                         "key": insightsLbls.submitted + ":",
                    //                         "value": insightsLbls.countOfSubmittedPowerForm
                    //                     },
                    //                     {
                    //                         "key": insightsLbls.cancelled + ":",
                    //                         "value": insightsLbls.countOfCancelledPowerForm
                    //                     }
                    //                 ]
                    //             }
                    //             // {
                    //             //     "subTitle": insightsLbls.activity,
                    //             //     "tooltipText": insightsLbls.tableOfPowerFormStats,
                    //             //     "childern": [
                    //             //         {
                    //             //             "key": insightsLbls.powerForm + ":",
                    //             //             "value": insightsLbls.titleOfPowerForm
                    //             //         },
                    //             //         {
                    //             //             "key": insightsLbls.cancelled + ":",
                    //             //             "value": insightsLbls.countOfCancelledSpecificPowerForm
                    //             //         },
                    //             //         {
                    //             //             "key": insightsLbls.submitted + ":",
                    //             //             "value": insightsLbls.countOfSubmittedSpecificPowerForm
                    //             //         }
                    //             //     ]
                    //             // }
                    //         }
                    //         sectionType={'type1'}
                    //         guide_filter={false}
                    //         event_filter={true}
                    //         tooltip_filter={true}
                    //         segment_filter={true}
                    //         component={'tooltip'}
                    //         tooltip_guideIds={guideIds.list}
                    //     ></CustomDashboardModal>
                    // </SectionRefresh>
                }

                {/* Powerform Chart  */}
                <div className="dashboardTiles  col-lg-12 page_section padding0 height600 blockImportant marginTop-20">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper-edge borderbottom icon-text chart-wrapper5  height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            {
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.powerForm,
                                        "tooltipText": insightsLbls.barChartPowerForm,
                                        "childern": [
                                            {
                                                "key": insightsLbls.submitted + ":",
                                                "value": insightsLbls.countOfSubmittedPowerForm
                                            },
                                            {
                                                "key": insightsLbls.cancelled + ":",
                                                "value": insightsLbls.countOfCancelledPowerForm
                                            }
                                        ]
                                    }
                                } placement="right"></SectionTooltip>
                            }

                            <span>
                                <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(1)}> {insightsLbls.refresh}</button>
                                <CustomDashboardModal
                                    api={powerformChart.api}
                                    eventSource={filterValues.eventSource}
                                    guideStatus={filterValues.guideStatus}
                                    segmentsGroup={filterValues.segmentsGroup}
                                    chartType={"LineChart"}
                                    sectionHeader={insightsLbls.powerForm}
                                    sectionTooltip={
                                        {
                                            "subTitle": insightsLbls.activity,
                                            "tooltipText": insightsLbls.barChartPowerForm,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.submitted + ":",
                                                    "value": insightsLbls.countOfSubmittedPowerForm
                                                },
                                                {
                                                    "key": insightsLbls.cancelled + ":",
                                                    "value": insightsLbls.countOfCancelledPowerForm
                                                }
                                            ]
                                        }
                                        // {
                                        //     "subTitle": insightsLbls.activity,
                                        //     "tooltipText": insightsLbls.tableOfPowerFormStats,
                                        //     "childern": [
                                        //         {
                                        //             "key": insightsLbls.powerForm + ":",
                                        //             "value": insightsLbls.titleOfPowerForm
                                        //         },
                                        //         {
                                        //             "key": insightsLbls.cancelled + ":",
                                        //             "value": insightsLbls.countOfCancelledSpecificPowerForm
                                        //         },
                                        //         {
                                        //             "key": insightsLbls.submitted + ":",
                                        //             "value": insightsLbls.countOfSubmittedSpecificPowerForm
                                        //         }
                                        //     ]
                                        // }
                                    }
                                    sectionType={'type1'}
                                    guide_filter={false}
                                    event_filter={true}
                                    tooltip_filter={true}
                                    segment_filter={true}
                                    component={'tooltip'}
                                    tooltip_guideIds={guideIds.list}
                                ></CustomDashboardModal>
                            </span>
                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {powerformChart.chartFetched ? (
                                errorCount.errorCount[1] === 0 ? (
                                    powerformChart.chartData.length === 0 ? (
                                        <div className="noDataText-font textCenter-absolute">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (powerformChart.powerformLineChartFetched
                                        ? <>{powerformChart.powerformLineChart}</>
                                        : (<PowerformLineChart></PowerformLineChart>)
                                    )
                                )
                                    : (
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {insightsLbls.serviceNotAvailable}
                                        </h5>
                                    )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}
                        </div>

                    </CardContainer>
                </div>

                {/* Powerform Summary  */}
                <div className="dashboardTiles  col-lg-12 page_section padding0 height600 blockImportant marginTop-20">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5  height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            {
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.powerForm + " " + insightsLbls.summary,
                                        "tooltipText": insightsLbls.tableOfPowerFormStats,
                                        "childern": [
                                            {
                                                "key": insightsLbls.powerForm + ":",
                                                "value": insightsLbls.titleOfPowerForm
                                            },
                                            {
                                                "key": insightsLbls.cancelled + ":",
                                                "value": insightsLbls.countOfCancelledSpecificPowerForm
                                            },
                                            {
                                                "key": insightsLbls.submitted + ":",
                                                "value": insightsLbls.countOfSubmittedSpecificPowerForm
                                            }
                                        ]
                                    }
                                } placement="right"></SectionTooltip>
                            }
                            <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(1)}> {insightsLbls.refresh}</button>
                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {
                                powerformSummary.summaryFetched ? (
                                    errorCount.errorCount[1] === 0 ? (
                                        <div className="tabelEditing commonSummaryTable">
                                            <CommonTable tableId="1"
                                                data={fetched_details.tooltips.powerformSummary.data}
                                                currentPage={powerformSummary.page}
                                                barColors=""
                                                linkPresent={false}
                                                showExport={false}
                                                showSearch={true}
                                                showEntries={true}
                                                defaultSort={powerformSummary.defaultSort}
                                                tablePagination={powerformSummary.tablePagination}
                                                tableEvent={handleTableEvents}
                                                firstTimeloadFlag={fetched_details.tooltips.powerformSummary.firstTimeload}
                                                hideDeleted={true}
                                                checkboxText={insightsLbls.hideDeleted}
                                                modalTable={false}

                                            />
                                        </div>
                                    )
                                        : errorCount.errorCount[1] === 1 ? (
                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                {insightsLbls.serviceNotAvailable}
                                            </h5>
                                        )
                                            : null)
                                    : (
                                        <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader></Loader>
                                            </div>
                                        </div>
                                    )
                            }
                        </div>

                    </CardContainer>
                </div>
            </div>

            <div id="ducttapeChartSection">
                {

                    // <SectionRefresh sectionTitle={insightsLbls.ductTape} refreshClass={"marginTop-30"}>
                    //     <i className="fa fa-refresh pull-right cursorPointer row displayFlex"
                    //         aria-hidden="true"
                    //         title={insightsLbls.refresh}
                    //         onClick={() => refreshSections(2)}></i>

                    //     <CustomDashboardModal
                    //         api={ducttapeChart.api}
                    //         eventSource={filterValues.eventSource}
                    //         guideStatus={filterValues.guideStatus}
                    //         segmentsGroup={filterValues.segmentsGroup}
                    //         chartType={"LineChart"}
                    //         sectionHeader={insightsLbls.ductTape}
                    //         sectionTooltip={{
                    //             "subTitle": insightsLbls.activity,
                    //             "tooltipText": insightsLbls.statsOfDigitalDuctTape,
                    //             "childern": [
                    //                 {
                    //                     "key": insightsLbls.digitalDuctTape,
                    //                     "value": insightsLbls.countDigitalDuctTapeDisplayed
                    //                 }
                    //             ]
                    //         }}
                    //         sectionType={'type1'}
                    //         guide_filter={false}
                    //         event_filter={true}
                    //         tooltip_filter={true}
                    //         segment_filter={true}
                    //         component={'tooltip'}
                    //         tooltip_guideIds={guideIds.list}
                    //     ></CustomDashboardModal>

                    // </SectionRefresh>

                }
                <div className="dashboardTiles  col-lg-12 page_section padding0 height600 blockImportant marginTop-20">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5  height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            <SectionTooltip tooltipText={{
                                "subTitle": insightsLbls.ductTape,
                                "tooltipText": insightsLbls.statsOfDigitalDuctTape,
                                "childern": [
                                    {
                                        "key": insightsLbls.digitalDuctTape,
                                        "value": insightsLbls.countDigitalDuctTapeDisplayed
                                    }
                                ]
                            }} placement="right"></SectionTooltip>


                            <span>
                                <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(2)}> {insightsLbls.refresh}</button>
                                <CustomDashboardModal
                                    api={ducttapeChart.api}
                                    eventSource={filterValues.eventSource}
                                    guideStatus={filterValues.guideStatus}
                                    segmentsGroup={filterValues.segmentsGroup}
                                    chartType={"LineChart"}
                                    sectionHeader={insightsLbls.ductTape}
                                    sectionTooltip={{
                                        "subTitle": insightsLbls.activity,
                                        "tooltipText": insightsLbls.statsOfDigitalDuctTape,
                                        "childern": [
                                            {
                                                "key": insightsLbls.digitalDuctTape,
                                                "value": insightsLbls.countDigitalDuctTapeDisplayed
                                            }
                                        ]
                                    }}
                                    sectionType={'type1'}
                                    guide_filter={false}
                                    event_filter={true}
                                    tooltip_filter={true}
                                    segment_filter={true}
                                    component={'tooltip'}
                                    tooltip_guideIds={guideIds.list}
                                ></CustomDashboardModal>
                            </span>
                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {ducttapeChart.chartFetched ? (
                                errorCount.errorCount[0] === 0 ? (
                                    ducttapeChart.chartData.length === 0 ? (
                                        <div className="noDataText-font textCenter-absolute">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (ducttapeChart.ducttapeLineChartFetched
                                        ? <>{ducttapeChart.ducttapeLineChart}</>
                                        : (<DucttapeLineChart></DucttapeLineChart>)
                                    )
                                )
                                    : (
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {insightsLbls.serviceNotAvailable}
                                        </h5>
                                    )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}
                        </div>

                    </CardContainer>
                </div>
            </div>

            <div id="smartTipTableSection">
                {

                    // <SectionRefresh sectionTitle={insightsLbls.summary} refreshClass={"marginTop-30"}>
                    //     <i className="fa fa-refresh pull-right cursorPointer row displayFlex"
                    //         aria-hidden="true"
                    //         title={insightsLbls.refresh}
                    //         onClick={() => refreshSections(3)}></i>

                    // </SectionRefresh>

                }
                <div className="dashboardTiles  col-lg-12 page_section padding0 height600 blockImportant marginTop-20">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5  height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            {
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.summary,
                                        "tooltipText": insightsLbls.tableOfStatsOfTooltip,
                                        "childern": [
                                            {
                                                "key": insightsLbls.guideTitle + ":",
                                                "value": insightsLbls.nameOfTooltipContainsMoreTooltips
                                            },
                                            {
                                                "key": insightsLbls.guidance + ":",
                                                "value": insightsLbls.interactionHappenedWithGuidanceType
                                            },
                                            {
                                                "key": insightsLbls.validation + ":",
                                                "value": insightsLbls.interactionHappenedWithValidationType
                                            },
                                            {
                                                "key": insightsLbls.guidanceAndValidation + ":",
                                                "value": insightsLbls.interactionHappenedWithGuidanceValidationType
                                            },
                                            {
                                                "key": insightsLbls.digitalDuctTape + ":",
                                                "value": insightsLbls.digitalDuctTapeRenderCount
                                            },
                                            {
                                                "key": insightsLbls.powerForm + ":",
                                                "value": insightsLbls.interactionHappenedWithPowerForm
                                            }
                                        ]
                                    }
                                } placement="right"></SectionTooltip>
                            }
                            <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(3)}> {insightsLbls.refresh}</button>

                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {
                                smartTipSummary.summaryFetched ? (
                                    errorCount.errorCount[3] === 0 ? (
                                        <div className=" commonSummaryTable">
                                            <CommonTable tableId="2"
                                                data={fetched_details.tooltips.smartTipSummary.data}
                                                currentPage={smartTipSummary.page}
                                                barColors=""
                                                linkPresent={true}
                                                showExport={false}
                                                showSearch={true}
                                                showEntries={true}
                                                defaultSort={smartTipSummary.defaultSort}
                                                tablePagination={smartTipSummary.tablePagination}
                                                tableEvent={handleTableEvents2}
                                                firstTimeloadFlag={fetched_details.tooltips.smartTipSummary.firstTimeload}
                                                hideDeleted={true}
                                                checkboxText={insightsLbls.hideDeleted}
                                                modalTable={false}
                                            />
                                        </div>
                                    )
                                        : errorCount.errorCount[3] === 1 ? (
                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                {insightsLbls.serviceNotAvailable}
                                            </h5>
                                        )
                                            : null)
                                    : (
                                        <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader></Loader>
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                    </CardContainer>
                </div>
            </div>

            <div className="overlayCursor" id="overlayCursor"></div>
            {
                errorCount.showMaintenance_flag && (
                    <Maintenance></Maintenance>
                )
            }

        </section >
    )
}

export default withRouter(withReducer("tooltips", tooltipReducer)(Tooltips));

